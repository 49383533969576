import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { POSITIONS, COLOR, SIZE } from "../constants";
import SectionContent from "./SectionContent";

const propTypes = {
  theme: PropTypes.oneOf([
    "",
    COLOR.dark,
    COLOR.grey,
    COLOR.light,
    COLOR.primary,
    COLOR.primary_light,
  ]),
};

function SectionHeroHeader({ background, theme, content, children }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    relative
    ${bgColor}
  `;

  useMemo(() => {
    switch (theme) {
      case "dark":
        setBgColor("bg-grey-800");
        break;
      case "grey":
        setBgColor("bg-grey-50");
        break;
      case "primary":
        setBgColor("bg-primary-800");
        break;
      case "primaryLight":
        setBgColor("bg-primary-50");
        break;
      case "light":
        setBgColor("bg-white");
        break;
      default:
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      {background && background}
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-start md:justify-end">
          <div className="w-full max-w-xl">
            <div className="px-4 md:px-8 py-16 md:py-40">
              <SectionContent {...content} />
            </div>
          </div>
        </div>
        {children && children}
      </div>
    </div>
  );
}

SectionHeroHeader.prototype = propTypes;
export default SectionHeroHeader;
