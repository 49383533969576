import PropTypes from "prop-types";
import cntl from "cntl";

const propTypes = {
  isSelected: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const SelectionComplex = ({
  onClick,
  isSelected,
  children,
  disabled,
  size
}) => {
  const paymentCn = cntl`
    ${disabled ? cntl`pointer-events-none` : null}
    ${
      isSelected
        ? cntl`ease-out duration-200 outline outline-1 outline-primary-700`
        : cntl`ease-out duration-200 border-grey-300`
    }
    hover:cursor-pointer 
    bg-white 
    rounded-lg
    border
    shadow-sm
    border-solid
    ${size ? size : `w-28 h-16`}
  `;

  return (
    <div className={paymentCn} onClick={onClick}>
      {children && <div className="p-3">{children}</div>}
    </div>
  );
};

SelectionComplex.propTypes = propTypes;
export default SelectionComplex;
