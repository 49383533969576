import { get } from "lodash";
import { httpClient } from "../../httpClient";

export async function getTenantMe() {
  try {
    const res = await httpClient.get(`/tenants/me`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenantType(type) {
  try {
    const res = await httpClient.put(`/tenants/type/${type}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenant(values) {
  let body = {};
  
  if (values) {
    const { address, vatcode, name, fiscalcode } = values;

    body = {
      tenant: {
        name,
        vatcode,
        address,
        fiscalcode,
      },
    };
  } else {
    body = {};
  }

  try {
    const res = await httpClient.post(`/tenants`, body);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenantIncorporationDocument(document) {
  const formData = new FormData();
  formData.append("file", document);
  try {
    await httpClient.post(`/tenants/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  } catch (err) {
    throw err;
  }
}

export async function createTenantDocuments(document, name, type) {
  const formData = new FormData();
  formData.append("file", document);
  formData.append("name", name);
  formData.append("type", type);

  try {
    await httpClient.post(`/tenants/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  } catch (err) {
    throw err;
  }
}

export async function uploadTenantDocument(document) {
  const formData = new FormData();
  formData.append("file", document?.file[0]);
  formData.append("type", document?.type);
  formData.append("name", document?.name);
  try {
    await httpClient.post(`/tenants/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  } catch (err) {
    throw err;
  }
}

export async function updateTenantDocument(id, value) {
  try {
    await httpClient.put(`/tenants/documents/${id}`, {
      name: value.name,
      type: value.type,
    });
    return;
  } catch (err) {
    throw err;
  }
}

export async function createTenantAddress(values) {
  const body = {
    address: values,
  };

  try {
    await httpClient.post(`/tenants/addresses`, body);
    return;
  } catch (err) {
    throw err;
  }
}

export async function getTenantAddresses({ pageIndex, pageSize }) {
  try {
    const res = await httpClient.get(`/tenants/addresses`, {
      params: {
        page: pageIndex + 1,
        pageSize,
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenantPeople(values) {
  const { address, name: firstname, surname: lastname, birthDate: birthday, birthCity: birth_city } = values;

  const body = {
    person: {
      firstname,
      lastname,
      birthday,
      birth_city,
      address: {
        ...address,
        type: "residential",
        postcode: address.cap,
      },
    },
  };

  try {
    const res = await httpClient.post(`/tenants/people`, body);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenantPersonIdentityDocument(personId, document, name, type) {
  const formData = new FormData();
  formData.append("file", document);
  formData.append("name", name);
  formData.append("type", type);
  try {
    // TODO: verify if it's tenant people or not
    await httpClient.post(`/tenants/people/${personId}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    throw err;
  }
}

export async function deleteDocumentById(documentId) {
  try {
    const res = await httpClient.delete(`/tenants/documents/${documentId}`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updatePerson(values, { personId }) {
  const body = {
    person: {
      ...values,
    },
  };

  try {
    const res = await httpClient.put(`/tenants/people/${personId}`, body);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getPersonById({ person }) {
  try {
    const res = await httpClient.get(`/tenants/person/${person?.id}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getTenantPeople({ pageIndex, pageSize, filters }) {
  try {
    const res = await httpClient.get(`/tenants/people`, {
      params: {
        page: pageIndex + 1,
        pageSize,
        ...(filters.name && { name: filters.name }),
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function createTenantPerson(values) {
  try {
    const res = await httpClient.post(`/tenants/people`, {
      person: {
        firstname: values.firstname,
        lastname: values.lastname,
        birthday: values.birthday,
        birth_city: values.birth_city,
        address: {
          city: values.city,
          country: values.country,
          region: values.region,
          postcode: values.postcode,
          street: values.street,
          type: "residential",
        },
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateTenantPerson(values, id) {
  try {
    const res = await httpClient.put(`/tenants/people/${id}`, {
      person: {
        firstname: values.firstname,
        lastname: values.lastname,
        birthday: values.birthday,
        birth_city: values.birth_city,
        address: {
          street: values.street,
          city: values.city,
          country: values.country,
          region: values.region,
          postcode: values.postcode,
          type: "residential",
        },
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getTenantsDocuments({ pageIndex, pageSize, filters }) {
  try {
    const res = await httpClient.get(`/tenants/documents`, {
      params: {
        page: pageIndex + 1,
        pageSize,
        ...(filters.type && { type: filters.type }),
      },
    });
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function deleteAddressById({ addressId }) {
  try {
    const res = await httpClient.delete(`/tenants/addresses/${addressId}`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateAddress(values, id) {
  const body = {
    address: { ...values },
  };

  try {
    const res = await httpClient.put(`/tenants/addresses/${id}`, body);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function downloadDocument({ documentId }) {
  try {
    const res = await httpClient.post(
      `/tenants/documents/${documentId}/download`,
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data, headers) => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          };
        },
      }
    );

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function uploadImage(formData) {
  try {
    const res = await httpClient.post(`/tenants/picture`, formData);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function getTenantById(tenatId) {
  try {
    const res = await httpClient.get(`/backoffice/tenants/${tenatId}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchTenantProfilePicture() {
  try {
    const res = await httpClient.get(`/tenants/picture`, {
      responseType: "arraybuffer",
      transformResponse: (data, headers) => {
        return {
          data: {
            buffer: data,
            contentType: headers["content-type"],
          },
        };
      },
    });
    return res?.data?.data || null;
  } catch (err) {
    throw err;
  }
}

export async function updateTenant(values) {
  const body = {
    tenant: {
      ...values,
    },
  };

  try {
    const res = await httpClient.put(`/tenants`, body);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function transferTenant(userId) {
  try {
    const res = await httpClient.put(`/tenants/transfer/${userId}`);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}
