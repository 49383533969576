import cntl from "cntl";
import { Item } from ".";
import PropTypes from "prop-types";
import { cloneElement } from "react";

const propTypes = {
  value: PropTypes.any,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ListItem({ icon, value, isActive, onClick, children, disabled }) {
  const iconCn = () => cntl`
    w-5
    h-5
    ${disabled ? `text-grey-300` : `text-grey-500`}
`;

  const itemDisabledCn = () => cntl`
    ${
      disabled
        ? `text-grey-300 bg-transparent hover:text-grey-300 hover:bg-white pointer-events-none`
        : `text-grey-700`
    }
  `;

  const itemCn = () => cntl`
    flex
    group
    py-0.5
    px-1.5
    text-sm
    flex-row
    font-medium
    h-[2.625rem]
    items-center
    cursor-pointer
    justify-between
    first-letter:uppercase
    ${itemDisabledCn()}
  `;

  const iconAndChildCn = () => cntl`
    flex
    px-2.5
    w-full
    h-full
    gap-x-3
    flex-row
    rounded-md
    items-center
    group-hover:bg-grey-50
    ${isActive ? cntl`bg-grey-50 cursor-default pointer-events-none` : cntl`bg-white`}
  `;

  return (
    <Item
      value={value}
      onClick={onClick}
      disabled={disabled}
      className={itemCn()}
    >
      <div className={iconAndChildCn()}>
        {icon &&
          cloneElement(icon, {
            className: iconCn(),
          })}
        {children}
      </div>
    </Item>
  );
}

ListItem.propTypes = propTypes;
export default ListItem;
