import cntl from "cntl";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import ListItem from "../atoms/ListItem";
import { Dropdown, Menu } from "../atoms";
import { useTranslation } from "react-i18next";
import { useState, cloneElement } from "react";

const propTypes = {
  offset: PropTypes.array,
  width: PropTypes.string,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  isThreeDoots: PropTypes.bool,
  isItemDisabled: PropTypes.bool,
};

function ButtonDropdown({
  width,
  offset,
  options,
  children,
  className,
  isDisabled,
  onItemSelect,
  isItemDisabled,
}) {
  const { t } = useTranslation();
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: offset || [0, 8],
        },
      },
    ],
    strategy: "absolute",
  });

  const menuCn = () => cntl`
    w-fit
    min-w-[15rem]
  `;

  const renderOptions = () => {
    if (options?.length === 0) {
      return <ListItem disabled>{t("dropdown.no_matches_found")}</ListItem>;
    }

    return options?.map(({ label, value, icon, isActive }, index) => (
      <ListItem
        icon={icon}
        value={value}
        isActive={isActive}
        disabled={isItemDisabled}
        key={`list-item-btn-${index}`}
      >
        {label || value}
      </ListItem>
    ));
  };

  return (
    <Dropdown
      width={width}
      onSelect={onItemSelect}
      isDisabled={isDisabled}
      className={className ? className : undefined}
    >
      {children &&
        cloneElement(children, {
          buttonRef: setReferenceElement,
        })}
      <Menu
        className={menuCn()}
        style={styles.popper}
        menuRef={setPopperElement}
        {...attributes.popper}
      >
        {renderOptions()}
      </Menu>
    </Dropdown>
  );
}

ButtonDropdown.propTypes = propTypes;
export default ButtonDropdown;
