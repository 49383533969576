export const POLLING_INTERVAL = 600 * 1000;

export const SNACKBAR_TIMEOUT = 9 * 1000;

export const CompanyRegistrationSteps = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
};

export const PaperValidationErrors = {
  GENERIC: "generic",
  BELOW_THAN_ZERO: "below_than_zero",
};

export const PageModes = {
  CREATE: "create",
  UPDATE: "update",
  ADD: "add",
};

export const Logistics = {
  PACKAGE: 1,
  PALLET: 2,
  BOX_ON_A_PALLET: 3,
  HAND_MADE_BOX: 4,
  SUPPORTED_HAND_MADE_BOX: 5,
  TUBULAR: 6,
};

export const SelectBoxStates = {
  SELECT: "select",
  DESELECT: "deselect",
};

export const PaperStates = {
  CREATE: "create",
  UPDATE: "update",
};

export const ClientPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const ProductPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const SheetsPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const BoxPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const PalletpageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const IntegrationPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const HookPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const ModalModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const JobsModalStatus = {
  CREATE: "create",
  UPDATE: "update",
};

export const ProfilePageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const WorkflowJobsModals = {
  CREATE: "create",
  UPDATE: "update",
};

export const ProfileModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const ShippersPageModes = {
  CREATE: "create",
  UPDATE: "update",
};

export const ENTER_KEY = 13;

export const ProfileLogsVersion = {
  NORMAL: "normal",
  ADVANCED: "advanced",
};

export const ProfileStatuses = {
  LOCKED: 0,
  UNLOCKED: 1,
};

export const PaymentEvents = {
  STARTED: "started",
  APPROVED: "approved",
  REJECTED: "rejected",
  CANCELED: "canceled",
  READY: "ready",
  CARD_FIELD_BLUR: "card-field-blur",
  CARD_FIELD_FOCUS: "card-field-focus",
  CREDIT_CARD_TOKENIZE: "credit-card-tokenize",
  INIT: "initialize",
};

export const DEFAULT_CURRENCY = "EUR";

export const PaymentMethodKeys = {
  CARDS: "cards",
  PAYPAL: "paypal",
  CROMIE_BALANCE: "cromie-balance",
  BANK_TRANSFER: "bank-transfer",
  SEPA: "sepa-account",
};

export const AccountModalTypes = {
  ADD_ADDRESS: "addAddress",
  EDIT_ADDRESS: "editAddress",
  ADD_DOCUMENT: "addDocument",
  EDIT_DOCUMENT: "editDocument",
  ADD_PERSON: "addPerson",
  EDIT_PERSON: "editPerson",
};

export const RegistrationSteps = {
  COMPANY_INFO: 0,
  LEGAL_INFO: 1,
  PURPOSE: 2,
};

export const ChoiceQuoteItems = {
  APPROVE: "approve",
  REJECT: "reject",
};

export const PaymentMethods = {
  CARD: "card",
  PAYPAL: "paypal",
};

export const SectionTypes = {
  WALLET: 'wallet',
  ORDER: 'order'
}

export const ROLE = {
  administrator: 'administrator',
  technician: 'technician',
  commercial: 'commercial'
}