import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function Divider({ title, marginY, tone, width, className }) {
  const { t } = useTranslation();
  const [color, setColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [borderWidth, setBorderWidth] = useState("");

  useEffect(() => {
    switch (width) {
      case "lg":
        setBorderWidth("border-t-8");
        break;
      case "sm":
        setBorderWidth("border-t-4");
        break;
      default:
        setBorderWidth("border-t");
        break;
    }
    switch (tone) {
      case "dark":
        setColor("border-grey-100");
        setTextColor("text-grey-500");
        break;
      case "light":
        setColor("border-grey-200");
        setTextColor("text-grey-500")
        break;
      default:
        setColor("border-grey-200");
        setTextColor("text-grey-500");
        break;
    }
  }, [width, color, tone]);

  return (
    <div
      className={`flex items-center ${marginY ? marginY : `my-6`} ${
        className ? className : undefined
      }`}
    >
      <div className="flex-1">
        <hr className={`${color} ${borderWidth}`} />
      </div>
      {title && (
        <div className="flex-none px-4">
          <p className={`first-letter:uppercase text-sm ${textColor}`}>{t(title)}</p>
        </div>
      )}
      <div className="flex-1">
        <hr className={`${color} ${borderWidth}`} />
      </div>
    </div>
  );
}

export default Divider;

