import cntl from "cntl";
import Button from "./Button";
import PropTypes from "prop-types";
import { SIZE, BUTTON_VARIANTS, POSITIONS } from "../constants/index";

const propTypes = {
  icon: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  buttonRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  variant: PropTypes.oneOf([
    BUTTON_VARIANTS.default,
    BUTTON_VARIANTS.border,
    BUTTON_VARIANTS.light,
    BUTTON_VARIANTS.border_off,
  ]),
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
};

function ButtonPrimary({
  icon,
  name,
  type,
  size,
  label,
  onClick,
  disabled,
  buttonRef,
  iconColor,
  className,
  isLoading,
  iconPosition,
  variant = BUTTON_VARIANTS.default,
}) {
  const defaultCn = () => cntl`
    text-white
    shadow-sm
    enabled:bg-primary-600
    disabled:bg-primary-100
    enabled:border-primary-600
    disabled:border-primary-100
    enabled:hover:bg-primary-700 enabled:hover:border-primary-700
    focus:bg-primary-600 focus:border-primary-600 focus:outline focus:outline-4 focus:outline-primary-100
    ${className ? className : undefined}
  `;

  const lightCn = () => cntl`
    shadow-sm
    enabled:text-primary-700
    disabled:text-primary-200
    enabled:bg-primary-50
    disabled:bg-primary-25
    enabled:border-primary-50
    disabled:border-primary-25
    enabled:hover:bg-primary-100 enabled:hover:border-primary-100
    focus:bg-primary-100 focus:border-primary-100 focus:outline focus:outline-4 focus:outline-primary-100
    ${className ? className : undefined}
  `;

  const borderCn = () => cntl`
    bg-white
    shadow-sm
    enabled:text-primary-700
    disabled:text-primary-300
    enabled:border-primary-300
    disabled:border-primary-200
    enabled:hover:bg-primary-50 enabled:hover:border-primary-300 enabled:hover:text-primary-800
    focus:bg-white focus:border-primary-300 focus:outline focus:outline-4 focus:outline-primary-100
    ${className ? className : undefined}
  `;

  const borderOffCn = () => cntl`
    bg-transparent
    border-transparent
    enabled:text-primary-700
    disabled:text-grey-300
    enabled:hover:bg-primary-50 enabled:hover:border-primary-50 enabled:hover:text-primary-700
    focus:bg-transparent focus:border-transparent
    ${className ? className : undefined}
  `;

  return (
    <>
      {variant === BUTTON_VARIANTS.default && (
        <Button
          name={name}
          type={type}
          size={size}
          icon={icon}
          label={label}
          onClick={onClick}
          disabled={disabled}
          buttonRef={buttonRef}
          iconColor={iconColor}
          isLoading={isLoading}
          className={defaultCn()}
          iconPosition={iconPosition}
        />
      )}
      {variant === BUTTON_VARIANTS.light && (
        <Button
          name={name}
          type={type}
          size={size}
          icon={icon}
          label={label}
          onClick={onClick}
          disabled={disabled}
          buttonRef={buttonRef}
          iconColor={iconColor}
          isLoading={isLoading}
          className={lightCn()}
          iconPosition={iconPosition}
        />
      )}
      {variant === BUTTON_VARIANTS.border && (
        <Button
          name={name}
          type={type}
          size={size}
          icon={icon}
          label={label}
          onClick={onClick}
          disabled={disabled}
          buttonRef={buttonRef}
          iconColor={iconColor}
          isLoading={isLoading}
          className={borderCn()}
          iconPosition={iconPosition}
        />
      )}
      {variant === BUTTON_VARIANTS.border_off && (
        <Button
          name={name}
          type={type}
          size={size}
          icon={icon}
          label={label}
          onClick={onClick}
          disabled={disabled}
          buttonRef={buttonRef}
          iconColor={iconColor}
          isLoading={isLoading}
          className={borderOffCn()}
          iconPosition={iconPosition}
        />
      )}
    </>
  );
}

ButtonPrimary.propTypes = propTypes;
export default ButtonPrimary;
