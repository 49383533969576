import cntl from "cntl";
import StepperCheckIcon from "@dbox/components/icons/StepperCheckIcon";
import StepLabel from "@dbox/components/atoms/StepLabel";

function Step({ activeStep, currentStep, isLastStep, label }) {
  const isStepComplete = currentStep < activeStep;
  const isStepVisited = !isStepComplete && currentStep === activeStep;

  const stepCn = cntl`
    flex
    items-center
    justify-center
    w-8 
    h-8
    relative
    rounded-full 
    border-2
    ml-24
    ${isStepVisited ? cntl`border-primary-600` : cntl`border-grey-300`}
  `;

  const stepInnerCn = cntl`
    w-2.5 
    h-2.5 
    rounded-full
    ${
      isStepVisited
        ? cntl`border-primary-600 bg-primary-600`
        : cntl`bg-grey-300`
    }
    ${isStepVisited || isLastStep ? cntl`flex` : cntl`hidden`}
  `;

  const stepCheckCn = cntl`
    flex
    items-center
    justify-center
    w-8 
    h-8
    relative
    rounded-full
    ml-24
    ${isStepComplete ? cntl`border border-primary-600 bg-primary-600` : null}
  `;

  const stepLastCn = cntl`
    flex
    items-center
    justify-center
    w-8 
    h-8
    relative
    rounded-full 
    border-2
    ml-24
    ${
      isLastStep && !isStepVisited
        ? cntl`border-grey-300`
        : cntl`border-primary-600`
    }
  `;

  return (
    <>
      {isStepComplete ? (
        <div className={stepCheckCn}>
          <StepLabel label={label} isActive={activeStep === currentStep} />
          <StepperCheckIcon />
        </div>
      ) : (
        <>
          {isStepVisited && isLastStep ? (
            <div className={stepLastCn}>
              <StepLabel label={label} isActive={activeStep === currentStep} />
              <div className={stepInnerCn} />
            </div>
          ) : (
            <div className={stepCn}>
              <StepLabel label={label} isActive={activeStep === currentStep} />
              <div className={stepInnerCn} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Step;
