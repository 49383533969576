import PropTypes from "prop-types";
import { useNavigate } from "react-router";

const propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  isGoBack: PropTypes.bool,
};

function IconWithLink({ icon, text, isGoBack }) {
  const navigate = useNavigate();
  
  return (
    <div className="flex items-center">
      <div className="flex items-center justify-center w-5 h-5">{icon}</div>
      <div className="first-letter:capitalize pl-3 text-sm text-amber-600 font-medium">
        {isGoBack ? (
          <div
            className="hover:cursor-pointer "
            onClick={() => navigate(-1)}
          >
            {text}
          </div>
        ) : (
          <>{text}</>
        )}
      </div>
    </div>
  );
}

IconWithLink.propTypes = propTypes;
export default IconWithLink;
