function LogoCromie({ color = "#333333", width= "32", heigth= "32" }) {
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9227 29.821C13.3419 31.2212 16.1592 32 18.9514 32C21.7485 32 24.4963 31.2603 26.9186 29.8553C27.1969 29.6939 27.4697 29.5244 27.7368 29.3471C29.206 28.3716 29.1945 26.3102 27.9492 25.0591C26.7063 23.8104 24.6988 23.871 23.1157 24.6387C20.3617 25.9743 17.0192 25.9618 14.171 24.3098C12.7604 23.4916 11.6358 22.3668 10.8325 21.0675C11.7515 22.6841 13.2715 25.8418 10.9227 29.821Z"
        fill="url(#paint0_linear_12_102667)"
      />
      <path
        d="M10.9958 29.8644C8.56476 28.4597 6.54602 26.4393 5.14246 24.0063C3.73891 21.5734 3 18.8135 3 16.0041C3 13.1948 3.73891 10.4349 5.14246 8.00192C6.54602 5.56894 8.56524 3.54885 10.9963 2.14417C10.9963 2.14417 18.1748 4.67005 18.9579 6.43192C15.948 6.43192 14.1941 7.68799 14.1941 7.68799C9.60491 10.3397 8.03254 16.2126 10.6821 20.8055C11.5561 22.3205 13.4806 25.6565 10.9958 29.8644Z"
        fill="url(#paint1_linear_12_102667)"
      />
      <circle cx="18.8992" cy="15.8991" r="3.99369" fill={color} />
      <path
        d="M28.03 7.00973C29.2774 5.75254 29.2916 3.68179 27.8227 2.69717C27.5421 2.50908 27.2551 2.32963 26.9622 2.15919C24.5311 0.744678 21.7735 0 18.9663 0C16.1592 0 13.4015 0.744678 10.9705 2.15919C13.3692 6.44655 17.2183 6.44655 18.9663 6.44655C20.4747 6.44655 21.9018 6.79732 23.1716 7.42232C24.7605 8.20433 26.7796 8.26978 28.03 7.00973Z"
        fill="#FFD600"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12_102667"
          x1="18.6521"
          y1="29.1985"
          x2="9.5807"
          y2="26.1225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB00FF" />
          <stop offset="1" stopColor="#850099" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12_102667"
          x1="8.29721"
          y1="10.6029"
          x2="16.299"
          y2="3.10724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BA5EC" />
          <stop offset="1" stopColor="#045C85" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoCromie;
