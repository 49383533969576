import cntl from "cntl";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { COLOR } from "../constants";
import NavItemBase from "./NavItemBase";
import { NavLink } from "react-router-dom";
import NavItemHeader from "./NavItemHeader";

const propTypes = {
  path: PropTypes.string,
  icon: PropTypes.object,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  hasBadge: PropTypes.bool,
  subItem: PropTypes.array,
  hasStatus: PropTypes.bool,
  counter: PropTypes.number,
  isDropdown: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.oneOf([COLOR.dark, COLOR.primary, COLOR.light]),
};

function NavItem({ path, theme, subItem, className, ...props }) {
  const colors = useMemo(() => {
    switch (theme) {
      case COLOR.primary:
        return {
          dot: "bg-primary-300",
          badge: "bg-primary-50",
          icon: "text-primary-300",
          item: {
            active: "bg-primary-600 text-white hover:bg-primary-600 focus:ring-4 focus:ring-primary-500",
            noActive: "bg-primary-700 text-primary-100 hover:bg-primary-600 focus:ring-4 focus:ring-primary-500",
          },
        };
      case COLOR.dark:
        return {
          dot: "bg-grey-100",
          badge: "bg-grey-100",
          icon: "text-grey-300",
          item: {
            active: "bg-grey-700 text-grey-100 hover:bg-grey-700 focus:ring-4 focus:ring-grey-500",
            noActive: "bg-grey-900 text-white hover:bg-grey-700 focus:ring-4 focus:ring-grey-500",
          },
        };
      case COLOR.light:
        return {
          dot: "bg-grey-100",
          badge: "bg-success-500",
          icon: {
            active: "text-primary-500",
            noActive: "text-grey-500",
          },
          item: {
            active: "bg-grey-50 text-primary-700",
            noActive: "text-grey-700 group-hover:bg-grey-50",
          },
        };
      default:
        return {
          dot: "bg-primary-300",
          badge: "bg-primary-50",
          icon: "text-primary-300",
          item: {
            active: "bg-primary-600 text-white hover:bg-primary-600 focus:ring-4 focus:ring-primary-500",
            noActive: "bg-primary-700 text-primary-100 hover:bg-primary-600 focus:ring-4 focus:ring-primary-500",
          },
        };
    }
  }, [theme]);

  const liCn = () => cntl`
    group
    ${className ? className : undefined}
  `;

  const navLinkCn = ({ isActive }) => cntl`
    block
    rounded-md
    ${isActive ? colors?.item?.active : colors?.item?.noActive}
  `;

  return (
    <>
      {!subItem?.length ? (
        <li tabIndex={0} className={liCn()}>
          <NavLink to={path} className={({ isActive }) => navLinkCn({ isActive })}>
            {({ isActive }) => <NavItemBase colors={colors} isActive={isActive} {...props} />}
          </NavLink>
        </li>
      ) : (
        <NavItemHeader colors={colors} subItem={subItem} dropdownPath={path} {...props} />
      )}
    </>
  );
}

NavItem.propTypes = propTypes;
export default NavItem;
