import cntl from "cntl";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { SIZE } from "../constants/index";

const propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  tabIndex: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  ariaSelected: PropTypes.string,
  ariaControls: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function TabButton({
  id,
  size,
  label,
  onClick,
  children,
  tabIndex,
  className,
  ariaControls,
  isDisabled = false,
  ariaSelected = "false",
}) {
  const [fontStyle, setFontStyle] = useState("");

  const buttonCn = () => cntl`
    flex
    gap-x-2
    rounded-md
    snap-center
    ${fontStyle}
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mx-auto
    first-letter:uppercase
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-sm font-medium");
        break;
      case SIZE.md:
        setFontStyle("text-base font-medium");
        break;
      default:
        setFontStyle("text-sm font-medium");
        break;
    }
  }, [size]);

  return (
    <button
      id={id}
      role="tab"
      tabIndex={tabIndex}
      disabled={isDisabled}
      className={buttonCn()}
      onClick={() => onClick()}
      aria-controls={ariaControls}
      aria-selected={ariaSelected}
    >
      <span className={labelCn()}>{label}</span>
      {children}
    </button>
  );
}

TabButton.propTypes = propTypes;
export default TabButton;
