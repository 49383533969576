import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonPrimary } from "../atoms";
import { HeaderPricing } from "../molecules";
import { POSITIONS, SIZE } from "../constants";

const propTypes = {
  firstCol: PropTypes.array,
  className: PropTypes.string,
  columnsProps: PropTypes.array,
};

function PricingPageHeader({ className, firstCol, columnsProps }) {
  return <FeaturedIcon3ColsDesktop className={className} firstCol={firstCol} columnsProps={columnsProps} />;
}

const FeaturedIcon3ColsDesktop = ({ className, firstCol, columnsProps }) => {
  const wrapperCn = () => cntl`
    grid
    grid-cols-1
    xl:grid-cols-4
    ${className ? className : undefined}
  `;

  const colCn = ({ isHighlight }) => cntl`
    flex-col
    overflow-hidden
    ${isHighlight ? `rounded-2xl lg:border-2 lg:border-primary-500` : ``}
  `;

  const headerCn = () => cntl`
    p-4
    flex
    pb-8
    lg:p-6
    lg:pb-10
    flex-col
    xl:h-[16rem]
    justify-between
  `;

  const tableCn = () => cntl`
    flex
    flex-col
  `;

  return (
    <div className={wrapperCn()}>
      <div className="hidden xl:flex flex-col">
        <div className="min-h-[13.75rem]" />
        {firstCol?.map(({ title, rows }, index) => {
          return <FirstCol title={title} rows={rows} index={index} key={`col-${index}`} />;
        })}
      </div>
      {columnsProps?.map((props, colIndex) => {
        return (
          <div className={colCn({ isHighlight: props.header?.isMostPopular, colIndex })} key={`col-price-${colIndex}`}>
            <div className={headerCn()}>
              {props?.header && <HeaderPricing {...props?.header} alignment={POSITIONS.center} />}
            </div>
            {props?.groups?.length && (
              <div className={tableCn()}>
                {props?.groups?.map((props, cellIndex) => {
                  return (
                    <div key={`cell-col-${cellIndex}`}>
                      <TableMobile index={cellIndex} className="block xl:hidden" {...props} />
                      <TableDesktop index={cellIndex} className="hidden xl:block" {...props} />
                    </div>
                  );
                })}
              </div>
            )}
            {props?.button && (
              <ButtonPrimary size={SIZE.md} className="mx-auto mt-8 mb-16 lg:mb-8" {...props?.button} />
            )}
          </div>
        );
      })}
    </div>
  );
};

const TableMobile = ({ index, className, ...props }) => {
  const wrapperCn = () => cntl`
    ${className ? className : undefined}
  `;

  const cellTitleCn = () => cntl`
    h-9
    px-4
    mt-8
  `;

  const titleCn = () => cntl`
    text-sm
    font-medium
    text-primary-700
  `;

  const cellRowCn = () => cntl`
    h-14
    px-4
    flex
    text-sm
    font-medium
    items-center
    odd:bg-white
    text-grey-900
    justify-between
    even:bg-grey-50
  `;

  const valueCn = () => cntl`
    text-grey-600
  `;

  return (
    <div className={wrapperCn()}>
      {props?.title && (
        <div className={cellTitleCn()}>
          <div className={titleCn()}>{props?.title}</div>
        </div>
      )}
      {props?.rows?.map(({ value, description }, index) => {
        return (
          <div className={cellRowCn()} key={`mobile-row-${index}`}>
            <div>{description?.text}</div>
            <div className={valueCn()}>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const TableDesktop = ({ index, className, ...props }) => {
  const wrapperCn = () => cntl`
  ${index === 0 ? "mt-0" : "mt-[76px]"}
    ${className ? className : undefined}
  `;

  const cellRowCn = () => cntl`
    h-16
    px-4
    flex
    text-sm
    font-medium
    items-center
    even:bg-white
    text-grey-900
    justify-between
    odd:bg-grey-50
  `;

  const valueCn = () => cntl`
    mx-auto
    text-grey-600
  `;

  return (
    <div className={wrapperCn()}>
      {props?.rows?.map(({ value }, index) => {
        return (
          <div className={cellRowCn()} key={`desktop-row-${index}`}>
            <div className={valueCn()}>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const FirstCol = ({ rows, title, index }) => {
  const titleCn = () => cntl`
    h-9
    pl-6
    text-sm
    font-medium
    text-primary-700
    ${index === 0 ? "mt-0" : "mt-10"}
  `;

  const cellRowCn = () => cntl`
    h-16
    px-6
    flex
    text-sm
    font-medium
    items-center
    even:bg-white
    text-grey-900
    justify-between
    odd:bg-grey-50
  `;

  return (
    <>
      <div className={titleCn()}>{title}</div>
      {rows?.map(({ description }, index) => {
        return (
          <div className={cellRowCn()} key={`first-col-${index}`}>
            <div>{description?.text}</div>
          </div>
        );
      })}
    </>
  );
};

PricingPageHeader.prototype = propTypes;
export default PricingPageHeader;
