import { ROLE } from "../constants";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { getIsLoggedIn, getLoginVerificationState, getUser } from "@dbox/cromie-common/store/reducers/app.reducer";

// Layout
const LayoutOnboarding = React.lazy(() => import("@dbox/cromie-common/components/templates/LayoutOnboarding"));
const LayoutFullScreen = React.lazy(() => import("@dbox/cromie-common/components/templates/LayoutFullScreen"));
const LayoutWithSidebar = React.lazy(() => import("@dbox/cromie-common/components/templates/LayoutWithSidebar"));

// Routing
const ProtectedRoute = React.lazy(() => import("@dbox/cromie-common/routes/ProtectedRoute"));
const RoleBasedRoutes = React.lazy(() => import("@dbox/cromie-common/routes/RoleBasedRoutes"));

// Login, signup, reset passowrd and customer payment (page without login)
const Login = React.lazy(() => import("@dbox/cromie-common/pages/Login"));
const Signup = React.lazy(() => import("@dbox/cromie-common/pages/Signup"));
const ResetPassword = React.lazy(() => import("@dbox/cromie-common/pages/Signup"));
const SignupWelcome = React.lazy(() => import("@dbox/cromie-common/pages/SignupWelcome"));
const LoginVerification = React.lazy(() => import("@dbox/cromie-common/pages/LoginVerification"));
const CustomerFinalizeQuote = React.lazy(() => import("@dbox/cromie/src/pages/CustomerFinalizeQuote"));
const RequestRecoveryPassword = React.lazy(() => import("@dbox/cromie-common/pages/RequestRecoveryPassword"));

// Onboarding pages
const RootOnboarding = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/RootOnboarding"));
const OnboardingStep1 = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/OnboardingStep1"));
const OnboardingStep2 = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/OnboardingStep2"));
const OnboardingStep3 = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/OnboardingStep3"));
const OnboardingStep4 = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/OnboardingStep4"));
const OnboardingStep5 = React.lazy(() => import("@dbox/cromie/src/pages/onboarding/OnboardingStep5"));

// Core
const Me = React.lazy(() => import("@dbox/cromie/src/pages/Me"));
const Root = React.lazy(() => import("@dbox/cromie/src/pages/Root"));
const Quotes = React.lazy(() => import("@dbox/cromie/src/pages/Quotes"));
const Orders = React.lazy(() => import("@dbox/cromie/src/pages/Orders"));
const Sheets = React.lazy(() => import("@dbox/cromie/src/pages/Sheets"));
const Clients = React.lazy(() => import("@dbox/cromie/src/pages/Customers"));
const Products = React.lazy(() => import("@dbox/cromie/src/pages/Products"));
const Settings = React.lazy(() => import("@dbox/cromie/src/pages/Settings"));
const HookView = React.lazy(() => import("@dbox/cromie/src/pages/HookView"));
const Machines = React.lazy(() => import("@dbox/cromie/src/pages/Machines"));
const Dashboard = React.lazy(() => import("@dbox/cromie/src/pages/Dashboard"));
const Logistics = React.lazy(() => import("@dbox/cromie/src/pages/Logistics"));
const Materials = React.lazy(() => import("@dbox/cromie/src/pages/Materials"));
const OrdersView = React.lazy(() => import("@dbox/cromie/src/pages/OrdersView"));
const QuotesView = React.lazy(() => import("@dbox/cromie/src/pages/QuotesView"));
const MyBusiness = React.lazy(() => import("@dbox/cromie/src/pages/MyBusiness"));
const ProductsView = React.lazy(() => import("@dbox/cromie/src/pages/ProductsView"));
const SheetDetails = React.lazy(() => import("@dbox/cromie/src/pages/SheetDetails"));
const MachinesEdit = React.lazy(() => import("@dbox/cromie/src/pages/MachinesEdit"));
const CustomersView = React.lazy(() => import("@dbox/cromie/src/pages/CustomersView"));
const ClientsCreate = React.lazy(() => import("@dbox/cromie/src/pages/CustomersCreate"));
const MachinesCreate = React.lazy(() => import("@dbox/cromie/src/pages/MachinesCreate"));
const ProductJobDetails = React.lazy(() => import("@dbox/cromie/src/pages/ProductJobDetails"));
const SheetFormatDetails = React.lazy(() => import("@dbox/cromie/src/pages/SheetFormatDetails"));
const ProductsJobsCreate = React.lazy(() => import("@dbox/cromie/src/pages/ProductsJobsCreate"));
const UserProfilesDetails = React.lazy(() => import("@dbox/cromie/src/pages/UserProfilesDetails"));
const ProductsWorkflowsEdit = React.lazy(() => import("@dbox/cromie/src/pages/ProductsWorkflowsEdit"));
const ProductsWorkflowsCreate = React.lazy(() => import("@dbox/cromie/src/pages/ProductsWorkflowsCreate"));
const ProductsWorkflowsJobsEdit = React.lazy(() => import("@dbox/cromie/src/pages/ProductsWorkflowsJobsEdit"));
const QuotesCreationMakeProduct = React.lazy(() => import("@dbox/cromie/src/pages/QuotesCreationMakeProduct"));
const ProductsWorkflowsJobsCreate = React.lazy(() => import("@dbox/cromie/src/pages/ProductsWorkflowsJobsCreate"));
const ProductsAttributesJobsCreate = React.lazy(() => import("@dbox/cromie/src/pages/ProductsAttributesJobsCreate"));

function CromieRoutes() {
  const userProfile = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loginVerification = useSelector(getLoginVerificationState);
  const isVerificationCompleted = isLoggedIn && loginVerification?.step1 && loginVerification?.step2;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<ProtectedRoute isLoggedIn={isVerificationCompleted} />}>
          <Route path="/" element={<Root />}>
            <Route path="/" element={<LayoutWithSidebar />}>
              <Route
                path="/"
                element={
                  <RoleBasedRoutes
                    currentRole={userProfile?.profile?.role}
                    allowedRoles={[ROLE.administrator, ROLE.commercial, ROLE.technician]}
                  />
                }
              >
                <Route
                  path="me"
                  element={
                    <Suspense fallback="Loading...">
                      <Me />
                    </Suspense>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback="Loading...">
                      <Dashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Suspense fallback="Loading...">
                      <Navigate to="/dashboard" />
                    </Suspense>
                  }
                />
                <Route path="orders">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <Orders />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/view"
                    element={
                      <Suspense fallback="Loading...">
                        <OrdersView />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="/"
                element={
                  <RoleBasedRoutes
                    currentRole={userProfile?.profile?.role}
                    allowedRoles={[ROLE.commercial, ROLE.administrator]}
                  />
                }
              >
                <Route path="quotes">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <Quotes />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/view"
                    element={
                      <Suspense fallback="Loading...">
                        <QuotesView />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":quoteId/view/add/product/:productId"
                    element={
                      <Suspense fallback="Loading...">
                        <QuotesCreationMakeProduct />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path="customers">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <Clients />
                      </Suspense>
                    }
                  />
                  <Route
                    path="create"
                    element={
                      <Suspense fallback="Loading...">
                        <ClientsCreate />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/view"
                    element={
                      <Suspense fallback="Loading...">
                        <CustomersView />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="/"
                element={
                  <RoleBasedRoutes allowedRoles={[ROLE.administrator]} currentRole={userProfile?.profile?.role} />
                }
              >
                <Route path="production">
                  <Route index element={<Navigate to="/dashboard" />} />
                  <Route
                    path="logistics"
                    element={
                      <Suspense fallback="Loading...">
                        <Logistics />
                      </Suspense>
                    }
                  />
                  <Route
                    path="materials"
                    element={
                      <Suspense fallback="Loading...">
                        <Materials />
                      </Suspense>
                    }
                  />
                  <Route path="products">
                    <Route
                      index
                      element={
                        <Suspense fallback="Loading...">
                          <Products />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":id/view"
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsView />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":id/view/jobs/create"
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsJobsCreate />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":productId/view/jobs/:jobId"
                      element={
                        <Suspense fallback="Lx\oading...">
                          <ProductJobDetails />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":id/view/workflows/create"
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsWorkflowsCreate />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":productId/view/workflows/:workflowId/edit"
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsWorkflowsEdit />
                        </Suspense>
                      }
                    />
                    <Route
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsAttributesJobsCreate />
                        </Suspense>
                      }
                      path=":productId/view/jobs/:jobId/attribute/create"
                    />
                    <Route
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsWorkflowsJobsCreate />
                        </Suspense>
                      }
                      path=":productId/view/workflows/:workflowId/job/create"
                    />
                    <Route
                      element={
                        <Suspense fallback="Loading...">
                          <ProductsWorkflowsJobsEdit />
                        </Suspense>
                      }
                      path=":productId/view/workflows/:workflowId/job/:jobId/edit"
                    />
                  </Route>
                  <Route path="sheets">
                    <Route
                      index
                      element={
                        <Suspense fallback="Loading...">
                          <Sheets />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":id/edit"
                      element={
                        <Suspense fallback="Loading...">
                          <SheetDetails />
                        </Suspense>
                      }
                    />
                    <Route
                      path="formats/:id/edit"
                      element={
                        <Suspense fallback="Loading...">
                          <SheetFormatDetails />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route path="machines">
                    <Route
                      index
                      element={
                        <Suspense fallback="Loading...">
                          <Machines />
                        </Suspense>
                      }
                    />
                    <Route
                      path="add"
                      element={
                        <Suspense fallback="Loading...">
                          <MachinesCreate />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":tenantMachineId/edit"
                      element={
                        <Suspense fallback="Loading...">
                          <MachinesEdit />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
                <Route path="settings">
                  <Route
                    index
                    element={
                      <Suspense fallback="Loading...">
                        <Settings />
                      </Suspense>
                    }
                  />
                  <Route
                    path="hooks/:id/view"
                    element={
                      <Suspense fallback="Loading...">
                        <HookView />
                      </Suspense>
                    }
                  />
                  <Route
                    path="user-details/:id"
                    element={
                      <Suspense fallback="Loading...">
                        <UserProfilesDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="myBusiness"
                  element={
                    <Suspense fallback="Loading...">
                      <MyBusiness />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path="onboarding" element={<RootOnboarding />}>
            <Route element={<LayoutOnboarding />}>
              <Route
                path="step1"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep1 />
                  </Suspense>
                }
              />
              <Route
                path="step2"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep2 />
                  </Suspense>
                }
              />
              <Route
                path="step3"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep3 />
                  </Suspense>
                }
              />
              <Route
                path="step4"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep4 />
                  </Suspense>
                }
              />
              <Route
                path="step5"
                element={
                  <Suspense fallback="Loading...">
                    <OnboardingStep5 />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* No login */}
        <Route path="/" element={<LayoutFullScreen />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="signup/success" element={<SignupWelcome />} />
          <Route path="password/reset" element={<ResetPassword />} />
          <Route path="order/:id" element={<CustomerFinalizeQuote />} />
          <Route path="login/verification" element={<LoginVerification />} />
          <Route path="password/recovery" element={<RequestRecoveryPassword />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default CromieRoutes;
