import { useEffect, useState, Fragment, useMemo } from "react";
import { Pagination } from "..";
import T from "prop-types";
import { LoaderIcon } from "../icons";
import { IconArrowDown, IconArrowsUpDown, IconArrowUp } from "@tabler/icons-react";
import { PAGINATION_VARIANTS } from "../constants";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import cntl from "cntl";

const propTypes = {
  from: T.number,
  to: T.number,
  hiddenHeader: T.bool,
  columns: T.array.isRequired,
  data: T.array.isRequired,
  paginationSize: T.number,
  pageCount: T.number,
  fetch: T.func,
  total: T.number,
  className: T.string,
  isLoading: T.bool,
  RowExpansion: T.func,
  settings: T.object,
  variantPagination: T.oneOf([
    PAGINATION_VARIANTS.card_button,
    PAGINATION_VARIANTS.card_center,
    PAGINATION_VARIANTS.card_default,
    PAGINATION_VARIANTS.card_left,
    PAGINATION_VARIANTS.card_right,
    PAGINATION_VARIANTS.page_button,
    PAGINATION_VARIANTS.page_default,
  ]),
};

const Rows = ({ table, RowExpansion, settings }) => {
  const [expandedIdx, setExpandedIdx] = useState();
  return table?.getRowModel()?.rows?.map((row, idx) => {
    return (
      <Fragment key={idx}>
        <tr
          onClick={() => {
            setExpandedIdx((expandedId) =>
              idx === expandedId ? undefined : idx
            );
          }}
          className={`hover:bg-grey-50 border-b border-b-grey-200 last:border-b-0
            ${RowExpansion && "hover:cursor-pointer"}
            ${settings?.table?.row?.height || `h-[4.5rem]`}
            ${expandedIdx === idx && RowExpansion && `border-b-0`}
          `}
        >
          {row?.getVisibleCells()?.map((cell) => {
            return (
              <td key={cell.id}>
                {flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}
              </td>
            );
          })}
        </tr>
        {expandedIdx === idx && RowExpansion && (
          <tr
            key={idx}
            className={`border-b border-b-grey-200 last:border-b-0 ${
              RowExpansion && "hover:cursor-pointer"
            }
          ${settings?.table?.row?.height || `h-[4.5rem]`}`}
          >
            <td colSpan={row?.getAllCells()?.length}>
              <div className="p-4">
                <RowExpansion row={row} />
              </div>
            </td>
          </tr>
        )}
      </Fragment>
    );
  });
};

function DataGrid({
  to,
  data,
  from,
  total,
  fetch,
  columns,
  settings,
  pageCount,
  isLoading,
  className,
  RowExpansion,
  variantPagination,
  paginationSize = 15,
  hiddenHeader = false,
  hiddenPagination = false,
}) {
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationSize,
  });

  const thHeaderSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    flex-row
    select-none
    items-center
    cursor-pointer
    ${
      textAlign?.toLowerCase() === "left"
        ? cntl`justify-start`
        : cntl`justify-end`
    }
  `;

  const thHeaderNoSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    flex-row
    ${
      textAlign?.toLowerCase() === "left"
        ? cntl`justify-start`
        : cntl`justify-end`
    }
  `;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount,
    autoResetPage: false,
    manualPagination: true,
    initialState: { pageIndex: 0 },
    onSortingChange: setSorting,
    state: {
      sorting,
      expanded,
      pagination,
    },
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    fetch({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
  }, [fetch, pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-row items-center justify-center">
          <LoaderIcon className="animate-spin" />
        </div>
      ) : (
        <>
          {!!data?.length && (
            <div
              className={`flex flex-col overflow-y-auto ${
                className ? className : undefined
              }`}
            >
              <table className={`w-full ${settings?.table?.bgColor}`}>
                {!hiddenHeader && (
                  <thead
                    className={`h-11 text-xs font-medium text-grey-600 border-b border-b-grey-200 ${
                      settings?.table?.header?.bgColor || `bg-grey-50`
                    }`}
                  >
                    {table?.getHeaderGroups() &&
                      table?.getHeaderGroups()?.map((headerGroup) => {
                        return (
                          <tr key={headerGroup.id}>
                            {headerGroup?.headers?.map((header) => {
                              return (
                                <th
                                  key={header.id}
                                  className={`px-6 text-left`}
                                  style={{
                                    width:
                                      header.getSize() !== 0
                                        ? header.getSize()
                                        : undefined,
                                  }}
                                >
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? thHeaderSort({
                                            textAlign:
                                              header.column.columnDef
                                                .textHeaderAlign || "left",
                                          })
                                        : thHeaderNoSort({
                                            textAlign:
                                              header.column.columnDef
                                                .textHeaderAlign || "left",
                                          }),
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {header.column.getCanSort() && (
                                      <div className="w-[13px] h-[13px]">
                                        {!header.column.getIsSorted() && (
                                          <IconArrowsUpDown className="w-[13px] h-[13px]" />
                                        )}
                                        {{
                                          asc: (
                                            <IconArrowDown className="w-[13px] h-[13px]" />
                                          ),
                                          desc: (
                                            <IconArrowUp className="w-[13px] h-[13px]" />
                                          ),
                                        }[header.column.getIsSorted()] ?? null}
                                      </div>
                                    )}
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </thead>
                )}
                <tbody>
                  <Rows
                    table={table}
                    RowExpansion={RowExpansion}
                    settings={settings}
                  />
                </tbody>
              </table>
              {((table.getPageCount() && from && to && total) ||
                !hiddenPagination) && (
                <Pagination
                  to={to}
                  from={from}
                  total={total}
                  pageCount={pageCount}
                  variant={variantPagination}
                  paginationSize={paginationSize}
                  nextPage={() => table.nextPage()}
                  canNextPage={table.getCanNextPage()}
                  previousPage={() => table.previousPage()}
                  canPreviousPage={table.getCanPreviousPage()}
                  gotoPage={(index) => table.setPageIndex(index)}
                  pageIndex={table.getState().pagination.pageIndex}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

DataGrid.propTypes = propTypes;
export default DataGrid;
