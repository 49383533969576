import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  textHelper: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaClassName: PropTypes.string,
  formRegistration: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function TextArea({
  id,
  name,
  label,
  value,
  errors,
  onChange,
  onKeyDown,
  className,
  textHelper,
  isDisabled,
  placeholder,
  type = "text",
  textAreaClassName,
  formRegistration = null,
}) {
  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedId = id || formName;
  const computedName = name || formName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1.5
    text-sm
    font-medium
    text-grey-700
    first-letter:uppercase
  `;

  const textAreaCn = ({ hasErrors }) => cntl`
    relative
    form-input
    py-2.5
    px-3.5
    border
    w-full
    enable:bg-white
    disabled:bg-grey-50
    shadow-sm
    rounded-lg
    focus:ring-4
    enabled:text-grey-900
    disabled:text-grey-500
    focus:ring-offset-0
    overflow-y-auto
    ${
      hasErrors
        ? cntl`focus:ring-error-100 focus:border-error-300 border-error-300`
        : cntl`border-grey-300 focus:ring-primary-100 focus:border-primary-300`
    }
    ${textAreaClassName ? textAreaClassName : undefined}
  `;

  const textHelpCn = () => cntl`
    mt-1.5
    text-sm
    text-grey-500
    first-letter:uppercase
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1.5
    text-sm
    text-error-500
    first-letter:uppercase
    ${!hasErrors && cntl`invisible`}
  `;

  return (
    <div className={wrapperCn()}>
      {!!label && <label className={labelCn()}>{label}</label>}
      <textarea
        type={type}
        id={computedId}
        name={computedName}
        onKeyDown={onKeyDown}
        disabled={isDisabled}
        placeholder={placeholder}
        className={textAreaCn({ hasErrors: !!errorMessage })}
        aria-invalid={errorMessage ? "true" : "false"}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        value={value}
        {...rest}
      />
      {textHelper && !!!errorMessage && (
        <div className={textHelpCn()} data-cypress="form-help-msg">
          {textHelper}
        </div>
      )}
      {!!errorMessage && (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      )}
    </div>
  );
}

TextArea.propTypes = propTypes;
export default TextArea;
