import cntl from "cntl";
import PropTypes from "prop-types";
import { usePagination } from "@dbox/core/hooks/usePagination";
import { useWindowSize } from "@dbox/core/hooks/useWindowSize";
import { useMemo, useState, useEffect, useCallback } from "react";
import PaginationContentNumbers from "./PaginationContentNumbers";
import PaginationContentDetails from "./PaginationContentDetails";
import PaginationContentButtonGroup from "./PaginationContentButtonGroup";
import { BREAKPOINT, BUTTON_VARIANTS, PAGINATION_VARIANTS } from "../constants";

const propTypes = {
  to: PropTypes.number,
  from: PropTypes.number,
  total: PropTypes.number,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  canNextPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  variant: PropTypes.oneOf([
    PAGINATION_VARIANTS.card_button,
    PAGINATION_VARIANTS.card_center,
    PAGINATION_VARIANTS.card_default,
    PAGINATION_VARIANTS.card_left,
    PAGINATION_VARIANTS.card_right,
    PAGINATION_VARIANTS.page_button,
    PAGINATION_VARIANTS.page_default,
  ]),
};

function Pagination({
  total,
  variant,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  canNextPage,
  previousPage,
  canPreviousPage,
  siblingCount = 1,
  paginationSize = 15,
}) {
  const { width } = useWindowSize();
  const paginationRange = usePagination({
    total,
    pageIndex,
    siblingCount,
    paginationSize,
  });
  const [padding, setPadding] = useState("");
  const [isMobile, setIsMobile] = useState();
  const [borderTop, setBorderTop] = useState("");
  const [buttonVariant, setButtonVariant] = useState();
  const [isDetailsVisible, setIsDetailsVisible] = useState();
  const [contentDirection, setContentDirection] = useState("");
  const [pagesInfo, setPagesInfo] = useState({
    lastPage: -1,
    currentPage: -1,
  });
  const [paginationData, setPaginationData] = useState({
    canNextPage: false,
    paginationRange: [],
    canPreviousPage: false,
  });

  const wrapperPagination = () => cntl`
    ${padding}
    ${borderTop}
  `;

  useEffect(() => {
    if (width >= BREAKPOINT.sm) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  useEffect(() => {
    setPagesInfo({
      lastPage: pageCount,
      currentPage: pageIndex,
    });
  }, [pageCount, pageIndex, isMobile]);

  useEffect(() => {
    setPaginationData({
      canNextPage,
      canPreviousPage,
      paginationRange,
    });
  }, [canNextPage, paginationRange, canPreviousPage]);

  useMemo(() => {
    switch (variant) {
      case PAGINATION_VARIANTS.page_default:
        setPadding("pt-2 sm:pt-5");
        setBorderTop("border-t border-t-grey-200");
        setButtonVariant(
          isMobile ? BUTTON_VARIANTS.border : BUTTON_VARIANTS.border_off
        );
        setIsDetailsVisible(isMobile ? true : false);
        break;
      case PAGINATION_VARIANTS.page_button:
        setPadding("pt-2 sm:pt-5");
        setButtonVariant(BUTTON_VARIANTS.border);
        setBorderTop("border-t border-t-grey-200");
        setIsDetailsVisible(isMobile ? true : false);
        break;
      case PAGINATION_VARIANTS.card_default:
        setBorderTop("border-t-none");
        setButtonVariant(BUTTON_VARIANTS.border);
        setIsDetailsVisible(isMobile ? true : false);
        setPadding("py-3 px-4 sm:pt-3 sm:pb-4 sm:px-6");
        break;
      case PAGINATION_VARIANTS.card_right:
        setIsDetailsVisible(true);
        setContentDirection("flex-row");
        setButtonVariant(BUTTON_VARIANTS.border);
        setBorderTop("border-t border-t-grey-200");
        setPadding("py-3 px-4 sm:pt-3 sm:pb-4 sm:px-6");
        break;
      case PAGINATION_VARIANTS.card_left:
        setIsDetailsVisible(true);
        setContentDirection("flex-row-reverse");
        setButtonVariant(BUTTON_VARIANTS.border);
        setBorderTop("border-t border-t-grey-200");
        setPadding("py-3 px-4 sm:pt-3 sm:pb-4 sm:px-6");
        break;
      case PAGINATION_VARIANTS.card_center:
        setIsDetailsVisible(true);
        setButtonVariant(BUTTON_VARIANTS.border);
        setBorderTop("border-t border-t-grey-200");
        setPadding("py-3 px-4 sm:pt-3 sm:pb-4 sm:px-6");
        break;
      case PAGINATION_VARIANTS.card_button:
        setIsDetailsVisible(false);
        setPadding("py-3 px-2 sm:py-3 sm:px-2");
        setBorderTop("border-t border-t-grey-200");
        break;
      default:
        setPadding("pt-2 sm:pt-5");
        setBorderTop("border-t border-t-grey-200");
        setButtonVariant(
          isMobile ? BUTTON_VARIANTS.border : BUTTON_VARIANTS.border_off
        );
        break;
    }
  }, [variant, isMobile]);

  const renderPagination = useCallback(() => {
    switch (variant) {
      case PAGINATION_VARIANTS.page_default:
        return (
          <PaginationContentNumbers
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      case PAGINATION_VARIANTS.page_button:
        return (
          <PaginationContentNumbers
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      case PAGINATION_VARIANTS.card_default:
        return (
          <PaginationContentNumbers
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      case PAGINATION_VARIANTS.card_right:
        return (
          <PaginationContentDetails
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            direction={contentDirection}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      case PAGINATION_VARIANTS.card_left:
        return (
          <PaginationContentDetails
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            direction={contentDirection}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      case PAGINATION_VARIANTS.card_center:
        return (
          <PaginationContentNumbers
            variant={variant}
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );

      case PAGINATION_VARIANTS.card_button:
        return (
          <PaginationContentButtonGroup
            variant={variant}
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
      default:
        return (
          <PaginationContentNumbers
            isMobile={isMobile}
            goToPage={gotoPage}
            nextPage={nextPage}
            pagesInfo={pagesInfo}
            previousPage={previousPage}
            buttonVariant={buttonVariant}
            paginationProps={paginationData}
            isDetailsVisible={isDetailsVisible}
          />
        );
    }
  }, [
    variant,
    isMobile,
    pagesInfo,
    gotoPage,
    nextPage,
    previousPage,
    buttonVariant,
    paginationData,
    contentDirection,
    isDetailsVisible,
  ]);

  return <div className={wrapperPagination()}>{renderPagination()}</div>;
}

Pagination.propTypes = propTypes;
export default Pagination;
