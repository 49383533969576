import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { Heading, Paragraph } from "../atoms";

const proptypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  harMarginTop: PropTypes.bool,
  HasMarginBottom: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl]),
};

function FeatureText({ text, title, size, className }) {
  const wrapperCn = () => cntl`
    p-5
    flex
    my-8
    lg:p-6
    flex-col
    lg:my-12
    bg-grey-50
    ${className ? className : undefined}
  `;

  const commonProps = {
    size,
    text,
    title,
  };

  return (
    <div className={wrapperCn()}>
      <Heading hasMarginBottom {...commonProps} />
      <Paragraph {...commonProps} />
    </div>
  );
}

FeatureText.prototype = proptypes;
export default FeatureText;
