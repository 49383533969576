import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonGrey } from "../atoms";
import { useTranslation } from "react-i18next";
import { PaginationCounter } from "../molecules";
import { POSITIONS, SIZE } from "../constants";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  isMobile: PropTypes.bool,
  nextPage: PropTypes.func,
  goToPage: PropTypes.func,
  direction: PropTypes.string,
  previousPage: PropTypes.func,
  buttonVariant: PropTypes.string,
  isDetailsVisible: PropTypes.bool,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
  paginationProps: PropTypes.exact({
    canNextPage: PropTypes.bool,
    canPreviousPage: PropTypes.bool,
    paginationRange: PropTypes.array,
  }),
};

function PaginationContentDetails({
  isMobile,
  nextPage,
  goToPage,
  pagesInfo,
  direction,
  previousPage,
  buttonVariant,
  paginationProps,
  isDetailsVisible,
}) {
  const { t } = useTranslation();

  const paginationContentNumbersCn = () => cntl`
    flex
    items-center
    justify-between
    ${direction}
  `;

  const buttonsCn = () => cntl`
    flex
    gap-x-3
    flex-row
    items-center
    ${isMobile && cntl`w-full justify-between`}
  `;

  return (
    <div className={paginationContentNumbersCn()}>
      {!isMobile && (
        <PaginationCounter
          pagesInfo={pagesInfo}
          isDetailsVisible={isDetailsVisible}
          range={paginationProps?.paginationRange}
          onPageClick={(value) => goToPage(value - 1)}
        />
      )}
      <div className={buttonsCn()}>
        <ButtonGrey
          size={SIZE.sm}
          icon={isMobile ? <IconArrowLeft /> : null}
          iconPosition={POSITIONS.left}
          variant={buttonVariant}
          onClick={() => previousPage()}
          label={!isMobile ? t("button.label.previous") : ""}
          disabled={!paginationProps?.canPreviousPage}
        />
        {isMobile && (
          <PaginationCounter
            pagesInfo={pagesInfo}
            isDetailsVisible={isDetailsVisible}
            range={paginationProps?.paginationRange}
            onPageClick={(value) => goToPage(value - 1)}
          />
        )}
        <ButtonGrey
          size={SIZE.sm}
          icon={isMobile ? <IconArrowRight /> : null}
          iconPosition={POSITIONS.right}
          onClick={() => nextPage()}
          variant={buttonVariant}
          label={!isMobile ? t("button.label.next") : ""}
          disabled={!paginationProps?.canNextPage}
        />
      </div>
    </div>
  );
}

PaginationContentDetails.propTypes = propTypes;
export default PaginationContentDetails;
