import T from "prop-types";
import { BadgeText } from "../atoms";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";
import { IconTrash } from "@tabler/icons-react";

const propTypes = {
  onClick: T.func,
  quote: T.object,
  className: T.string,
  expirationDate: T.object,
};

function QuoteCardHeader({
  quote,
  onClick,
  className,
  expirationDate,
}) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col ${className ? className : undefined}`}>
      <div className="mb-2">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <div className="uppercase text-grey-900 font-medium text-xl mr-4">
              {quote?.id || "n/a"}
            </div>
            <BadgeText label={quote?.status} />
          </div>
          <IconTrash
            onClick={onClick}
            className="w-5 h-5 text-grey-500 hover:cursor-pointer"
          />
        </div>
        <div className="flex flex-row">
          <div className="text-base text-grey-900 font-medium first-letter:uppercase">
            {!!quote?.customer_data &&
            quote?.customer_data?.firstname?.length &&
            quote?.customer_data?.lastname
              ? quote?.customer_data?.firstname +
                " " +
                quote?.customer_data?.lastname
              : !!quote?.customer_data && quote?.customer_data?.name?.length
              ? quote?.customer_data?.name
              : "n/a"}
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className="flex flex-row">
          <div className="text-sm text-grey-500 first-letter:uppercase">
            {t("card.total")}&nbsp;{t("card.with_vat")}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="text-xl text-grey-900 font-medium">
            {t("data.general.euro")}&nbsp;
            {formatCurrency(quote?.total_price) || "n/a"}
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className="flex flex-row">
          <div className="text-sm text-grey-500 first-letter:uppercase">
            {t("card.total")}&nbsp;{t("card.without_vat")}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="text-base text-grey-900 font-medium">
            {t("data.general.euro")}&nbsp;
            {formatCurrency(quote?.price) || "n/a"}
          </div>
        </div>
      </div>
      <div
        className={`text-sm font-semibold first-letter:uppercase mb-2 ${
          expirationDate?.isExpired ? `text-error-600` : `text-grey-900`
        }`}
      >
        {t("card.expiration_date") +
          t("data.general.colon") +
          " " +
          expirationDate?.expiringDate}
      </div>
    </div>
  );
}

QuoteCardHeader.propTypes = propTypes;
export default QuoteCardHeader;
