import T from "prop-types";
import { useState, useEffect } from "react";
import { BadgeText, Divider } from "../atoms";
import { useTranslation } from "react-i18next";

const propTypes = {
  className: T.string,
  rowData: T.object,
};

function StatusPreview({ className, rowData }) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [jobs, setJobs] = useState([]);
  const [title, setTitle] = useState("");
  const [badgeValue, setBadgeValue] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    setName(rowData?.name);
    setJobs(rowData?.jobs);
    setTitle(t("card.jobs"));
    setBadgeValue(rowData?.status);
    setCode(rowData?.code);
  }, [rowData, t]);

  return (
    <div className={`flex flex-col p-4 ${className ? className : undefined}`}>
      <div className="flex flex-row">
        <div className="text-xl text-grey-900 font-semibold first-letter:uppercase">
          {name}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between mt-2">
        {!!badgeValue && <BadgeText label={badgeValue} />}
        {!!code && (
          <div className="text-sm text-grey-500 first-letter:uppercase">
            {code}
          </div>
        )}
      </div>
      <Divider marginY="my-4" tone="dark" />
      <div className="flex flex-row mb-3">
        <div className="text-base text-grey-900 first-letter:uppercase">
          {title}
        </div>
      </div>
      <div className="flex flex-col">
        {jobs?.map((job, index) => {
          return (
            <div
              className="flex flex-row items-center justify-between my-2"
              key={`job-${index}`}
            >
              <div className="text-sm text-grey-900 first-letter:uppercase">
                {job?.job?.name}
              </div>
              <BadgeText label={job?.job?.code} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

StatusPreview.propTypes = propTypes;
export default StatusPreview;
