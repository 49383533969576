import cntl from "cntl";
import { useDispatch, useSelector } from "react-redux";
import { getSnackbarState } from "@dbox/cromie-common/store/reducers/app.reducer";
import { closeSnackbar } from "@dbox/core/actions/common/ui";
import { IconBell, IconCheck, IconX } from "@tabler/icons-react";

const propTypes = {};
function Snackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector(getSnackbarState);
  const onClose = () => dispatch(closeSnackbar());

  const snackbarCn = ({ open, type }) => cntl`
  ${open ? cntl`flex` : cntl`hidden`}
    ${
      type === "success"
        ? cntl`border border-solid border-success-700 text-success-700`
        : type === "error"
        ? cntl`border border-solid border-error-700 text-error-700`
        : cntl`border border-solid border-warning-700 text-warning-700`
    }
    fixed
    justify-between
    shadow-sm
    right-4
    m-4
    p-4
    rounded-lg
    z-[100]
    bg-white
    lg:w-[35rem]
  `;

  return (
    <div
      className={`${snackbarCn({
        open: snackbar.open,
        type: snackbar.type,
      })}`}
      data-cypress="snackbar"
    >
      <div className="flex items-center">
        <div
          className={`w-8 h-8 flex justify-center items-center rounded ${
            snackbar.type === "success" ? "bg-success-25" : "bg-error-25"
          }`}
        >
          {snackbar.type === "error" ? (
            <IconBell className="w-5 h-5 text-error-700" />
          ) : snackbar.type === "success" ? (
            <IconCheck className="w-5 h-5 text-success-700" />
          ) : (
            <IconBell className="w-5 h-5 text-error-700" />
          )}
        </div>
        <div className="flex flex-1 pl-4">
          <p className="first-letter:capitalize">{snackbar.message}</p>
        </div>
      </div>
      <div className="cursor-pointer" onClick={onClose}>
        <IconX
          className={`w-5 h-5 ${
            snackbar.type === "success" ? "text-success-600" : "text-error-600"
          }`}
        />
      </div>
    </div>
  );
}

Snackbar.propTypes = propTypes;

export default Snackbar;
