import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { SIZE } from "../constants";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  supportingText: PropTypes.string,
  formRegistration: PropTypes.object,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
};

function Radio({
  id,
  name,
  size,
  label,
  value,
  onBlur,
  onChange,
  isChecked,
  className,
  isDisabled,
  supportingText,
  formRegistration = null,
}) {
  const [rounded, setRounded] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [checkboxSize, setCheckboxSize] = useState("");

  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedName = name || formName;

  const computedId = id || computedName;

  const inputCn = () => cntl`
    mt-0.5
    form-radio
    border
    border-grey-300
    focus:ring
    focus:ring-offset-0
    focus:ring-primary-100
    focus:border-primary-200
    disabled:bg-grey-100
    disabled:border-grey-200
    disabled:hover:bg-grey-100
    enable:bg-white
    enabled:checked:text-primary-600
    enabled:checked:hover:bg-primary-600
    enabled:indeterminate:text-primary-600
    enabled:indeterminate:hover:bg-primary-50
    enabled:hover:border-primary-600
    enabled:hover:bg-primary-50
    ${rounded}
    ${checkboxSize}
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    ${fontStyle}
    first-letter:capitalize
  `;

  const onlyLabelCn = () => cntl`
    text-grey-700
    ${!!supportingText?.length ? cntl`font-medium` : cntl`font-regular`}
  `;

  const onlySupportingTextCn = () => cntl`
    text-grey-500
    first-letter:uppercase
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        setRounded("rounded-full");
        break;
      case SIZE.md:
        setFontStyle("text-base");
        setCheckboxSize("w-5 h-5");
        setRounded("rounded-full");
        break;
      default:
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        setRounded("rounded-full");
        break;
    }
  }, [size]);

  return (
    <div className="flex flex-row items-start gap-x-2">
      <input
        id={computedId}
        value={value}
        type="radio"
        checked={isChecked}
        name={computedName}
        disabled={isDisabled}
        aria-hidden="true"
        className={inputCn()}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      />
      {!!label?.length && (
        <label htmlFor={computedId} className={labelCn()}>
          <div className={onlyLabelCn()}>{label}</div>
          {!!supportingText?.length && (
            <div className={onlySupportingTextCn()}>{supportingText}</div>
          )}
        </label>
      )}
    </div>
  );
}

Radio.propTypes = propTypes;
export default Radio;
