import cntl from "cntl";
import PropTypes from "prop-types";
import { Pagination } from "..";
import { useState, useEffect, cloneElement, useCallback, useMemo } from "react";
import { LoaderIcon } from "../icons";
import { PAGINATION_VARIANTS } from "../constants";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IconArrowDown, IconArrowsUpDown, IconArrowUp } from "@tabler/icons-react";

const propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  hiddenHeader: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  paginationSize: PropTypes.number,
  pageCount: PropTypes.number,
  fetch: PropTypes.func,
  total: PropTypes.number,
  previewType: PropTypes.string,
  emptyState: PropTypes.node,
  isLoading: PropTypes.bool,
  childrenWidth: PropTypes.string,
  jsonKey: PropTypes.string,
  className: PropTypes.string,
  variantPagination: PropTypes.oneOf([
    PAGINATION_VARIANTS.card_button,
    PAGINATION_VARIANTS.card_center,
    PAGINATION_VARIANTS.card_default,
    PAGINATION_VARIANTS.card_left,
    PAGINATION_VARIANTS.card_right,
    PAGINATION_VARIANTS.page_button,
    PAGINATION_VARIANTS.page_default,
  ]),
};

function AdvancedDataGrid({
  hiddenHeader,
  columns,
  data,
  paginationSize = 15,
  pageCount,
  from,
  to,
  fetch,
  total,
  roundedBorder = true,
  previewType,
  children,
  isLoading,
  emptyState,
  childrenWidth,
  jsonKey,
  className,
  variantPagination,
  settings,
  ...props
}) {
  const [sorting, setSorting] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [dataToPreview, setDataToPreview] = useState();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationSize,
  });

  const thHeaderSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    flex-row
    select-none
    items-center
    cursor-pointer
    ${
      textAlign?.toLowerCase() === "left"
        ? cntl`justify-start`
        : cntl`justify-end`
    }
  `;

  const thHeaderNoSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    flex-row
    ${
      textAlign?.toLowerCase() === "left"
        ? cntl`justify-start`
        : cntl`justify-end`
    }
  `;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount,
    autoResetPage: false,
    manualPagination: true,
    initialState: { pageIndex: 0 },
    onSortingChange: setSorting,
    state: {
      sorting,
      pagination,
    },
    onPaginationChange: setPagination,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  const dataGridCn = cntl`
    grow
    overflow-x-auto 
    overflow-y-hidden
    border
    border-grey-200
    bg-clip-border
    bg-white
    ${roundedBorder ? cntl`rounded-l-lg` : cntl`rounded-lg`}
  `;

  useEffect(() => {
    fetch({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
  }, [fetch, pagination.pageIndex, pagination.pageSize]);

  const setDataPreview = useCallback(
    (data, index) => {
      setClickedIndex(index || 0);
      if (!!data?.length) {
        switch (previewType?.toLowerCase()) {
          case "json":
            if (jsonKey) {
              setDataToPreview(JSON.stringify(data[index][jsonKey], null, 4));
            } else {
              setDataToPreview(JSON.stringify(data[index], null, 4));
            }
            break;
          default:
            setDataToPreview(data[index]);
            break;
        }
      }
    },
    [previewType, jsonKey]
  );

  useEffect(() => {
    if (!!data?.length) {
      setDataPreview(data, 0);
    }
  }, [data, setDataPreview]);

  const renderPreview = (previewType, dataToPreview) => {
    switch (previewType?.toLowerCase()) {
      case "json":
        return (
          <div className="flex-none w-6/12 bg-white">
            <textarea
              disabled
              className="json-scroller overflow-y-auto w-full h-full p-4 font-mono text-sm rounded-r-lg border border-solid border-grey-200 text-grey-900 border-l-0"
              style={{ resize: "none" }}
              value={dataToPreview}
            />
          </div>
        );
      default:
        return (
          <div
            className={`flex-none ${
              childrenWidth ? childrenWidth : "w-72"
            } border-l-0 border border-solid border-grey-200 rounded-r-lg`}
          >
            {cloneElement(children, {
              rowData: dataToPreview,
            })}
          </div>
        );
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-row items-center justify-center">
          <LoaderIcon className="animate-spin" />
        </div>
      ) : (
        <>
          {data?.length === 0 ? (
            <>{emptyState}</>
          ) : (
            <div
              className={`flex flex-col gap-y-4 ${
                className ? className : undefined
              }`}
              {...props}
            >
              <div className="flex flex-row justify-center min-h-[896px]">
                <div className={dataGridCn}>
                  <table className={`w-full ${settings?.table?.bgColor}`}>
                    {!hiddenHeader && (
                      <thead
                        className={`h-11 text-xs font-medium text-grey-600 border-b border-b-grey-200 ${
                          settings?.table?.header?.bgColor || `bg-grey-50`
                        }`}
                      >
                        {table?.getHeaderGroups() &&
                          table?.getHeaderGroups()?.map((headerGroup) => {
                            return (
                              <tr key={headerGroup.id}>
                                {headerGroup?.headers?.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      className={`px-6 text-left`}
                                      style={{
                                        width:
                                          header.getSize() !== 0
                                            ? header.getSize()
                                            : undefined,
                                      }}
                                    >
                                      <div
                                        {...{
                                          className: header.column.getCanSort()
                                            ? thHeaderSort({
                                                textAlign:
                                                  header.column.columnDef
                                                    .textHeaderAlign || "left",
                                              })
                                            : thHeaderNoSort({
                                                textAlign:
                                                  header.column.columnDef
                                                    .textHeaderAlign || "left",
                                              }),
                                          onClick:
                                            header.column.getToggleSortingHandler(),
                                        }}
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {header.column.getCanSort() && (
                                          <div className="w-[13px] h-[13px]">
                                            {!header.column.getIsSorted() && (
                                              <IconArrowsUpDown className="w-[13px] h-[13px]" />
                                            )}
                                            {{
                                              asc: (
                                                <IconArrowDown className="w-[13px] h-[13px]" />
                                              ),
                                              desc: (
                                                <IconArrowUp className="w-[13px] h-[13px]" />
                                              ),
                                            }[header.column.getIsSorted()] ??
                                              null}
                                          </div>
                                        )}
                                      </div>
                                    </th>
                                  );
                                })}
                              </tr>
                            );
                          })}
                      </thead>
                    )}
                    <tbody>
                      {table.getRowModel().rows.map((row, index) => {
                        return (
                          <tr
                            onClick={() => {
                              setDataPreview(data, index);
                            }}
                            key={row.id}
                            className={`hover:bg-grey-50 border-b border-b-grey-200 last:border-b-0
                              ${
                                index === clickedIndex
                                  ? `bg-primary-25`
                                  : undefined
                              }
                              ${settings?.table?.row?.height || `h-[4.5rem]`}
                        `}
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {renderPreview(previewType, dataToPreview)}
              </div>
              <Pagination
                to={to}
                from={from}
                total={total}
                pageCount={pageCount}
                variant={variantPagination}
                paginationSize={paginationSize}
                nextPage={() => table.nextPage()}
                canNextPage={table.getCanNextPage()}
                previousPage={() => table.previousPage()}
                canPreviousPage={table.getCanPreviousPage()}
                gotoPage={(index) => table.setPageIndex(index)}
                pageIndex={table.getState().pagination.pageIndex}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

AdvancedDataGrid.propTypes = propTypes;
export default AdvancedDataGrid;
