import cntl from "cntl";
import PropTypes from "prop-types";
import { TextLink } from "@dbox/components";
import { Trans, useTranslation } from "react-i18next";
import VerificationCodeForm from "./VerificationCodeForm";
import { LINK_VARIANTS, POSITIONS } from "@dbox/components/constants";
import VerificationTitleAndSubtitle from "../atoms/VerificationTitleAndSubtitle";
import { IconMail } from "@tabler/icons-react";

const propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  onClickTextLink: PropTypes.func,
  isButtonLoading: PropTypes.bool,
  onClickResendEmail: PropTypes.func,
};

function ContainerVerificationStep2({
  email,
  onSubmit,
  className,
  onClickTextLink,
  isButtonLoading,
  onClickResendEmail,
}) {
  const { t } = useTranslation();

  const container = () => cntl`
    px-4
    xs:px-0
    xs:w-[22.5rem]
    ${className ? className : undefined}
  `;

  const wrapperCn = () => cntl`
    flex
    gap-y-8
    flex-col
  `;

  const textLinkCn = () => cntl`
    self-center
  `;

  const resendCn = () => cntl`
    flex
    gap-x-1
    text-sm
    flex-row
    flex-wrap
    items-end
    text-grey-600
    justify-center
  `;

  const clickCn = () => cntl`
    font-semibold
    cursor-pointer
    text-primary-700
  `;

  const ResendEmail = () => {
    return (
      <div className={resendCn()}>
        <Trans
          i18nKey="page.verification.step2.body.resend_email"
          values={{
            clickResendEmail: t(
              "page.verification.step2.body.click_to_resend"
            ),
          }}
          components={{
            c: <span className={clickCn()} onClick={onClickResendEmail} />,
          }}
        />
      </div>
    );
  };

  return (
    <section className={container()}>
      <div className={wrapperCn()}>
        <VerificationTitleAndSubtitle
          icon={<IconMail />}
          title={t("page.verification.step2.title")}
          subTitle={
            <Trans
              i18nKey="page.verification.step2.sub_title"
              values={{
                email: email,
              }}
              components={{
                e: <span className="font-semibold" />,
              }}
            />
          }
        />
        <VerificationCodeForm
          onSubmit={onSubmit}
          isButtonLoading={isButtonLoading}
        />
        <ResendEmail />
        <TextLink
          className={textLinkCn()}
          onAction={onClickTextLink}
          variant={LINK_VARIANTS.grey}
          iconPosition={POSITIONS.left}
          label={t("text_link.label.back_to_login")}
        />
      </div>
    </section>
  );
}

ContainerVerificationStep2.propTypes = propTypes;
export default ContainerVerificationStep2;
