import T from "prop-types";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";
import { IconClock } from "@tabler/icons-react";

const propTypes = {
  className: T.string,
  data: T.object.isRequired,
};

function QuoteTable({ data, className }) {
  const { t } = useTranslation();

  const headerValue = [
    t("table.header.name"),
    t("table.header.materials"),
    t("table.header.setup"),
    t("table.header.production"),
  ];

  return (
    <table className={`flex flex-col ${className ? className : undefined}`}>
      <thead>
        <tr className="flex flex-row justify-between p-2 border-b border-b-grey-200">
          {headerValue.map((value, index) => {
            return (
              <th
                key={`workflow-value-header-${index}`}
                className="w-1/4 first:text-left text-right text-sm font-medium text-grey-900 first-letter:capitalize"
              >
                {value}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data?.steps?.length &&
          data?.steps
            ?.filter((step) => step !== null)
            ?.map(({ machine, values }, index) => {
              return (
                <tr
                  key={`workflow-value-row-${index}`}
                  className="flex flex-row justify-between py-1 px-2 border-b border-b-grey-200 last:border-none"
                >
                  <td className="w-1/4 text-sm text-grey-700 first-letter:capitalize">{machine?.name || "n/a"}</td>
                  <td className="w-1/4 text-sm text-grey-700 first-letter:capitalize">
                    <div className="flex flex-col items-end">
                      <div className="flex flex-row">
                        {values?.costs?.materials ? (
                          <>
                            {t("data.general.euro")}
                            <>&nbsp;</>
                            {formatCurrency(values?.costs?.materials)}
                          </>
                        ) : (
                          <>n/a</>
                        )}
                      </div>
                      <IconClock className="w-5 h-5 text-grey-500" />
                    </div>
                  </td>
                  <td className="w-1/4 flex flex-col items-end">
                    <div className="text-sm text-grey-700">
                      {t("data.general.euro")}
                      <>&nbsp;</>
                      {formatCurrency(values?.costs?.setup) || "n/a"}
                    </div>
                    <div className="text-sm text-grey-700">
                      {values?.time?.setup ? <>{values?.time?.setup}'</> : <>n/a</>}
                    </div>
                  </td>
                  <td className="w-1/4 flex flex-col items-end">
                    <div className="text-sm text-grey-700">
                      {t("data.general.euro")}
                      <>&nbsp;</>
                      {formatCurrency(values?.costs?.production) || "n/a"}
                    </div>
                    <div className="text-sm text-grey-700">
                      {values?.time?.production ? <>{values?.time?.production}'</> : <>n/a</>}
                    </div>
                  </td>
                </tr>
              );
            })}
      </tbody>
    </table>
  );
}

QuoteTable.propTypes = propTypes;
export default QuoteTable;
