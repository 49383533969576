import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRoundedFull: PropTypes.bool,
};

function PaginationNumberBase({
  value,
  onClick,
  isActive,
  isDisabled = false,
  isRoundedFull = false,
}) {
  const wrapperCn = () => cntl`
    w-10
    h-10
    flex
    text-sm
    flex-row
    font-medium
    items-center
    cursor-pointer
    justify-center
    text-grey-600
    hover:bg-grey-50
    hover:text-grey-800
    ${isActive ? cntl`bg-grey-50 text-grey-800` : cntl`bg-white`}
    ${isRoundedFull ? "rounded-full" : "rounded-lg"}
    ${isDisabled ? cntl`pointer-events-none` : cntl`pointer-events-auto	`}
  `;

  return (
    <div className={wrapperCn()} onClick={() => onClick(value)}>
      {value}
    </div>
  );
}

PaginationNumberBase.propTypes = propTypes;
export default PaginationNumberBase;
