import PropTypes from "prop-types";

const propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  className: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

function InfoContainer({
  icon,
  text,
  borderColor,
  textColor,
  backgroundColor,
  className,
}) {
  return (
    <div className={`mb-2 ${className ? className : undefined}`}>
      <div
        className={`flex flex-row gap-x-2 items-start p-2 rounded-lg 
          border border-solid ${borderColor ? borderColor : ""}
          ${textColor ? textColor : ""}
          ${backgroundColor ? backgroundColor : ""}
          `}
      >
        <>{icon}</>
        <div className="text-sm first-letter:uppercase">
          {text}
        </div>
      </div>
    </div>
  );
}

InfoContainer.propTypes = propTypes;
export default InfoContainer;
