import cntl from "cntl";
import PropTypes from "prop-types";
import { WIDTH } from "../constants";
import { cloneElement, Children, Fragment, useState } from "react";

const propTypes = {
  onSelect: PropTypes.func,
  isVisible: PropTypes.bool,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  menuRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.oneOf([WIDTH.xs, WIDTH.sm, WIDTH.md, WIDTH.lg, WIDTH.xl]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Menu({
  width,
  style,
  menuRef,
  onSelect,
  children,
  className,
  isVisible,
  scrollable,
}) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const menuScrollable = () => cntl`
    ${scrollable ? `max-h-[20rem] overflow-y-auto` : `overflow-hidden`}
  `;

  const menuCn = () => cntl`
    z-10
    bg-white
    shadow-lg
    rounded-lg
    ${width}
    ${menuScrollable()}
    ${className ? className : undefined}
  `;

  const setElementClicked = (value, index) => {
    if (value && index !== -1) {
      onSelect(value);
      setActiveIndex(index);
    }
  };

  return (
    <>
      {isVisible && (
        <ul style={style} ref={menuRef} className={menuCn()}>
          {children &&
            Children.map(children, (child, index) => {
              return (
                <Fragment key={`menu-child-${index}`}>
                  {cloneElement(child, {
                    isSelected: activeIndex === index,
                    onClick: (value) => setElementClicked(value, index),
                  })}
                </Fragment>
              );
            })}
        </ul>
      )}
    </>
  );
}

Menu.propTypes = propTypes;
export default Menu;
