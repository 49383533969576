import cntl from "cntl";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  title: PropTypes.text,
  cta: PropTypes.string,
  supportingText: PropTypes.text,
};

function CardSelection({ title, supportingText, cta }) {
  const navigate = useNavigate();

  const cardHeaderCn = () => cntl`
    flex
    p-6
    border
    w-full
    flex-col
    gap-y-4
    shadow-sm
    rounded-lg
    justify-center
    items-center
    border-grey-200
    bg-white
    group
  `;

  const titleAndCtaCn = () => cntl`
    flex
    flex-row
    justify-between
    items-center
    w-full
  `;

  const titleCn = () => cntl`
    text-xl
    font-semibold
    group-hover:text-primary-600
    group-hover:underline
  `;

  const supportingTextCn = () => cntl`
    text-base
    text-grey-600
    w-full
    text-start
  `;

  const ctaIconCn = () => cntl`
    w-5 
    h-5 
    text-grey-900
    cursor-pointer
  `;

  return (
    <div className={cardHeaderCn()}>
      <div className={titleAndCtaCn()}>
        <div className={titleCn()}>{title}</div>
        <IconArrowRight className={ctaIconCn()} onClick={() => navigate(cta)} />
      </div>
      <div className={supportingTextCn()}>{supportingText}</div>
    </div>
  );
}

CardSelection.prototype = propTypes;
export default CardSelection;
