import T from "prop-types";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { QuoteTable } from "..";
import { formatUnit } from "@dbox/core/utils/formatUnit";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";

const propTypes = {
  isOpen: T.bool,
  data: T.array.isRequired,
};

function ContentWorkflowAccordion({ isOpen = false, data }) {
  const { t } = useTranslation();

  const gridItems = (obj) => {
    if (obj) {
      return [
        {
          key: t("accordion.content.name"),
          value: <>{obj.imposition?.imposition?.name || "n/a"}</>,
        },
        obj.imposition?.imposition?.segnature_name
          ? {
              key: t("accordion.content.segnature_name"),
              value: <>{obj.imposition?.imposition?.segnature_name || "n/a"}</>,
            }
          : {},
        {
          key: t("accordion.content.sheets"),
          value: (
            <>
              <>n.</>
              <>&nbsp;</>
              {obj.imposition?.imposition?.number_of_sheets || "n/a"}
            </>
          ),
        },
        {
          key: t("accordion.content.number_of_booklets"),
          value: (
            <>
              <>n.</>
              <>&nbsp;</>
              {obj.imposition?.imposition?.number_of_booklets || "n/a"}
            </>
          ),
        },
        {
          key: t("accordion.content.paper_waste_percentual"),
          value: (
            <>
              {obj.imposition?.imposition?.paper_waste_percentual || "n/a"}
              <>&nbsp;</>
              <>%</>
            </>
          ),
        },
        {
          key: t("accordion.content.starts"),
          value: (
            <>
              <>n.</>
              <>&nbsp;</>
              {obj.imposition?.imposition?.number_of_starts || "n/a"}
            </>
          ),
        },
        {
          key: t("accordion.content.repeats"),
          value: (
            <>
              <>n.</>
              <>&nbsp;</>
              {obj.imposition?.yield?.repeats || "n/a"}
            </>
          ),
        },
        {
          key: t("accordion.content.sheets_format"),
          value: (
            <>
              {formatUnit(obj.imposition?.sheet?.width, "millimeter") || "n/a"}
              <>&nbsp;</>
              {t("data.general.x")}
              <>&nbsp;</>
              {formatUnit(obj.imposition?.sheet?.height, "millimeter") || "n/a"}
            </>
          ),
        },
        // {
        //   key: t("accordion.content.type_of_printing"),
        //   value: <>{obj.imposition?.print_type || "n/a"}</>,
        // },
        {
          key: t("accordion.content.sheet_cost"),
          value: <>{formatCurrency(obj.imposition?.costs) + " " + t("data.general.euro") || "n/a"}</>,
        },
      ];
    }
  };

  return (
    <div className={`rounded-lg bg-grey-25 ${isOpen ? `mt-2` : `mt-0`}`}>
      {isOpen &&
        data?.map((job, index) => {
          return (
            <div className="flex flex-col p-2 mb-4 last:mb-0" key={`workflow-content-accordion-${index}`}>
              <div className="text-base text-teal-500 font-medium first-letter:capitalize">
                {t("accordion.content.imposition")}
                <>&nbsp;</>
                {index + 1}
              </div>
              <div className="grid grid-cols-2 gap-y-2 mt-4">
                {gridItems(job)?.map(({ key, value }, idx) => {
                  if (key && value) {
                    return (
                      <Fragment key={`quote-workflow-grid-value-${idx}`}>
                        <div className="text-sm text-grey-700 font-medium first-letter:capitalize">{key}</div>
                        <div className="text-sm text-grey-900 font-medium">{value}</div>
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              {job?.imposition && (
                <QuoteTable className={`mt-4 ${job?.steps?.length ? `block` : `hidden`}`} data={job} />
              )}
            </div>
          );
        })}
    </div>
  );
}

ContentWorkflowAccordion.propTypes = propTypes;
export default ContentWorkflowAccordion;
