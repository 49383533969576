import { get } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const propTypes = {
  quote: PropTypes.object,
  expiringDate: PropTypes.string,
};

function SubHeaderCustomerFinalize({ quote, expiringDate }) {
  const { t } = useTranslation();
  const [customerType, setCustomerType] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [customerAddress, setCustomerAddress] = useState({});

  useEffect(() => {
    if (!!quote) {
      setCustomerData(get(quote, "customer_data", {}));
      setCustomerType(get(quote, "customer_data.type", ""));
      setCustomerAddress(
        get(quote, "customer_data.addresses", []).filter(
          ({ type }) => type?.toLowerCase() === "billing"
        )
      );
    }
  }, [quote]);

  return (
    <div className="flex flex-row justify-between py-6">
      <div className="flex flex-col items-start">
        <div className="text-xl font-medium capitalize mb-2">
          {t("sub_header_customer_finalize.dear_sirs")}
          <>&nbsp;</>
          {customerType?.toLowerCase() === "individual" && (
            <>{customerData?.firstname + " " + customerData?.lastname}</>
          )}
          {customerType?.toLowerCase() === "corporate" && (
            <>{customerData?.name}</>
          )}
        </div>
        {customerType?.toLowerCase() === "corporate" && (
          <div className="flex flex-row">
            <div className="text-base text-grey-500 uppercase">
              {t("sub_header_customer_finalize.vatcode") +
                t("data.general.colon")}
              <>&nbsp;</>
            </div>
            <div className="text-base text-grey-700 uppercase">
              {customerData?.vatcode}
            </div>
          </div>
        )}
        <div className="text-base text-grey-700 capitalize">
          <>
            {customerAddress[0]?.street}
            <br />
          </>
          <>
            {customerAddress[0]?.postcode + " " + customerAddress[0]?.city}
            <br />
          </>
          <>{customerAddress[0]?.country}</>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div className="flex flex-row items-center">
          <div className="text-xl font-medium text-grey-500 capitalize">
            {t("sub_header_customer_finalize.quote_number") +
              t("data.general.colon")}
            <>&nbsp;</>
          </div>
          <div className="text-xl font-medium text-grey-700">{quote?.id}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className="text-base text-grey-500 capitalize">
            {t("sub_header_customer_finalize.expiration_date") +
              t("data.general.colon")}
            <>&nbsp;</>
          </div>
          <div className="text-base text-grey-700">{expiringDate}</div>
        </div>
      </div>
    </div>
  );
}

SubHeaderCustomerFinalize.propTypes = propTypes;
export default SubHeaderCustomerFinalize;
