import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useState, useMemo, cloneElement, useEffect, useCallback } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  dataType: PropTypes.string,
  isPassword: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  className: PropTypes.string,
  textHelper: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  formRegistration: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  color: PropTypes.exact({
    text: PropTypes.string,
    background: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

function Input({
  id,
  min,
  max,
  size,
  name,
  step,
  color,
  label,
  value,
  onBlur,
  errors,
  onClick,
  inputRef,
  children,
  datatype,
  iconLeft,
  onChange,
  iconRight,
  onKeyDown,
  className,
  textHelper,
  isPassword,
  isDisabled,
  placeholder,
  prefix = "",
  suffix = "",
  type = "text",
  onInputChange,
  inputClassName,
  labelClassName,
  width = "w-full",
  showErrorMessage = true,
  formRegistration = null,
}) {
  const [heigth, setHeigth] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [selectSize, setSelectSize] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [iconPosition, setIconPosition] = useState({
    leftIcon: "",
    rightIcon: "",
  });

  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedId = id || formName;
  const computedName = name || formName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    ${width}
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1.5
    text-sm
    font-medium
    text-grey-700
    first-letter:uppercase
    ${labelClassName ? labelClassName : undefined}
  `;

  const inputCn = ({ hasErrors }) => cntl`
    relative
    form-input
    py-0
    border
    w-full
    disabled:bg-grey-50
    shadow-sm
    rounded-lg
    focus:ring-4
    disabled:text-grey-500
    focus:ring-offset-0
    ${heigth}
    ${fontStyle}
    ${selectSize}
    ${color?.background}
    ${color?.text || `enabled:text-grey-900`}
    ${
      hasErrors
        ? cntl`focus:ring-error-100 focus:border-error-300 border-error-300`
        : cntl`border-grey-300 focus:ring-primary-100 focus:border-primary-300`
    }
    ${inputClassName ? inputClassName : undefined}
  `;

  const iconCn = () => cntl`
    w-5
    h-5
    z-[1]
    top-1/2
    absolute
    -translate-y-1/2
  `;

  const iconLeftCn = () => cntl`
    text-grey-400
    disabled:text-grey-500
    ${iconCn()}
    ${iconPosition?.leftIcon}
  `;

  const iconRightCn = ({ hasError }) => cntl`
    ${iconCn()}
    ${iconPosition?.rightIcon}
    ${hasError ? cntl`text-error-500` : cntl`text-grey-400`}
    disabled:text-grey-500
  `;

  const iconPasswordCn = () => cntl`
    right-4
    text-grey-400
    disabled:text-grey-500
    cursor-pointer
    ${iconCn()}
  `;

  const textHelpCn = () => cntl`
    mt-1.5
    text-sm
    text-grey-500
    disabled:text-grey-500
    first-letter:uppercase
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1.5
    text-sm
    text-error-500
    first-letter:uppercase
    ${!hasErrors && cntl`invisible`}
  `;

  const basePrefixAndSuffix = () => cntl`
    z-10
    absolute
    top-1/2
    text-grey-500
    disabled:text-grey-500
    -translate-y-1/2
    ${fontStyle}
  `;

  const prefixCn = () => cntl`
    left-3.5
    ${basePrefixAndSuffix()}
  `;

  const suffixCn = () => cntl`
    right-3.5
    ${basePrefixAndSuffix()}
  `;

  const setPrefixAndSuffixPadding = useCallback(() => {
    if (prefix?.length || suffix?.length) {
      if (prefix?.length && suffix?.length) {
        setSelectSize(`pl-8 pr-14 ${width}`);
      } else if (prefix?.length) {
        setSelectSize(`pl-8 ${width}`);
      } else if (suffix?.length) {
        setSelectSize(`pr-14 ${width}`);
      } else {
        return;
      }
    }
  }, [width, prefix?.length, suffix?.length]);

  useEffect(() => {
    setPrefixAndSuffixPadding();
  }, [prefix?.length, setPrefixAndSuffixPadding, suffix?.length]);

  const setIconPadding = ({ iconLeft, iconRight, width }) => {
    if (iconLeft || iconRight) {
      if (iconLeft && iconRight) {
        setSelectSize(`px-[2.625rem] ${width}`);
        setIconPosition({ leftIcon: "left-3.5", rightIcon: "right-3.5" });
      } else if (iconLeft) {
        setIconPosition({ leftIcon: "left-3.5" });
        setSelectSize(`pl-[2.625rem] pr-3.5 ${width}`);
      } else if (iconRight) {
        setIconPosition({ rightIcon: "right-3.5" });
        setSelectSize(`pr-[2.625rem] pl-3.5 ${width}`);
      } else {
        setSelectSize(`px-3.5 ${width}`);
      }
    }
  };

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setHeigth("h-9");
        setFontStyle("text-sm");
        setIconPadding({ iconLeft, iconRight, width });
        break;
      case SIZE.md:
        setHeigth("h-10");
        setFontStyle("text-base");
        setIconPadding({ iconLeft, iconRight, width });
        break;
      case SIZE.lg:
        setHeigth("h-11");
        setFontStyle("text-base");
        setIconPadding({ iconLeft, iconRight, width });
        break;
      case SIZE.xl:
        setHeigth("h-12");
        setFontStyle("text-base");
        setIconPadding({ iconLeft, iconRight, width });
        break;
      case SIZE["2xl"]:
        setHeigth("h-[3.75rem]");
        setFontStyle("text-base");
        setIconPadding({ iconLeft, iconRight, width });
        break;
      default:
        setHeigth("h-11");
        setFontStyle("text-base");
        setIconPadding({ iconLeft, iconRight, width });
        break;
    }
  }, [size, width, iconLeft, iconRight]);

  const onTextChange = (event) => {
    const val = event.target.value;
    onInputChange(val);
  };

  const onTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={wrapperCn()} ref={inputRef}>
      {!!label && <label className={labelCn()}>{label}</label>}
      <div className="relative">
        {iconLeft &&
          cloneElement(iconLeft, {
            className: iconLeftCn(),
          })}
        {!!prefix?.length && <span className={prefixCn()}>{prefix}</span>}
        <div className="flex">
          <input
            min={min}
            max={max}
            step={step}
            value={value}
            id={computedId}
            autoComplete="off"
            name={computedName}
            datatype={datatype}
            onKeyDown={onKeyDown}
            disabled={isDisabled}
            placeholder={placeholder}
            aria-invalid={errorMessage ? "true" : "false"}
            className={inputCn({ hasErrors: !!errorMessage })}
            type={isPassword ? (!isPasswordVisible ? "password" : type) : type}
            onChange={(e) => {
              if (onFormChange) {
                onFormChange(e);
              }
              if (onChange) {
                onChange(e);
              }
              if (onInputChange) {
                onTextChange(e);
              }
            }}
            onBlur={(e) => {
              if (onFormBlur) {
                onFormBlur(e);
              }
              if (onBlur) {
                onBlur(e);
              }
            }}
            {...rest}
          />
          {children &&
            cloneElement(children, {
              onClick,
              size,
            })}
        </div>
        {isPassword && (
          <div onClick={onTogglePasswordVisibility}>
            {!isPasswordVisible ? (
              <IconEyeOff className={iconPasswordCn()} />
            ) : (
              <IconEye className={iconPasswordCn()} />
            )}
          </div>
        )}
        {!!suffix?.length && <span className={suffixCn()}>{suffix}</span>}
        {iconRight &&
          cloneElement(iconRight, {
            className: iconRightCn({ hasErrors: !!errorMessage }),
          })}
      </div>
      {textHelper && !!!errorMessage && (
        <div className={textHelpCn()} data-cypress="form-help-msg">
          {textHelper}
        </div>
      )}
      {!!errorMessage && showErrorMessage && (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      )}
    </div>
  );
}

Input.propTypes = propTypes;
export default Input;
