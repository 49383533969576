import { Children, cloneElement, Fragment, useState } from "react";

function Slider({ children, onSlideChange, className, isPreselected = true }) {
  const [selectedCard, setSelectedCard] = useState(isPreselected ? 0 : null);

  const onCardClick = ({ index, values }) => {
    setSelectedCard(index);
    onSlideChange({ index, values });
  };

  return (
    <div
      className={`p-4 pb-2 bg-grey-25 rounded-lg shadow-inner max-w-[calc(100vw-64px)] 2xl:max-w-[calc(100vw-288px)] ${
        className ? className : undefined
      }`}
    >
      <div className="slider flex overflow-x-auto snap-x snap-mandatory pb-2">
        {children &&
          Children.map(children, (child, index) => {
            return (
              <Fragment key={`slider-child-${index}`}>
                <>
                  {cloneElement(child, {
                    onCardClick,
                    currentIndex: index,
                    isSelected: selectedCard === index,
                  })}
                </>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default Slider;
