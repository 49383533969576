import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { POSITIONS } from "../constants";

const propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  positionArrow: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
};

function PaginationButtonGroupBase({
  label,
  onClick,
  isActive,
  className,
  isDisabled,
  positionArrow,
}) {
  const wrapperCn = () => cntl`
    h-10
    group
    bg-white
    flex-row
    border-r
    inline-flex
    items-center
    min-w-[2.5rem]
    cursor-pointer
    justify-center
    hover:bg-grey-50
    border-r-grey-300
    ${className ? className : undefined}
    ${isActive ? cntl`bg-grey-50` : cntl`bg-white`}
    ${isDisabled ? cntl`pointer-events-none` : cntl`pointer-events-auto	`}
  `;

  const iconAndLabelCn = () => cntl`
    flex
    gap-x-2
    flex-row
    items-center
    ${label?.length ? cntl`px-4` : cntl`px-0`}
  `;

  const iconCn = () => cntl`
    h-5
    w-5
    text-grey-700
    group-hover:text-grey-800
  `;

  const labelCn = () => cntl`
    text-sm
    capitalize
    font-semobold
    text-grey-700
    hoover:text-grey-800
    group-hover:text-grey-800
  `;

  const Label = ({ label }) => {
    if (label?.length) {
      return <div className={labelCn()}>{label}</div>;
    }
  };

  const renderContent = useCallback(() => {
    if (positionArrow === POSITIONS.left) {
      return (
        <div className={iconAndLabelCn()}>
          <IconArrowLeft className={iconCn()} />
          <Label label={label} />
        </div>
      );
    } else if (positionArrow === POSITIONS.right) {
      return (
        <div className={iconAndLabelCn()}>
          <Label label={label} />
          <IconArrowRight className={iconCn()} />
        </div>
      );
    } else {
      return <Label label={label} />;
    }
  }, [label, positionArrow]);

  return (
    <div tabIndex={0} className={wrapperCn()} onClick={() => onClick(label)}>
      {renderContent()}
    </div>
  );
}

PaginationButtonGroupBase.propTypes = propTypes;
export default PaginationButtonGroupBase;
