import PropTypes from "prop-types";

const propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
};

const ContentTypePaymentSelectionComplex = ({ text, icon }) => {
  return (
    <div className="flex flex-col">
      <div className="font-medium text-sm h-5 w-5">{icon}</div>
      <div className="font-medium text-sm text-grey-500 capitalize">
        {text}
      </div>
    </div>
  );
};

ContentTypePaymentSelectionComplex.prototype = propTypes;
export default ContentTypePaymentSelectionComplex;
