import cntl from "cntl";
import Radio from "./Radio";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useEffect, useState } from "react";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  supportingText: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
};

function TileRadio({
  id,
  name,
  size,
  label,
  value,
  onClick,
  isChecked,
  isDisabled,
  supportingText,
}) {
  const [fontStyle, setFontStyle] = useState("");

  const disabledUnchecked = () => cntl`
    bg-grey-50
    border-grey-200
    pointer-events-none
  `;

  const disabledChecked = () => cntl`
    bg-grey-50
    border-grey-200
    pointer-events-none
  `;

  const checked = () => cntl`
    bg-primary-25
    border-primary-300
  `;

  const unchecked = () => cntl`
    bg-white
    border-grey-200
  `;

  const wrapperCn = () => cntl`
    pl-4
    flex
    flex-row
    border
    rounded-lg 
    hover:border-primary-300
    focus-within:outline
    focus-within:outline-4
    focus-within:outline-primary-100
    ${fontStyle}
    ${
      isChecked
        ? !isDisabled
          ? checked()
          : disabledChecked()
        : isDisabled
        ? disabledUnchecked()
        : unchecked()
    }
  `;

  const inputCn = () => cntl`
    mt-4
  `;

  const labelWrapperCn = () => cntl`
    flex
    flex-col
    w-full
    p-4
  `;

  const labelCn = () => cntl`
    text-grey-700
    first-letter:uppercase
    ${!!supportingText?.length ? cntl`font-medium` : cntl`font-regular`}
  `;

  const supportingTextCn = () => cntl`
    text-grey-500
    first-letter:uppercase
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-sm");
        break;
      case SIZE.md:
        setFontStyle("text-base");
        break;
      default:
        setFontStyle("text-sm");
        break;
    }
  }, [size]);

  return (
    <div className={wrapperCn()} onClick={onClick}>
      <Radio
        id={id}
        name={name}
        size={size}
        value={value}
        className={inputCn()}
        isChecked={isChecked}
        isDisabled={isDisabled}
      />
      <label htmlFor={id} className={labelWrapperCn()}>
        {!!label?.length && <span className={labelCn()}>{label}</span>}
        {!!supportingText?.length && (
          <span className={supportingTextCn()}>{supportingText}</span>
        )}
      </label>
    </div>
  );
}

TileRadio.propTypes = propTypes;
export default TileRadio;
