import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useState, useMemo } from "react";

const proptypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  harMarginTop: PropTypes.bool,
  hasMarginBottom: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl]),
};

function Heading({ title, size, harMarginTop, hasMarginBottom, className }) {
  const [textStyle, setTextStyle] = useState("");

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setTextStyle(`text-xl ${harMarginTop ? "mt-5 md:mt-8" : "mt-0"} ${hasMarginBottom ? "mb-2 md:mb-3" : "mb-0"} `);
        break;
      case SIZE.md:
        setTextStyle(
          `text-2xl ${harMarginTop ? "mt-8 md:mt-8" : "mt-0"} ${hasMarginBottom ? "mb-3 md:mb-4" : "mb-0"} `
        );
        break;
      case SIZE.lg:
        setTextStyle(
          `text-3xl ${harMarginTop ? "mt-8 md:mt-10" : "mt-0"} ${hasMarginBottom ? "mb-4 md:mb-5" : "mb-0"} `
        );
        break;
      case SIZE.xl:
        setTextStyle(
          `text-4xl ${harMarginTop ? "mt-10 md:mt-12" : "mt-0"} ${hasMarginBottom ? "mb-5 md:mb-6" : "mb-0"} `
        );
        break;
      default:
        setTextStyle(`text-xl ${harMarginTop ? "mt-5 md:mt-8" : "mt-0"} ${hasMarginBottom ? "mb-2 md:mb-3" : "mb-0"} `);
        break;
    }
  }, [hasMarginBottom, harMarginTop, size]);

  const wrapperCn = () => cntl`
    text-grey-900
    font-semibold
    ${textStyle}
    ${className ? className : undefined}
  `;

  return <div className={wrapperCn()}>{title}</div>;
}

Heading.prototype = proptypes;
export default Heading;
