import { getBadgeColors } from "@dbox/core/utils/getBadgeColors";
import T from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const propTypes = {
  text: T.string.isRequired,
  value: T.oneOfType([T.string, T.number]),
};

function BoxStats({ text, value }) {
  const { t } = useTranslation();

  const res = useMemo(() => getBadgeColors(text), [text]);

  return (
    <>
      {res && (
        <div className="flex flex-col w-32 rounded-lg shadow overflow-hidden">
          <div className="text-xl font-medium text-grey-900 py-3 px-2">
            {value}
          </div>
          <div
            className={`flex items-center text-xs font-medium uppercase px-2 border-t border-t-grey-200 h-8
            ${res?.backgroundColor ? res?.backgroundColor : `bg-grey-25`}
            ${res?.textColor ? res?.textColor : `text-grey-900`}
          `}
          >
            {t(`badge.label.${text}`)}
          </div>
        </div>
      )}
    </>
  );
}

BoxStats.propTypes = propTypes;
export default BoxStats;
