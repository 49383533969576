import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const propTypes = {
  user: PropTypes.object,
  profileIcon: PropTypes.node,
};

function CustomerInfoViewHeader({ user, profileIcon }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {profileIcon}
        <div className="flex flex-col">
          <div className="font-medium text-base text-grey-900">
            {user.type === "individual"
              ? user.firstname + " " + user.lastname
              : user.name}
          </div>
          <div className="font-medium tesxt-sm text-teal-500 first-letter:capitalize">
            {user.type}
          </div>
          <div className="text-sm text-grey-500 font-normal">{user.cic}</div>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-sm text-grey-500 font-normal uppercase">
          {t("customer_info_view_header.customer_info")}
        </div>
        <div className="flex flex-row mt-2">
          <div className="text-sm text-grey-500 font-normal first-letter:capitalize">
            {t("customer_info_view_header.email") + t("data.general.colon")}
          </div>
          <div className="ml-4 font-medium text-sm text-grey-900">
            {user.email}
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="text-sm text-grey-500 font-normal first-letter:capitalize">
            {t("customer_info_view_header.pec") + t("data.general.colon")}
          </div>
          <div className="ml-4 font-medium text-sm text-grey-900">
            {user.pec}
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="text-sm text-grey-500 font-normal first-letter:capitalize">
            {t("customer_info_view_header.telephone") + t("data.general.colon")}
          </div>
          <div className="ml-4 font-medium text-sm text-grey-900">
            {user.telephone}
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="text-sm text-grey-500 font-normal first-letter:capitalize">
            {t("customer_info_view_header.fiscalcode") +
              t("data.general.colon")}
          </div>
          <div className="ml-4 font-medium text-sm text-grey-900">
            {user.fiscalcode}
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerInfoViewHeader.propTypes = propTypes;
export default CustomerInfoViewHeader;
