import cntl from "cntl";
import PropTypes from "prop-types";
import { BadgeText, FeaturedIcon } from "../atoms";
import { COLOR, HEADER_PRICING, POSITIONS, SIZE } from "../constants";

const propTypes = {
  alignment: PropTypes.string,
  badgeLabel: PropTypes.string,
  hasFeaturedIcon: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.md, SIZE.lg]),
  variant: PropTypes.oneOf([HEADER_PRICING.with_featured_icon]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.oneOf([COLOR.primary_light, COLOR.grey, COLOR.light]),
};

function HeaderPricing({
  icon,
  theme,
  title,
  subtitle,
  pretitle,
  alignment,
  className,
  badgeLabel,
  description,
  hasFeaturedIcon,
}) {
  const style = {
    [COLOR.primary_light]: {
      text: "text-primary-700",
      title: "text-primary-900",
    },
    [COLOR.grey]: {
      text: "text-grey-600",
      title: "text-grey-900",
    },
    [COLOR.light]: {
      text: "text-grey-600",
      title: "text-grey-900",
    },
    default: {
      text: "text-grey-600",
      title: "text-grey-900",
    },
  };

  const container = () => cntl`
    flex
    flex-col
    ${
      alignment === POSITIONS.left
        ? "self-start items-start"
        : alignment === POSITIONS.right
        ? "self-end items-end"
        : "self-center items-center"
    }
    ${className ? className : undefined}
  `;

  const pretitleCn = () => cntl`
    mt-5
    mb-2
    text-xl
    font-semibold	
    text-primary-700
  `;

  const titleCn = () => cntl`
    mb-1
    text-4xl
    sm:text-5xl
    font-semibold	
    ${style[theme]?.title || style["default"]?.title}
  `;

  const subtitleCn = () => cntl`
    text-xl
    font-semibold
    ${style[theme]?.title || style["default"]?.title}
  `;

  const subtitleAndBadgeCn = () => cntl`
    mt-3
    flex
    gap-x-2
    items-center
  `;

  const descirptionCn = () => cntl`
    mt-1
    text-base
    font-normal
    ${style[theme]?.text || style["default"]?.text}
  `;

  return (
    <div className={container()}>
      {hasFeaturedIcon && icon ? <FeaturedIcon size={SIZE.md} children={icon} /> : <div>{icon}</div>}
      {pretitle?.length && <div className={pretitleCn()}>{pretitle}</div>}
      {title?.length && <div className={titleCn()}>{title}</div>}
      {subtitle?.length > 0 && (
        <div className={subtitleAndBadgeCn()}>
          <div className={subtitleCn()}>{subtitle}</div>
          {badgeLabel?.length > 0 && <BadgeText label={badgeLabel} />}
        </div>
      )}
      {description?.length && <div className={descirptionCn()}>{description}</div>}
    </div>
  );
}

HeaderPricing.propTypes = propTypes;
export default HeaderPricing;
