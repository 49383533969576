import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Item({ value, onClick, children, className }) {
  const liCn = () => cntl`
    flex
    items-center
    cursor-pointer
    ${className ? className : undefined}
  `;

  return (
    <li className={liCn()} onClick={() => onClick(value)}>
      {children}
    </li>
  );
}

Item.propTypes = propTypes;
export default Item;
