import T from "prop-types";
import { IconWithTitle, LabelsWithValues } from "../molecules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";

const propTypes = {
  icon: T.node,
  data: T.array,
  title: T.string,
  className: T.string,
  image: T.oneOfType([T.object, T.array]),
};

const DetailsBox = ({ className, icon, title, image, data }) => {
  return (
    <div className={`${className ? className : undefined}`}>
      <div className="flex flex-col gap-y-4">
        <IconWithTitle icon={icon} text={title} />
        <div className="flex flex-row gap-x-4">
          {!!image?.buffer && (
            <LazyLoadImage
              alt="profile"
              className="w-9 h-9 rounded-full"
              src={`data:image/png;base64,${arrayBufferToBase64(image?.buffer)}`}
            />
          )}
          {!!data && (
            <div className="flex flex-row items-end gap-x-10 flex-wrap">
              <LabelsWithValues data={data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DetailsBox.propTypes = propTypes;
export default DetailsBox;
