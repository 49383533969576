import PropTypes from "prop-types";

const propTypes = {
  active: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isVertical: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  navButtonType: PropTypes.string,
};

function NavButton({
  active,
  onClick,
  isVertical,
  children,
  isDisabled,
  navButtonType,
}) {
  const styleCn = (navButtonType, isVertical, active, isDisabled) => {
    switch (navButtonType) {
      case "noBackground":
        return `
          flex
          flex-row
          h-9
          mr-16
          last:mr-0
          font-medium
          items-center
          hover:cursor-pointer
          ${
            active
              ? `text-primary-700 border-b border-b-primary-40`
              : `text-grey-500`
          }
        `;
      default:
        return `
          flex
          h-14
          pl-2
          pr-6
          text-base
          box-border
          font-medium
          items-center
          hover:cursor-pointer
          ${isVertical && "relative"}
          ${
            active && !isDisabled
              ? `text-primary-700 bg-primary-50
                ${
                  !isVertical
                    ? `border-b-2 border-b-solid border-b-primary-40`
                    : undefined
                }`
              : !isDisabled
              ? `text-grey-500 hover:text-primary-700`
              : "hover:cursor-default text-grey-500"
          }
      `;
    }
  };

  return (
    <div
      onClick={onClick}
      className={styleCn(navButtonType, isVertical, active, isDisabled)}
    >
      {children}
    </div>
  );
}

NavButton.propTypes = propTypes;
export default NavButton;
