import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import { useMemo, useState } from "react";
import { COLOR, POSITIONS, SIZE } from "../constants";

const propTypes = {
  content: PropTypes.object,
  theme: PropTypes.oneOf([
    COLOR.dark,
    COLOR.grey,
    COLOR.light,
    COLOR.primary,
    COLOR.primary_light,
  ]),
};

function SectionCTASimpleCentered({ theme, content }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    px-4
    py-16
    md:px-8
    mx-auto
    md:py-24
    container
  `;

  const contentAndButtonsCn = () => cntl`
    flex
    gap-y-8
    flex-col
    md:gap-y-10
    sm:items-center
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-grey-800");
        break;
      case COLOR.grey:
        setBgColor("bg-grey-50");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-800");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-50");
        break;
      case COLOR.light:
        setBgColor("bg-white");
        break;
      default:
        setBgColor("bg-white");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentAndButtonsCn()}>
          <Content
            {...content}
            theme={theme}
            size={SIZE.sm}
            position={POSITIONS.center}
          />
        </div>
      </div>
    </div>
  );
}

SectionCTASimpleCentered.prototype = propTypes;
export default SectionCTASimpleCentered;
