import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState, cloneElement } from "react";
import { LINK_VARIANTS, POSITIONS, SIZE } from "../constants";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";

const propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onAction: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
  variant: PropTypes.oneOf([LINK_VARIANTS.primary, LINK_VARIANTS.error, LINK_VARIANTS.grey]),
};

function TextLink({ size, icon, label, variant, onAction, className, isDisabled, iconPosition }) {
  const [color, setColor] = useState("");
  const [fontSize, setFontSize] = useState("");

  const wrapperCn = () => cntl`
    flex
    w-fit
    group
    gap-x-1
    flex-row
    items-center
    justify-center
    cursor-pointer
    h-5
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    align-middle
    font-semibold
    whitespace-pre-wrap
    first-letter:uppercase
    ${color}
    ${fontSize}
  `;

  const iconCn = () => cntl`
    w-5
    h-5
    ${color}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontSize("text-sm");
        break;
      case SIZE.md:
        setFontSize("text-base");
        break;
      case SIZE.lg:
        setFontSize("text-lg");
        break;
      default:
        setFontSize("text-sm");
        break;
    }
  }, [size]);

  useMemo(() => {
    switch (variant) {
      case LINK_VARIANTS.primary:
        isDisabled
          ? setColor("text-grey-300 pointer-events-none")
          : setColor("text-primary-700 group-hover:text-primary-800");
        break;
      case LINK_VARIANTS.error:
        isDisabled
          ? setColor("text-error-300 pointer-events-none")
          : setColor("text-error-700 group-hover:text-error-800");
        break;
      case LINK_VARIANTS.grey:
        isDisabled
          ? setColor("text-grey-300 pointer-events-none")
          : setColor("text-grey-600 group-hover:text-grey-700");
        break;
      default:
        isDisabled
          ? setColor("text-grey-300 pointer-events-none")
          : setColor("text-primary-700 group-hover:text-primary-800");
        break;
    }
  }, [variant, isDisabled]);

  const Label = ({ label }) => {
    if (label?.length) {
      return <div className={labelCn()} dangerouslySetInnerHTML={{ __html: label }} />;
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
    onAction();
  };

  return (
    <div className={wrapperCn()} onClick={onClick}>
      {iconPosition === POSITIONS.left &&
        (icon ? cloneElement(icon, { className: iconCn() }) : <IconArrowLeft className={iconCn()} />)}
      <Label label={label} />
      {iconPosition === POSITIONS.right &&
        (icon ? cloneElement(icon, { className: iconCn() }) : <IconArrowRight className={iconCn()} />)}
    </div>
  );
}

TextLink.prototype = propTypes;
export default TextLink;
