import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonGrey } from "../atoms";
import { useTranslation } from "react-i18next";
import { IconUpload } from "@tabler/icons-react";
import React, { useMemo, useState, useRef } from "react";
import { FORMAT_ACCEPTED, BUTTON_VARIANTS, POSITIONS } from "../constants";

const propTypes = {
  handleFile: PropTypes.func,
  formatAccepted: PropTypes.oneOf([FORMAT_ACCEPTED.json, FORMAT_ACCEPTED.image, FORMAT_ACCEPTED.document]),
};

function FileUploadButton({ handleFile, formatAccepted }) {
  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);
  const [formats, setFormats] = useState("");

  const inputCn = () => cntl`
    sr-only	
  `;

  useMemo(() => {
    switch (formatAccepted) {
      case FORMAT_ACCEPTED.json:
        setFormats(".json");
        break;
      case FORMAT_ACCEPTED.image:
        setFormats(".jpg, .png, .jpeg, .svg");
        break;
      case FORMAT_ACCEPTED.document:
        setFormats(".pdf");
        break;
      default:
        setFormats(".jpg, .png, .jpeg, .svg");
        break;
    }
  }, [formatAccepted]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };

  return (
    <>
      <ButtonGrey
        onClick={handleClick}
        icon={<IconUpload />}
        iconPosition={POSITIONS.left}
        label={t("button.label.load")}
        variant={BUTTON_VARIANTS.border}
      />
      <input ref={hiddenFileInput} type="file" accept={formats} className={inputCn()} onChange={handleChange} />
    </>
  );
}

FileUploadButton.propTypes = propTypes;
export default FileUploadButton;
