import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import { useMemo, useState } from "react";
import { MenuSlider } from "../organisms/";
import { COLOR, POSITIONS } from "../constants";

const propTypes = {
  images: PropTypes.array,
  content: PropTypes.object,
  sectionItem: PropTypes.array,
  position: PropTypes.oneOf([POSITIONS.center, POSITIONS.left]),
  theme: PropTypes.oneOf([COLOR.dark, COLOR.grey, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function FeatureTabMockup01({ images, theme, content, position, sectionItem }) {
  const [bgColor, setBgColor] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    px-4
    py-16
    md:px-20
    mx-auto
    md:py-24
    container
  `;

  const contentAndGridCn = () => cntl`
    flex
    flex-col
    gap-y-12
    md:gap-y-16
    justify-center
  `;

  const imageCn = () => cntl`
    flex
    w-full
    mx-auto
    items-center
    justify-center
    max-w-3xl
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-grey-800");
        break;
      case COLOR.grey:
        setBgColor("bg-grey-50");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-800");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-50");
        break;
      case COLOR.light:
        setBgColor("bg-white");
        break;
      default:
        setBgColor("bg-white");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentAndGridCn()}>
          <Content {...content} theme={theme} position={position} />
          {images?.map((image, index) => {
            if (index === selectedItem) {
              return (
                <div key={`image-${index}`} className={imageCn()}>
                  {image}
                </div>
              );
            } else {
              return false;
            }
          })}
          <MenuSlider
            steps={sectionItem}
            current={selectedItem}
            className="text-center"
            onClick={(index) => setSelectedItem(index)}
          />
        </div>
      </div>
    </div>
  );
}

FeatureTabMockup01.prototype = propTypes;
export default FeatureTabMockup01;
