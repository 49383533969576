import cntl from "cntl";
import Modal from "./Modal";
import PropTypes from "prop-types";
import { ModalAction, NavItem } from "../molecules";
import { Avatar, Input, ModalHeader } from "../atoms";
import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect } from "react";
import { BREAKPOINT, COLOR, MODAL_ACTION_VARIANTS, MODAL_HEADER_VARIANTS, SIZE } from "../constants";
import { useWindowSize } from "@dbox/core/hooks/useWindowSize";
import { IconAlignLeft, IconLogout, IconSearch, IconX } from "@tabler/icons-react";

const propTypes = {
  width: PropTypes.string,
  logoMark: PropTypes.object,
  hasSearchBar: PropTypes.bool,
  handleClickItem: PropTypes.func,
  handleLogoClick: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleLogOutClick: PropTypes.func,
  childrenTop: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  childrenBottom: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  items: PropTypes.exact({
    body: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.object,
        path: PropTypes.string,
        label: PropTypes.string,
        isHidden: PropTypes.bool,
        children: PropTypes.arrayOf(
          PropTypes.exact({
            path: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
    footer: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.object,
        label: PropTypes.string,
        path: PropTypes.string,
        isHidden: PropTypes.bool,
      })
    ),
  }),
};

function SidebarSimple({
  width,
  items,
  logoMark,
  childrenTop,
  hasSearchBar,
  childrenBottom,
  handleClickItem,
  handleLogoClick,
  handleInputChange,
  handleLogOutClick,
  ...avatarProps
}) {
  const screenSize = useWindowSize();
  const body = document.querySelector("body");
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = screenSize?.width < BREAKPOINT.lg;

  useEffect(() => {
    if (body && isVisible && isMobile) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
      body.classList.add("overflow-auto");
    }
  }, [body, isVisible, isMobile]);

  return (
    <>
      {isMobile && (
        <Navigation
          logoMark={logoMark}
          isVisible={isVisible}
          handleLogoClick={handleLogoClick}
          handleNavMenuClick={() => setIsVisible(true)}
        />
      )}
      <Sidebar
        {...avatarProps}
        width={width}
        items={items}
        logoMark={logoMark}
        isMobile={isMobile}
        isVisible={isVisible}
        hasSearchBar={hasSearchBar}
        handleClickItem={handleClickItem}
        handleLogoClick={handleLogoClick}
        handleInputChange={handleInputChange}
        handleLogOutClick={handleLogOutClick}
        handelCloseSidebar={() => setIsVisible(false)}
        childrenTop={childrenTop}
        childrenBottom={childrenBottom}
      ></Sidebar>
    </>
  );
}

const Sidebar = ({
  items,
  width,
  logoMark,
  isMobile,
  isVisible,
  hasSearchBar,
  childrenTop,
  childrenBottom,
  handleClickItem,
  handleLogoClick,
  handleInputChange,
  handleLogOutClick,
  handelCloseSidebar,
  ...avatarProps
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const overlayCn = ({ isVisible, isMobile }) => cntl`
    ${
      isVisible &&
      isMobile &&
      cntl`
      z-10
      fixed
      top-0
      left-0
      right-0
      bottom-0
      opacity-100
      duration-500
      bg-grey-700/60
      backdrop-blur-md
    `
    }
  `;

  const sidebarCn = () => cntl`
    z-20
    fixed
    top-0
    left-0
    no-blur
    bg-white
    border-r
    h-screen
    ease-in-out
    duration-300
    border-r-grey-200
    ${width}
    ${!isMobile ? cntl`translate-x-0` : isVisible ? cntl`translate-x-0` : cntl`-translate-x-full`}
    `;

  const headerCn = () => cntl`
    m-6
    mt-8
    flex
    gap-y-6
    flex-col
  `;

  const bodyCn = () => cntl`
    sidebar
    max-h-[19rem]
    overflow-y-auto
    ${childrenBottom ? cntl`h-[calc(100vh_-_500px)]` : cntl`h-[calc(100vh_-_290px)]`}
  `;

  const logoCn = () => cntl`
    h-8
    w-fit
    cursor-pointer
  `;

  const footerCn = () => cntl`
    px-4
    fixed
    bottom-0
    border-r
    border-r-grey-200
    ${width}
  `;

  const innerFooterCn = () => cntl`
    flex
    gap-y-5
    flex-col
    bg-white
  `;

  const accountCn = () => cntl`
    pt-6
    pb-8
    px-2
    flex
    border-t
    flex-row
    justify-between
    border-t-grey-200
  `;

  const logOutIconCn = () => cntl`
    w-5
    h-5
    shrink-0
    text-grey-500
    cursor-pointer
  `;

  const navItemCn = () => cntl`
    mx-4
    my-0.5
  `;

  const wrapperCloseIcon = () => cntl`
    flex
    flex-row
    justify-end
  `;

  const closeIconCn = () => cntl`
    w-10
    h-10
    flex
    mt-3
    flex-row
    items-center
    justify-center
  `;

  const closeIcon = () => cntl`
    w-6
    h-6
    z-20
  `;

  return (
    <>
      <Modal width="min-w-[25rem]" isOpen={isModalOpen}>
        <ModalHeader
          text={t("modal.header.text.logout")}
          title={t("modal.header.title.logout")}
          variant={MODAL_HEADER_VARIANTS.inline}
          onCloseModal={() => setIsModalOpen(false)}
        />
        <ModalAction
          variant={MODAL_ACTION_VARIANTS.row}
          labelCancelBtn={t("button.label.cancel")}
          labelSubmitBtn={t("button.label.confirm")}
          onClickSubmitBtn={() => handleLogOutClick()}
          onClickCancelBtn={() => setIsModalOpen(false)}
        />
      </Modal>
      <div className={overlayCn({ isVisible, isMobile })}>
        <aside className={sidebarCn()}>
          <div className={headerCn()}>
            <div className={logoCn()} onClick={handleLogoClick}>
              {logoMark}
            </div>
            {hasSearchBar && (
              <Input
                onChange={handleInputChange}
                iconLeft={<IconSearch />}
                placeholder={t("input.placeholder.search")}
              />
            )}
          </div>
          <div className="mx-4 mb-6">{childrenTop}</div>
          <div className={bodyCn()}>
            <ul className="list-none">
              {items?.body?.map(({ icon, label, path, isHidden, children }, index) => {
                if (!isHidden) {
                  return (
                    <Fragment key={`nav-item-body-${index}`}>
                      <NavItem
                        icon={icon}
                        path={path}
                        label={label}
                        subItem={children}
                        theme={COLOR.light}
                        className={navItemCn()}
                        key={`nav-item-body-${index}`}
                      />
                    </Fragment>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
          <div className={footerCn()}>
            <div className="bg-gradient-to-t from-white via-white to-transparent h-8" />
            <div className={innerFooterCn()}>
              <ul className="list-none">
                {items?.footer?.map(({ icon, label, path, isHidden }, index) => {
                  if (!isHidden) {
                    return (
                      <Fragment key={`nav-item-body-${index}`}>
                        <NavItem
                          icon={icon}
                          path={path}
                          label={label}
                          theme={COLOR.light}
                          key={`nav-item-body-${index}`}
                        />
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
              {childrenBottom}
              <div className={accountCn()}>
                <Avatar size={SIZE.md} {...avatarProps} />
                <IconLogout className={logOutIconCn()} onClick={() => setIsModalOpen(!isModalOpen)} />
              </div>
            </div>
          </div>
        </aside>
        {isVisible && isMobile && (
          <div className={wrapperCloseIcon()}>
            <div className={closeIconCn()} onClick={handelCloseSidebar}>
              <IconX className={closeIcon()} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const Navigation = ({ logoMark, isVisible, handleLogoClick, handleNavMenuClick }) => {
  const wrapperCn = () => cntl`
    fixed
    top-0
    left-0
    right-0
    duration-300
    ${!isVisible ? cntl`opacity-100` : cntl`opacity-0`}
  `;

  const contentNavigation = () => cntl`
    h-16
    flex
    bg-white
    flex-row
    border-b
    items-center
    justify-between
    border-b-grey-200
  `;

  const logoCn = () => cntl`
    h-8
    ml-4
    w-fit
    cursor-pointer
  `;

  const btnNavMenu = () => cntl`
    h-10
    w-10
    flex
    mr-2
    items-center
    justify-center
  `;

  const iconCn = () => cntl`
    h-6
    w-6
    text-grey-500
  `;

  return (
    <div className={wrapperCn()}>
      <div className={contentNavigation()}>
        <div className={logoCn()} onClick={handleLogoClick}>
          {logoMark}
        </div>
        <div className={btnNavMenu()} onClick={handleNavMenuClick}>
          <IconAlignLeft className={iconCn()} />
        </div>
      </div>
    </div>
  );
};

SidebarSimple.propTypes = propTypes;
export default SidebarSimple;
