import T from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";

const propTypes = {
  fetchImage: T.func,
  shipperImage: T.object,
  shipping: T.object.isRequired,
};

function ContentShipping({ id, shipperImage, fetchImage, shipping }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      fetchImage(id);
    }
  }, [id, fetchImage]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <LazyLoadImage
          alt="shipper-image"
          className="rounded w-14 h-10"
          src={`data:image/png;base64,${arrayBufferToBase64(shipperImage?.buffer ?? null)}`}
        />
        <div className="text-base text-grey-900 font-medium ml-4">{shipping?.name || "n/a"}</div>
      </div>
      <div className="flex flex-row items-center justify-between mt-4">
        <div className="text-base text-grey-500 first-letter:uppercase">{t("selection_complex.cost")}</div>
        <div className="text-base text-grey-900 font-medium">
          {t("data.general.euro")}
          <>&nbsp;</>
          {formatCurrency(shipping?.data?.cost) || "n/a"}
        </div>
      </div>
    </div>
  );
}

ContentShipping.propTypes = propTypes;
export default ContentShipping;
