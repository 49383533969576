import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement, Children, Fragment } from "react";

const propTypes = {
  isLeading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  childGap: PropTypes.string,
  textAlign: PropTypes.string,
  supportingText: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

function CellBodyDatagrid({
  text,
  children,
  childGap,
  supportingText,
  isLeading = false,
  textAlign = "left",
  isDisabled = false,
}) {
  const wrapperCell = () => cntl`
    px-6
    flex
    items-center
    ${isDisabled && cntl`bg-grey-25`}
    ${childGap ? childGap : cntl`gap-x-3`}
    ${textAlign === "left" ? cntl`justify-start` : cntl`justify-end`}
  `;

  const wrpperText = () => cntl`
    flex
    flex-col
  `;

  const textCn = () => cntl`
    text-sm
    ${
      isLeading
        ? cntl`font-medium text-grey-900`
        : supportingText?.length
        ? cntl`font-regular text-grey-900`
        : cntl`font-regular text-grey-600`
    }
  `;

  const supportingTextCn = () => cntl`
    text-sm
    text-grey-600
  `;

  return (
    <div className={wrapperCell()}>
      {children &&
        Children.map(children, (child, index) => {
          if (child) {
            return (
              <Fragment key={index}>
                {cloneElement(child, { isDisabled })}
              </Fragment>
            );
          }
        })}
      {(!!text || !!supportingText?.length) && (
        <div className={wrpperText()}>
          {!!text && <span className={textCn()}>{text}</span>}
          {!!supportingText?.length && (
            <span className={supportingTextCn()}>{supportingText}</span>
          )}
        </div>
      )}
    </div>
  );
}

CellBodyDatagrid.propTypes = propTypes;
export default CellBodyDatagrid;
