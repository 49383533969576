import { formatCurrency } from "@dbox/core/utils/formatCurrency";
import T from "prop-types";
import { useTranslation } from "react-i18next";

const propTypes = {
  totalPrice: T.object.isRequired,
};

function QuoteTotalPriceView({ totalPrice }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="bg-primary-25 border border-primary-700 rounded-lg p-4">
        {totalPrice && (
          <>
            <div className="flex flex-row justify-end">
              <div className="text-sm text-grey-500 uppercase">
                {totalPrice?.key}
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div className="text-2xl text-grey-900 font-medium">
                {t("data.general.euro")}&nbsp;{formatCurrency(totalPrice?.value)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

QuoteTotalPriceView.propTypes = propTypes;
export default QuoteTotalPriceView;
