import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonGrey } from "../atoms";
import { useTranslation } from "react-i18next";
import { PaginationCounter } from "../molecules";
import { PAGINATION_VARIANTS, POSITIONS, SIZE } from "../constants";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  isMobile: PropTypes.bool,
  nextPage: PropTypes.func,
  goToPage: PropTypes.func,
  variant: PropTypes.string,
  previousPage: PropTypes.func,
  buttonVariant: PropTypes.string,
  isDetailsVisible: PropTypes.bool,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
  paginationProps: PropTypes.exact({
    canNextPage: PropTypes.bool,
    canPreviousPage: PropTypes.bool,
    paginationRange: PropTypes.array,
  }),
};

function PaginationContentNumbers({
  variant,
  isMobile,
  nextPage,
  goToPage,
  pagesInfo,
  previousPage,
  buttonVariant,
  paginationProps,
  isDetailsVisible,
}) {
  const { t } = useTranslation();

  const paginationContentNumbersCn = () => cntl`
    flex
    flex-row
    items-center
    justify-between
  `;

  return (
    <div className={paginationContentNumbersCn()}>
      <ButtonGrey
        size={SIZE.sm}
        variant={buttonVariant}
        iconPosition={POSITIONS.left}
        onClick={() => previousPage()}
        disabled={!paginationProps?.canPreviousPage}
        label={!isMobile ? t("button.label.previous") : ""}
        icon={
          variant === PAGINATION_VARIANTS.card_center && !isMobile ? null : (
            <IconArrowLeft />
          )
        }
      />
      <PaginationCounter
        pagesInfo={pagesInfo}
        isDetailsVisible={isDetailsVisible}
        range={paginationProps?.paginationRange}
        onPageClick={(value) => goToPage(value - 1)}
      />
      <ButtonGrey
        size={SIZE.sm}
        variant={buttonVariant}
        onClick={() => nextPage()}
        iconPosition={POSITIONS.right}
        disabled={!paginationProps?.canNextPage}
        label={!isMobile ? t("button.label.next") : ""}
        icon={
          variant === PAGINATION_VARIANTS.card_center && !isMobile ? null : (
            <IconArrowRight />
          )
        }
      />
    </div>
  );
}

PaginationContentNumbers.propTypes = propTypes;
export default PaginationContentNumbers;
