import cntl from "cntl";
import PropTypes from "prop-types";
import { TextLink } from "../atoms";
import { LINK_VARIANTS, POSITIONS } from "../constants";

const propTypes = {
  steps: PropTypes.array,
  onClick: PropTypes.func,
  current: PropTypes.number,
  className: PropTypes.string,
};

function MenuSlider({ steps, className, current, onClick }) {
  const wrapperCn = () => cntl`
    flex
    gap-y-10
    flex-col
    sm:flex-row
    sm:justify-center
    ${className ? className : undefined}
  `;

  const itemWrapperCn = (index) => cntl`
    flex
    w-full
    flex-col
    border-t-4
    cursor-pointer
    gap-y-4
    items-center
    ${current === index ? "border-t-primary-500" : "border-t-grey-100"}
  `;

  const textWrapperCn = (index) => cntl`
  ${!steps[index].icon && "mt-6"}
    px-4
    gap-y-2
  `;

  const titleCn = () => cntl`
    text-xl
    font-medium
    text-grey-900
  `;

  const textCn = () => cntl`
    text-base
    text-grey-500
  `;

  return (
    <div className={wrapperCn()}>
      {steps?.map(({ title, text, cta, icon }, index) => {
        return (
          <div key={`step-${index}`} onClick={() => onClick(index)} className={itemWrapperCn(index)}>
            {icon && <div className="mt-6 w-16 h-16">{icon}</div>}
            <div className={textWrapperCn(index)}>
              <div className={titleCn()}>{title}</div>
              <div className={textCn()}>{text}</div>
            </div>
            {cta && (
              <TextLink
                label={cta.text}
                iconPosition={POSITIONS.right}
                variant={LINK_VARIANTS.primary}
                className="mt-1"
                onAction={cta?.onClick}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

MenuSlider.propTypes = propTypes;
export default MenuSlider;
