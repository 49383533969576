import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  supportingText: PropTypes.string,
  formRegistration: PropTypes.object,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function Checkbox({
  id,
  name,
  size,
  label,
  value,
  errors,
  onBlur,
  onChange,
  isChecked,
  className,
  isDisabled,
  supportingText,
  showLabel = true,
  formRegistration = null,
}) {
  const [rounded, setRounded] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [checkboxSize, setCheckboxSize] = useState("");

  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedName = name || formName;
  const computedId = id || computedName;
  const errorMessage = get(errors, `[${formName}].message`, "");


  const checkboxWrapperCn = () => cntl`
    flex
    flex-row
    items-start
    gap-x-2 
    ${className ? className : undefined}
  `;

  const inputCn = ({ hasErrors }) => cntl`
    mt-0.5
    form-checkbox
    border
    focus:ring
    focus:ring-offset-0
    disabled:bg-grey-100
    disabled:border-grey-200
    disabled:hover:bg-grey-100
    enable:bg-white
    enabled:checked:text-primary-600
    enabled:checked:hover:bg-primary-600
    enabled:indeterminate:text-primary-600
    enabled:indeterminate:hover:bg-primary-50
    enabled:hover:border-primary-600
    enabled:hover:bg-primary-50
    ${rounded}
    ${checkboxSize}
    ${
      hasErrors
        ? cntl`focus:ring-error-100 focus:border-error-300 border-error-300`
        : cntl`border-grey-300 focus:ring-primary-100 focus:border-primary-300`
    }
  `;

  const labelCn = () => cntl`
    ${fontStyle}
  `;

  const onlyLabelCn = () => cntl`
    text-grey-700
    first-letter:capitalize
    ${!!supportingText?.length ? cntl`font-medium` : cntl`font-regular`}
  `;

  const onlySupportingTextCn = () => cntl`
    text-grey-500
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.xs:
        setRounded("rounded");
        setFontStyle("text-xs");
        setCheckboxSize("w-3.5 h-3.5");
        break;
      case SIZE.sm:
        setRounded("rounded");
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        break;
      case SIZE.md:
        setRounded("rounded-md");
        setFontStyle("text-base");
        setCheckboxSize("w-[1.125rem] h-[1.125rem]");
        break;
      case SIZE.lg:
        setRounded("rounded-md");
        setFontStyle("text-base");
        setCheckboxSize("w-5 h-5");
        break;
      default:
        setRounded("rounded");
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        break;
    }
  }, [size]);

  return (
    <div className={checkboxWrapperCn()}>
      <input
        id={computedId}
        value={value}
        type="checkbox"
        checked={isChecked}
        name={computedName}
        disabled={isDisabled}
        aria-hidden="true"
        aria-invalid={errorMessage ? "true" : "false"}
        className={inputCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      />
      {showLabel && (
        <label htmlFor={computedId} className={labelCn()}>
          <div className={onlyLabelCn()}>{label}</div>
          {!!supportingText?.length && (
            <div className={onlySupportingTextCn()}>{supportingText}</div>
          )}
        </label>
      )}
    </div>
  );
}

Checkbox.propTypes = propTypes;
export default Checkbox;
