import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconWithTitle } from "../molecules";

const propTypes = {
  icon: PropTypes.node,
  product: PropTypes.object,
  job: PropTypes.object,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

const JobDetailsBox = ({ className, icon, job, product, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-row justify-between ${className ? className : undefined}`}>
      <div className="flex flex-col">
        <div className="text-teal-500 font-medium text-xs mb-2 uppercase ">
          {t("details_box.label.product_job")}
        </div>
        <div className="text-grey-500 font-medium text-xl first-letter:uppercase ">{job.name}</div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row gap-x-1">
          <div className="text-grey-500 font-normal text-base first-letter:uppercase">
            {t("page.products.body.code")+t("data.general.colon")}
          </div>
          <div className="text-grey-900 font-normal text-base">
            {product.sku}
          </div>
        </div>
        <div className="text-amber-600 font-medium text-sm flex justify-end">
          <IconWithTitle
            icon={icon}
            text={product.name}
            isLoading={isLoading}
            classname="gap-1 mt-2"
          />
        </div>
      </div>
    </div>
  );
};

JobDetailsBox.propTypes = propTypes;
export default JobDetailsBox;
