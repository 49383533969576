import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useState, useMemo } from "react";

const proptypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl]),
};

function Paragraph({ text, size, hasMarginBottom, className }) {
  const [textStyle, setTextStyle] = useState("");

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setTextStyle(`text-sm ${hasMarginBottom ? "mb-[0.875rem]" : "mb-0"} `);
        break;
      case SIZE.md:
        setTextStyle(`text-base ${hasMarginBottom ? "mb-4" : "mb-0"} `);
        break;
      case SIZE.lg:
        setTextStyle(`text-lg ${hasMarginBottom ? "mb-[1.125rem]" : "mb-0"} `);
        break;
      case SIZE.xl:
        setTextStyle(`text-xl ${hasMarginBottom ? "mb-5" : "mb-0"} `);
        break;
      default:
        setTextStyle(`text-sm ${hasMarginBottom ? "mb-[0.875rem]" : "mb-0"} `);
        break;
    }
  }, [hasMarginBottom, size]);

  const wrapperCn = () => cntl`
    text-grey-600
    ${textStyle}
    ${className ? className : undefined}
  `;

  return <div className={wrapperCn()} dangerouslySetInnerHTML={{ __html: text}} />;
}

Paragraph.prototype = proptypes;
export default Paragraph;
