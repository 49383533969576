import cntl from "cntl";
import { Badge } from ".";
import PropTypes from "prop-types";
import { useMemo, useState, useCallback } from "react";
import { BADGE_ICON_VARIANTS, POSITIONS, SIZE } from "../constants";
import { getBadgeColors } from "@dbox/core/utils/getBadgeColors";

const propTypes = {
  label: PropTypes.string,
  isOutline: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
  variant: PropTypes.oneOf([
    BADGE_ICON_VARIANTS.custom,
    BADGE_ICON_VARIANTS.only_icon,
  ]),
};

function BadgeIcon({
  size,
  icon,
  label,
  variant,
  isOutline,
  className,
  iconPosition = POSITIONS.left,
}) {
  const [gap, setGap] = useState("");
  const [value, setValue] = useState(label);
  const [padding, setPadding] = useState("");
  const [rounded, setRounded] = useState("");
  const [sizeIcon, setSizeIcon] = useState("");
  const [colorIcon, setColorIcon] = useState("");
  const [sizeBadge, setSizeBadge] = useState("");

  const badgeColors = useMemo(() => getBadgeColors(label), [label]);

  const colorsFilled = () => cntl`
    ${badgeColors?.backgroundColor}
  `;

  const colorsOutline = () => cntl`
    outline
    bg-white
    outline-[1.5px]
    outline-offset-[-1.5px]
    ${badgeColors?.bordersColor}
  `;

  const colors = () => cntl`
    ${badgeColors?.textColor}
    ${isOutline ? colorsOutline() : colorsFilled()}
  `;

  const badgeIconCn = () => cntl`
    ${gap}
    ${rounded}
    ${padding}
    ${colors()}
    ${sizeBadge}
    ${className ? className : undefined}
  `;

  const iconCn = () => cntl`
    flex
    items-center
    ${sizeIcon}
    ${colorIcon}
    ${
      variant !== BADGE_ICON_VARIANTS.only_icon &&
      iconPosition === POSITIONS.left
        ? cntl`order-0`
        : cntl`order-1`
    }
  `;

  const sizeDefaultIcon = () => {
    switch (size) {
      case SIZE.sm:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-1.5 pr-2`
            : cntl`pr-1.5 pl-2`
        );
        break;
      case SIZE.md:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-1.5 pr-2.5`
            : cntl`pr-1.5 pl-2.5`
        );
        break;
      case SIZE.lg:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-2.5 pr-3`
            : cntl`pr-2.5 pl-3`
        );
        break;
      default:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-1.5 pr-2`
            : cntl`pr-1.5 pl-2`
        );
        break;
    }
  };

  const sizeCustomIcon = () => {
    switch (size) {
      case SIZE.sm:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-[0.188rem] pr-2`
            : cntl`pr-[0.188rem] pl-2`
        );
        break;
      case SIZE.md:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-1 pr-2.5`
            : cntl`pr-1 pl-2.5`
        );
        break;
      case SIZE.lg:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-1.5 pr-3`
            : cntl`pr-1.5 pl-3`
        );
        break;
      default:
        setPadding(
          iconPosition === POSITIONS.left
            ? cntl`pl-[0.188rem] pr-2`
            : cntl`pr-[0.188rem] pl-2`
        );
        break;
    }
  };

  const sizeOnlyIcon = () => {
    switch (size) {
      case SIZE.sm:
        setPadding("px-1");
        setSizeBadge("w-5");
        break;
      case SIZE.md:
        setPadding("px-1.5");
        setSizeBadge("w-6");
        break;
      case SIZE.lg:
        setPadding("px-2");
        setSizeBadge("w-7");
        break;
      default:
        setPadding("px-1");
        setSizeBadge("w-5");
        break;
    }
  };

  useMemo(() => {
    switch (variant) {
      case BADGE_ICON_VARIANTS.custom:
        sizeCustomIcon();
        setGap("gap-x-1.5");
        setSizeIcon("w-4 h-4");
        setRounded("rounded-2xl");
        break;
      case BADGE_ICON_VARIANTS.only_icon:
        setValue("");
        sizeOnlyIcon();
        setGap("gap-x-0");
        setRounded("rounded-full");
        setSizeIcon("w-3 h-3 shrink-0");
        setColorIcon(badgeColors?.iconColor);
        break;
      default:
        sizeDefaultIcon();
        setGap("gap-x-1");
        setSizeIcon("w-3 h-3");
        setRounded("rounded-2xl");
        setColorIcon(badgeColors?.iconColor);
        break;
    }
  }, [variant, size, iconPosition]);

  return (
    <Badge className={badgeIconCn()} label={value} size={size}>
      <div className={iconCn()}>{icon}</div>
    </Badge>
  );
}

BadgeIcon.propTypes = propTypes;
export default BadgeIcon;
