import T from "prop-types";
import { useState, useEffect } from "react";
import { BadgeText, Divider } from "../atoms";
import { useTranslation } from "react-i18next";

const propTypes = {
  className: T.string,
  rowData: T.object,
};

function FormatPreview({ className, rowData }) {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [machines, setMachines] = useState([]);
  const [badgeValue, setBadgeValue] = useState("");

  useEffect(() => {
    setName(rowData?.job?.name);
    setMachines(rowData?.steps);
    setBadgeValue(rowData?.job?.code);
    setCode(rowData?.code);
    setTitle(t("card.associated_machines"));
  }, [rowData, t]);

  return (
    <div className={`flex flex-col p-4 ${className ? className : undefined}`}>
      <div className="text-xl font-semibold text-grey-900 first-letter:uppercase mb-2">
        {name}
      </div>
      <div className="flex flex-row items-center justify-between mb-3">
        {!!badgeValue && <BadgeText label={badgeValue} />}
        {!!code && (
          <div className="text-sm text-grey-500 first-letter:uppercase">
            {code}
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {!!rowData?.imposition && (
          <div className="flex flex-row mb-2">
            <p className="text-sm text-grey-500 first-letter:uppercase">
              {t(`page.products.body.imposition.${rowData?.imposition}`)}
            </p>
          </div>
        )}
        {!!rowData?.imposition_type && (
          <div className="flex flex-row">
            <p className="text-sm text-grey-500 first-letter:uppercase">
              {t(`page.products.body.imposition_type.${rowData?.imposition_type}`)}
            </p>
          </div>
        )}
        <Divider marginY="my-4" tone="dark" />
      </div>
      <div className="flex flex-row mb-3">
        <div className="text-base font-medium text-grey-900 first-letter:uppercase">
          {title}
        </div>
      </div>
      <div className="flex flex-col">
        {machines?.map((machine, index) => {
          return (
            <div
              className="flex flex-row items-center justify-between last:mb-0 mb-2"
              key={`machine-${index}`}
            >
              <p className="text-sm text-grey-500 first-letter:uppercase">
                {machine?.tenant_machine?.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

FormatPreview.propTypes = propTypes;
export default FormatPreview;
