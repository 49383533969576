import cntl from "cntl";
import PropTypes from "prop-types";
import { useEffect, Children, Fragment } from "react";

const propTypes = {
  id: PropTypes.string,
  top: PropTypes.string,
  width: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Modal({ id, width, isOpen, children, top }) {
  const overlayCn = ({ isOpen }) => cntl`
    modal
    z-50
    fixed
    top-0
    left-0
    w-full
    h-full
    backdrop-blur-md
    bg-grey-700/60
    ${
      isOpen
        ? cntl`visible duration-300 opacity-100 ease-in	`
        : cntl`invisible duration-300 opacity-0 ease-out`
    }
  `;

  const modalCn = ({ width }) => cntl`
    fixed
    bg-white
    left-1/2
    shadow-lg
    rounded-xl
    transition-all
    -translate-x-1/2
    ${width ? width : cntl`w-[initial]`}
    ${top?.length ? top : cntl`top-1/2 -translate-y-1/2`}
  `;

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  return (
    <div id={id} className={overlayCn({ isOpen })}>
      <div className={modalCn({ width })}>
        {children &&
          Children.map(children, (child, index) => {
            return <Fragment key={`modal-child-${index}`}>{child}</Fragment>;
          })}
      </div>
    </div>
  );
}

Modal.propTypes = propTypes;
export default Modal;
