import PropTypes from "prop-types";

const propTypes = {
  sibConversationsID: PropTypes.string.isRequired,
};

const SendiblueConversations = ({ sibConversationsID }) => {
  const initSendublue = () => {
    (function (d, w, c) {
      w.SibConversationsID = sibConversationsID;
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      var s = d.createElement("script");
      s.async = true;
      s.src =
        "https://conversations-widget.sendinblue.com/sib-conversations.js";
      if (d.head) d.head.appendChild(s);
    })(document, window, "SibConversations");
  };

  return <script>{initSendublue()}</script>;
};

SendiblueConversations.propTypes = propTypes;
export default SendiblueConversations;
