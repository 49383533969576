import cntl from "cntl";
import PropTypes from "prop-types";
import { IconStar, IconStarHalf } from "@tabler/icons-react";

const proptypes = {
  isHalf: PropTypes.bool,
  isFull: PropTypes.bool,
};
const ReviewStar = ({ isHalf, isFull }) => {
  const starWrapperCn = () => cntl`
    relative
    flex-inline
    overflow-hidden
  `;

  const greyStarCn = () => cntl`
    fill-grey-200 
    text-grey-200
  `;

  const halfStarCn = () => cntl`
    -ml-px
    inset-0
    right-0
    absolute
    fill-warning-400 
    text-warning-400
  `;

  const fullStarCn = () => cntl`
    inset-0
    right-0
    absolute
    fill-warning-400 
    text-warning-400
  `;

  return (
    <div className={starWrapperCn()}>
      <IconStar className={greyStarCn()} />
      {isHalf && <IconStarHalf className={halfStarCn()} />}
      {isFull && <IconStar className={fullStarCn()} />}
    </div>
  );
};

ReviewStar.prototype = proptypes;
export default ReviewStar;
