import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function Header({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

Header.propTypes = propTypes;
export default Header;
