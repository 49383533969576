import { IconX } from "@tabler/icons-react";
import T from "prop-types";
import { Children, Fragment } from "react";

const propTypes = {
  title: T.string,
  onClose: T.func,
  closeRightSidebar: T.func,
  isRightSidebarOpen: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

function RightSidebar({
  title,
  onClose,
  children,
  closeRightSidebar,
  isRightSidebarOpen,
}) {
  return (
    <div
      onClick={closeRightSidebar}
      className={`h-full top-0 z-50 shadow-lg bg-grey-25 w-[44rem] fixed p-6 ease-in-out duration-700 overflow-y-auto
        ${isRightSidebarOpen ? `right-0` : `-right-full`}
      `}
    >
      <div className="flex flex-row items-center justify-between">
        <div
          className={`text-2xl text-grey-900 font-bold first-letter:capitalize`}
        >
          {title}
        </div>
        <IconX
          onClick={onClose}
          className="w-6 h-6 text-grey-500 cursor-pointer"
        />
      </div>
      {children &&
        Children.map(children, (child, index) => {
          return (
            <Fragment key={`quote-workflow-right-sidebar-child-${index}`}>
              {child}
            </Fragment>
          );
        })}
    </div>
  );
}

RightSidebar.propTypes = propTypes;
export default RightSidebar;
