import cntl from "cntl";
import PropTypes from "prop-types";
import { BadgeText } from "../atoms";
import { COLOR } from "../constants";
import { cloneElement } from "react";
import { IconChevronDown } from "@tabler/icons-react";

const propTypes = {
  path: PropTypes.string,
  icon: PropTypes.object,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  hasBadge: PropTypes.bool,
  isActive: PropTypes.bool,
  hasStatus: PropTypes.bool,
  counter: PropTypes.number,
  isDropdown: PropTypes.bool,
  className: PropTypes.string,
  colors: PropTypes.oneOf([COLOR.dark, COLOR.primary, COLOR.light]),
};

function NavItemBase({
  icon,
  label,
  isOpen,
  colors,
  counter,
  onClick,
  isActive,
  hasBadge,
  hasStatus,
  className,
  isDropdown,
}) {
  const wrapperCn = () => cntl`
    flex
    py-2
    px-3
    flex-row
    gap-x-12
    cursor-pointer
    justify-between
    ${className ? className : undefined}
  `;

  const dotCn = () => cntl`
    w-2
    h-2
    rounded-full
    ${colors?.dot}
  `;

  const leftWrapperCn = () => cntl`
    flex
    gap-x-3
    flex-row
    items-center
    ${
      !hasBadge && !isDropdown && !hasStatus
        ? "justify-start w-full lg:justify-center lg:w-fit"
        : "justify-center"
    }
  `;

  const rightWrapperCn = () => cntl`
    flex
    gap-x-2
    flex-row
    shrink-0
    items-center
    justify-center
    ${hasBadge || isDropdown ? "flex" : "hidden"}
  `;

  const labelCn = () => cntl`
    text-base
    font-semibold
    ${isActive ? colors?.item?.active : colors?.item?.noActive}
  `;

  const iconCn = () => cntl`
    w-6
    h-6
  `;

  const arrowCn = ({ isOpen }) => cntl`
    w-5
    h-5
    duration-300
    ${isOpen && cntl`rotate-180`}
    ${isActive ? colors?.icon?.active : colors?.icon?.noActive}
  `;

  return (
    <div className={wrapperCn()} onClick={onClick}>
      <div className={leftWrapperCn()}>
        {hasStatus && <div className={dotCn()} />}
        <div className={iconCn()}>
          {icon &&
            cloneElement(icon, {
              className: isActive ? colors?.active?.icon : colors?.noActive?.icon,
            })}
        </div>
        {label?.length && <div className={labelCn()}>{label}</div>}
      </div>
      <div className={rightWrapperCn()}>
        {hasBadge && <BadgeText label={counter} className={colors?.badge} />}
        {isDropdown && <IconChevronDown className={arrowCn({ isOpen })} />}
      </div>
    </div>
  );
}

NavItemBase.propTypes = propTypes;
export default NavItemBase;
