import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon, TextLink } from "../atoms";
import { useMemo, useState, cloneElement } from "react";
import { COLOR, SIZE, POSITIONS, FEATURED_ICON_VARIANTS } from "../constants";

const propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.object,
  isBoxed: PropTypes.bool,
  image: PropTypes.object,
  title: PropTypes.string,
  size: PropTypes.oneOf([SIZE.md, SIZE.lg]),
  position: PropTypes.oneOf([POSITIONS.left, POSITIONS.center]),
  theme: PropTypes.oneOf([COLOR.dark, COLOR.grey, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function SectionItem({ icon, text, link, title, theme, image, isBoxed, position, size }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [textSize, setTextSize] = useState("");
  const [iconColor, setIconColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [featuredIconColor, setFeaturedIconColor] = useState(null);
  const [featuredIconVariant, setFeaturedIconVariant] = useState(null);

  const titleCn = () => cntl`
    font-semibold
    ${textSize}
    ${titleColor}
  `;

  const textCn = () => cntl`
    font-normal
    text-base
    ${textColor}
  `;

  const imageCn = () => cntl`
    absolute
    ${
      position === "center"
        ? isBoxed
          ? "left-1/2 -translate-x-2/4"
          : "left-1/2 -translate-x-2/4"
        : isBoxed
        ? "left-6"
        : ""
    }
    top-0
  `;

  const wrapperCn = () => cntl`
    h-full
    ${isBoxed && bgColor}
    ${isBoxed && "rounded-lg px-6 pb-8"}
    ${image && "pt-9 md:pt-12"}
    ${position === "center" ? "text-center" : ""}
    flex
    flex-col
    gap-3
  `;

  const contentWrapperCn = () => cntl`
    ${position === "center" ? "text-center items-center" : ""}
    flex
    flex-col
    gap-4
    md:gap-5
  `;

  const linkWrapperCn = () => cntl`
    flex
    ${position === "center" ? "justify-center" : ""}
  `;

  const textWrapperCn = () => cntl`
    ${position === "center" ? "text-center" : ""}
    flex
    flex-col
    gap-1
    md:gap-2
  `;

  const containerWrapperCn = () => cntl`
    relative
    ${image && "pt-6"}
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-grey-700");
        setIconColor("text-white");
        setTitleColor("text-white");
        setTextColor("text-grey-200");
        setFeaturedIconColor(COLOR.grey);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.dark);
        break;
      case COLOR.grey:
        setBgColor("bg-grey-100");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        setIconColor("text-primary-600");
        setFeaturedIconColor(COLOR.primary);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
      case COLOR.primary:
        setIconColor("text-white");
        setTitleColor("text-white");
        setBgColor("bg-primary-700");
        setTextColor("text-primary-200");
        setFeaturedIconColor(COLOR.primary);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.dark);
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-100");
        setTextColor("text-primary-700");
        setIconColor("text-primary-600");
        setTitleColor("text-primary-900");
        setFeaturedIconColor(COLOR.primary);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
      default:
        setBgColor("bg-grey-100");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        setIconColor("text-primary-600");
        setFeaturedIconColor(COLOR.primary);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
    }
  }, [theme]);

  useMemo(() => {
    switch (size) {
      case SIZE.lg:
        setTextSize("text-3xl");
        break;
      default:
        setTextSize("text-lg md:text-xl");
        break;
    }
  }, [size]);

  return (
    <div className={containerWrapperCn()}>
      <div className={wrapperCn()}>
        <div className={contentWrapperCn()}>
          {icon && (
            <FeaturedIcon size={SIZE.md} color={featuredIconColor} variant={featuredIconVariant}>
              {icon}
            </FeaturedIcon>
          )}
          <div className={textWrapperCn()}>
            {title && <div className={titleCn()} dangerouslySetInnerHTML={{ __html: title }} />}
            {text && <div className={textCn()} dangerouslySetInnerHTML={{ __html: text }} />}
          </div>
          {link && (
            <div className={linkWrapperCn()}>
              <TextLink {...link} />
            </div>
          )}
        </div>
      </div>
      {image && (
        <div className={imageCn()}>
          {cloneElement(image, {
            className: `w-10 h-10 md:w-14 md:h-14 ${iconColor}`,
          })}
        </div>
      )}
    </div>
  );
}

SectionItem.prototype = propTypes;
export default SectionItem;
