import { IconHome } from "@tabler/icons-react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

function BreadcrumbText({ link, label, isActive, isHomePage = false }) {
  const baseLiCn = () => cntl`
    text-sm
    list-none
    capitalize
    font-medium
    inline-flex
  `;

  const iconCn = () => cntl`
    w-5
    h-5
  `;

  const active = () => cntl`
    ${
      isActive
        ? cntl`text-primary-700 hover:text-primary-700`
        : cntl`text-grey-500 hover:text-grey-700`
    }
  `;

  const liCn = () => cntl`
    ${active()}
    ${baseLiCn()}
  `;

  const renderBreadcrumb = () => {
    if (isHomePage) {
      return (
        <li className={liCn()}>
          <Link to={link}>
            <IconHome className={iconCn()} />
          </Link>
        </li>
      );
    } else {
      return (
        <li className={liCn()}>
          <Link
            to={link}
            className={isActive ? "cursor-auto" : "cursor-pointer"}
          >
            {label}
          </Link>
        </li>
      );
    }
  };

  return renderBreadcrumb();
}

BreadcrumbText.propTypes = propTypes;
export default BreadcrumbText;
