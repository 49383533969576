import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  supportingText: PropTypes.string,
  formRegistration: PropTypes.object,
};

function Toggle({
  id,
  name,
  size,
  label,
  value,
  onChange,
  isChecked,
  className,
  isDisabled,
  supportingText,
  formRegistration = null,
}) {
  const [fontStyle, setFontStyle] = useState("");
  const [toggleBoxSize, setToggleBoxSize] = useState("");
  const [toggleSliderSize, setToggleSliderSize] = useState("");
  const [toggleSliderTranslate, setToggleSliderTranslate] = useState("");

  const {
    onChange: onFormChange,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedName = name || formName;

  const computedId = id || computedName;

  const box = () => cntl`
    flex
    p-0.5
    rounded-xl
    ease-in-out
    bg-grey-100
    duration-300
    items-center
    flex-shrink-0
    hover:bg-grey-200
    peer-focus:ring
    peer-focus:ring-offset-0
    peer-focus:ring-primary-100
    peer-checked:bg-primary-600
    peer-disabled:hover:bg-grey-100
    peer-checked:peer-disabled:bg-grey-100
    ${toggleBoxSize}
  `;

  const slider = () => cntl`
    after:shadow
    after:bg-white
    after:rounded-full
    after:duration-200
    ${toggleSliderSize}
    ${toggleSliderTranslate}
  `;

  const spanCn = () => cntl`
    ${box()}
    ${slider()}
  `;

  const toggleCn = () => cntl`
    gap-x-2
    flex-row
    items-start
    inline-flex
    ${className ? className : undefined}
  `;

  const inputCn = () => cntl`
    peer
    sr-only
  `;

  const labelCn = () => cntl`
    ${fontStyle}
    first-letter:capitalize
  `;

  const onlyLabelCn = () => cntl`
    text-grey-700
    ${!!supportingText?.length ? cntl`font-medium` : cntl`font-regular`}
  `;

  const onlySupportingTextCn = () => cntl`
    text-grey-500
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-sm");
        setToggleBoxSize("w-9 h-5");
        setToggleSliderSize("after:w-4 after:h-4");
        setToggleSliderTranslate("peer-checked:after:translate-x-4");
        break;
      case SIZE.md:
        setFontStyle("text-base");
        setToggleBoxSize("w-11 h-6");
        setToggleSliderSize("after:w-5 after:h-5");
        setToggleSliderTranslate("peer-checked:after:translate-x-5");
        break;
      default:
        setFontStyle("text-sm");
        setToggleBoxSize("w-9 h-5");
        setToggleSliderSize("after:w-4 after:h-4");
        setToggleSliderTranslate("peer-checked:after:translate-x-4");
        break;
    }
  }, [size]);

  return (
    <label className={toggleCn()}>
      <input
        value={value}
        type="checkbox"
        id={computedId}
        name={computedName}
        checked={isChecked}
        disabled={isDisabled}
        className={inputCn()}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        {...rest}
      />
      <span className={spanCn()} />
      {!!label?.length && (
        <label htmlFor={computedId} className={labelCn()}>
          <div className={onlyLabelCn()}>{label}</div>
          {!!supportingText?.length && (
            <div className={onlySupportingTextCn()}>{supportingText}</div>
          )}
        </label>
      )}
    </label>
  );
}

Toggle.propTypes = propTypes;
export default Toggle;
