import cntl from "cntl";
import PropTypes from "prop-types";
import { STATUS } from "../constants";
import { useTranslation } from "react-i18next";

const propTypes = {
  label: PropTypes.string,
  sizeFile: PropTypes.string,
  progress: PropTypes.string,
  textStyle: PropTypes.string,
  status: PropTypes.oneOf([STATUS.success, STATUS.error, STATUS.undefined]),
  hideProgress: PropTypes.bool,
};

function ProgressBar({
  label,
  sizeFile,
  progress,
  textStyle,
  status,
  hideProgress,
}) {
  const { t } = useTranslation();

  const labelWrapperCn = () => cntl`
    flex
    w-full
    text-sm
    flex-col
    font-medium
    justify-center
    ${textStyle?.length ? textStyle : cntl`text-grey-900`}
  `;

  const spanCn = () => cntl`
    font-normal
    text-grey-500
  `;

  const progressWrapperCn = () => cntl`
    flex
    gap-x-3
    flex-row
    items-center
  `;

  const progressWrapper = () => cntl`
    h-2
    w-full
    rounded
    bg-primary-50
  `;

  const progressCn = () => cntl`
    w-full
    h-full
    rounded
    bg-primary-600
  `;

  const pCn = () => cntl`
    mb-3
    first-letter:capitalize
  `;

  return (
    <label className={labelWrapperCn()} htmlFor="progress-file-upload">
      {label && (
        <p className={pCn()}>
          {status !== STATUS.error ? label : t("file_upload.upload_error")}
          <br />
          {status === STATUS.error && (
            <span className="font-normal">{label}</span>
          )}
          {status !== STATUS.error && sizeFile?.length && (
            <span className={spanCn()}>{sizeFile + " KB"}</span>
          )}
        </p>
      )}
      {progress && status !== STATUS.error && (
        <div className={progressWrapperCn()}>
          <div className={progressWrapper()}>
            <div className={progressCn()} style={{ width: progress + "%" }} />
          </div>
          {!hideProgress && progress + "%"}
        </div>
      )}
    </label>
  );
}

ProgressBar.propTypes = propTypes;
export default ProgressBar;
