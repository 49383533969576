import { IconEye, IconEyeOff } from "@tabler/icons-react";
import PropTypes from "prop-types";
import { useState } from "react";

const propTypes = {
  label: PropTypes.string,
  fontStyle: PropTypes.string,
  isVisibleToDefault: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

function ShowHideValue({
  label,
  fontStyle,
  value,
  isVisibleToDefault = false,
}) {
  const [visibility, setVisibility] = useState(isVisibleToDefault);

  const onToggle = (e, visibility) => {
    e.stopPropagation();

    setVisibility(visibility);
  };
  return (
    <div className="flex flex-col w-72">
      {label && (
        <label className="mb-1 text-xs text-medium first-letter:uppercase">
          {label}
        </label>
      )}
      <div className="flex flex-row items-center gap-x-2">
        <input
          className={`focus:outline-none border-none w-full bg-inherit text-sm ${
            fontStyle ? fontStyle : undefined
          }`}
          type={visibility ? "text" : "password"}
          defaultValue={value}
          readOnly
        />
        <div
          className="hover:cursor-pointer"
          onClick={(e) => onToggle(e, !visibility)}
        >
          {visibility ? (
            <IconEyeOff className="w-5 h-5 text-grey-500" />
          ) : (
            <IconEye className="w-5 h-5 text-grey-500" />
          )}
        </div>
      </div>
    </div>
  );
}

ShowHideValue.propTypes = propTypes;
export default ShowHideValue;
