import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { forwardRef, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";
import { IconUser } from "@tabler/icons-react";

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  letters: PropTypes.string,
  supportingText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  picture: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reference: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

const Avatar = forwardRef(({ size, text, picture, letters, onClick, children, supportingText, className }, ref) => {
  const [fontSize, setFontSize] = useState("");
  const [textSize, setTextSize] = useState("");
  const [avatarSize, setAvatarSize] = useState("");
  const [wrapperSize, setWrapperSize] = useState("");
  const [supportingTextSize, setSupportingTextSize] = useState("");

  const avatarWrapperCn = () => cntl`
    flex
    relative
    flex-row
    rounded-full
    items-center
    cursor-pointer
    justify-center
    overflow-visible
    focus:outline
    focus:outline-4
    focus:outline-grey-100
    ${wrapperSize}
    ${!!!picture && "bg-grey-100"}
  `;

  const pictureCn = () => cntl`
    rounded-full
    overflow-hidden
  `;

  const lettersCn = () => cntl`
    text-center
    font-medium
    text-grey-600
    ${fontSize}
  `;

  const iconCn = () => cntl`
    text-grey-600
    ${avatarSize}
  `;

  const childrenCn = () => cntl`
    right-0
    absolute
    bottom-0
  `;

  const containerWrapperCn = () => cntl`
    flex-row
    gap-x-2.5
    inline-flex
    items-center
    ${className ? className : undefined}
  `;

  const textWrapperCn = () => cntl`
    w-36
    flex
    flex-col
  `;

  const textCn = () => cntl`
    truncate
    text-left
    font-medium
    text-grey-700
    ${textSize}
  `;

  const supportingTextCn = () => cntl`
    truncate
    text-left
    text-grey-500
    ${supportingTextSize}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.xs:
        setFontSize("text-xs");
        setTextSize("text-sm");
        setAvatarSize("w-4 h-4");
        setWrapperSize("w-6 h-6");
        setSupportingTextSize("text-xs");
        break;
      case SIZE.sm:
        setFontSize("text-sm");
        setTextSize("text-sm");
        setAvatarSize("w-5 h-5");
        setWrapperSize("w-8 h-8");
        setSupportingTextSize("text-xs");
        break;
      case SIZE.md:
        setFontSize("text-md");
        setTextSize("text-sm");
        setAvatarSize("w-6 h-6");
        setWrapperSize("w-10 h-10");
        setSupportingTextSize("text-sm");
        break;
      case SIZE.lg:
        setFontSize("text-lg");
        setAvatarSize("w-7 h-7");
        setTextSize("text-base");
        setWrapperSize("w-12 h-12");
        setSupportingTextSize("text-base");
        break;
      case SIZE.xl:
        setFontSize("text-xl");
        setTextSize("text-lg");
        setAvatarSize("w-8 h-8");
        setWrapperSize("w-14 h-14");
        setSupportingTextSize("text-base");
        break;
      case SIZE["2xl"]:
        setFontSize("text-2xl");
        setTextSize("text-xl");
        setAvatarSize("w-8 h-8");
        setWrapperSize("w-16 h-16");
        setSupportingTextSize("text-base");
        break;
      default:
        setTextSize("text-sm");
        setFontSize(" text-xs");
        setAvatarSize("w-4 h-4");
        setWrapperSize("w-6 h-6");
        setSupportingTextSize("text-xs");
        break;
    }
  }, [size]);

  return (
    <div className={containerWrapperCn()} ref={ref}>
      <div id="avatar" tabIndex="0" onClick={onClick} className={avatarWrapperCn()}>
        {picture?.buffer ? (
          <LazyLoadImage
            alt="image-avatar"
            className={pictureCn()}
            src={`data:image/png;base64,${arrayBufferToBase64(picture?.buffer ?? null)}`}
          />
        ) : letters ? (
          <div className={lettersCn()}>{letters}</div>
        ) : (
          <IconUser className={iconCn()} />
        )}
        <div className={childrenCn()}>{children}</div>
      </div>
      {!!text?.length && (
        <div className={textWrapperCn()}>
          <div className={textCn()}>{text}</div>
          <div className={supportingTextCn()}>{supportingText}</div>
        </div>
      )}
    </div>
  );
});

Avatar.propTypes = propTypes;
export default Avatar;
