import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
function Badge({ label, size, className, children }) {
  const { t } = useTranslation();
  const [badgeSize, setBadgeSize] = useState("");
  const [fontStyle, setFontStyle] = useState("");

  const badgeCn = () => cntl`
    box-border
    inline-flex
    items-center
    justify-center
    ${badgeSize}
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    ${fontStyle}
    first-letter:uppercase
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setBadgeSize("h-5");
        setFontStyle("text-xs font-medium");
        break;
      case SIZE.md:
        setBadgeSize("h-6");
        setFontStyle("text-sm font-medium");
        break;
      case SIZE.lg:
        setBadgeSize("h-7");
        setFontStyle("text-sm font-medium");
        break;
      default:
        setBadgeSize("h-5");
        setFontStyle("text-xs font-medium");
        break;
    }
  }, [size]);

  return (
    <span className={badgeCn()}>
      {children}
      <p className={labelCn()}>{isNaN(label) ? t(`badge.label.${label}`) : label}</p>
    </span>
  );
}

Badge.propTypes = propTypes;
export default Badge;
