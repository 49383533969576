import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { ButtonPrimary, ButtonGrey, CheckItem, HeaderPricing } from "@dbox/components";
import { BUTTON_VARIANTS, CHECK_ITEM_ICON_VARIANTS, COLOR, POSITIONS, SIZE } from "@dbox/components/constants";

const propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pretitle: PropTypes.string,
  checkItems: PropTypes.array,
  badgeLabel: PropTypes.string,
  isMostPopular: PropTypes.bool,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  labelMostPopular: PropTypes.string,
  buttonColor: PropTypes.oneOf([COLOR.primary, COLOR.grey]),
  alignment: PropTypes.oneOf([POSITIONS.left, POSITIONS.center]),
  theme: PropTypes.oneOf([COLOR.primary_light, COLOR.grey, COLOR.light]),
};

function PricingTierCard({
  icon,
  title,
  theme,
  subtitle,
  pretitle,
  badgeLabel,
  checkItems,
  buttonColor,
  description,
  buttonLabel,
  buttonOnClick,
  isMostPopular,
  labelMostPopular,
  alignment = POSITIONS.center,
}) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.primary_light:
        setBgColor(`bg-primary-50`);
        setTextColor(`text-primary-700`);
        break;
      case COLOR.grey:
        setBgColor(`bg-grey-50`);
        setTextColor(`text-grey-600`);
        break;
      case COLOR.light:
        setTextColor(`text-grey-600`);
        setBgColor(`bg-white border ${!isMostPopular ? "border-grey-200" : "border-primary-500"}`);
        break;
      default:
        setTextColor(`text-grey-600`);
        setBgColor(`bg-white border ${!isMostPopular ? "border-grey-200" : "border-primary-500"}`);
        break;
    }
  }, [theme, isMostPopular]);

  const wrapperCn = () => cntl`
    flex
    max-w-sm
    flex-col
    shadow-lg
    rounded-2xl
    items-center
    justify-center
    overflow-hidden
    ${bgColor}
  `;

  const container = () => cntl`
    px-8
    py-8
    flex
    w-full
    h-full
    flex-col
    justify-between
  `;

  const mostPopularCn = () => cntl`
    h-11
    flex
    w-full
    items-center
    bg-primary-500
    justify-center
  `;

  const textMostPopularCn = () => cntl`
    text-sm
    text-white
    font-semibold
    first-letter:uppercase
  `;

  const ctaWrapperCn = () => cntl`
    flex
    w-full
    lg:pt-2
    flex-col
    bg-grey-50
    items-center
    justify-center
  `;

  const checkItemsContainerCn = () => cntl`
    flex
    pt-8
    pb-10
    w-full
    gap-y-4
    flex-col
    items-center
    justify-start
    ${textColor}
  `;

  const headerPricingProps = {
    icon,
    title,
    theme,
    pretitle,
    subtitle,
    alignment,
    badgeLabel,
    description,
  };

  return (
    <div className={wrapperCn()}>
      {isMostPopular && (
        <div className={mostPopularCn()}>
          <div className={textMostPopularCn()}>{labelMostPopular}</div>
        </div>
      )}
      <div className={container()}>
        <HeaderPricing {...headerPricingProps} />
        <div className={checkItemsContainerCn()}>
          {checkItems?.map((checkItem, index) => {
            return (
              <CheckItem
                text={checkItem}
                color={COLOR.primary}
                key={`check-item-${index}`}
                variant={CHECK_ITEM_ICON_VARIANTS.light}
              />
            );
          })}
        </div>
        <div className={ctaWrapperCn()}>
          {buttonColor === COLOR.primary ? (
            <ButtonPrimary size={SIZE.md} className="w-full" onClick={buttonOnClick} label={buttonLabel} />
          ) : (
            <ButtonGrey
              size={SIZE.md}
              className="w-full"
              label={buttonLabel}
              onClick={buttonOnClick}
              variant={BUTTON_VARIANTS.border}
            />
          )}
        </div>
      </div>
    </div>
  );
}

PricingTierCard.prototype = propTypes;
export default PricingTierCard;
