import cntl from "cntl";
import PropTypes from "prop-types";
import { COLOR } from "../constants";
import { useMemo, useState } from "react";

const propTypes = {
  text: PropTypes.string,
  socials: PropTypes.array,
  theme: PropTypes.oneOf([COLOR.dark, COLOR.grey, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function PressMentionSection({ socials, text, theme }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");

  const socialCn = () => cntl`
    flex
    w-fit
    gap-x-3
    flex-row
    items-center
    justify-center 
  `;

  const logolCn = () => cntl`
    ${titleColor}
  `;

  const textCn = () => cntl`
    text-base
    text-center
    ${textColor}
  `;

  const wrapperCn = () => cntl`
    flex
    h-full
    gap-y-8
    flex-col
    items-center
  `;

  const socialsCn = () => cntl`
    gap-x-8
    gap-y-4
    flex
    flex-col
    lg:flex-row
    justify-between
  `;

  const containerWrapperCn = () => cntl`
    py-16
    md:px-20
    md:py-24
    px-4
    ${bgColor}
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-grey-700");
        setTitleColor("text-white");
        setTextColor("text-grey-200");
        break;
      case COLOR.grey:
        setBgColor("bg-grey-100");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        break;
      case COLOR.primary:
        setTitleColor("text-white");
        setBgColor("bg-primary-700");
        setTextColor("text-primary-200");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-100");
        setTextColor("text-primary-700");
        setTitleColor("text-primary-900");
        break;
      default:
        setBgColor("bg-grey-100");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        break;
    }
  }, [theme]);

  return (
    <div className={containerWrapperCn()}>
      <div className={wrapperCn()}>
        {text?.length > 0 && <div className={textCn()}>{text}</div>}
        <div className={socialsCn()}>
          {socials?.map(({ link, logo }, index) => {
            return (
              <a href={link} className={socialCn()} key={`social-link-${index}`}>
                {logo && <div className={logolCn()}>{logo}</div>}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

PressMentionSection.prototype = propTypes;
export default PressMentionSection;
