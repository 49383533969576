import cntl from "cntl";
import PropTypes from "prop-types";
import TabButton from "./TabButton";
import { useState, useMemo } from "react";
import { SIZE } from "../constants/index";

const propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  className: PropTypes.string,
  ariaSelected: PropTypes.string,
  ariaControls: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function TabButtonWhite({
  id,
  size,
  label,
  onClick,
  children,
  tabIndex,
  className,
  isDisabled,
  ariaSelected,
  ariaControls,
}) {
  const [padding, setPadding] = useState("");

  const tabButtonWhiteCn = () => cntl`
    ${padding}
    ${
      ariaSelected === "true"
        ? cntl`bg-white text-grey-700 shadow focus:shadow-sm`
        : !!!isDisabled && cntl`text-grey-500 bg-transparent hover:bg-white hover:shadow`
    }
    ${className ? className : undefined}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setPadding("px-3 py-2");
        break;
      case SIZE.md:
        setPadding("px-3.5 py-2.5");
        break;
      default:
        setPadding("px-3 py-2");
        break;
    }
  }, [size]);

  return (
    <TabButton
      id={id}
      size={size}
      label={label}
      onClick={onClick}
      tabIndex={tabIndex}
      isDisabled={isDisabled}
      ariaSelected={ariaSelected}
      ariaControls={ariaControls}
      className={tabButtonWhiteCn()}
    >
      {children}
    </TabButton>
  );
}

TabButtonWhite.propTypes = propTypes;
export default TabButtonWhite;
