import cntl from "cntl";
import PropTypes from "prop-types";
import { TextLink } from "../atoms";
import { cloneElement } from "react";
import Content from "./SectionContent";
import SectionItem from "./SectionItem";
import { POSITIONS } from "../constants";
import { useNavigate } from "react-router";
import { IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  content: PropTypes.object,
  sectionItem: PropTypes.array,
};

function FeatureSectionAlternated02({ content, sectionItem }) {
  const navigate = useNavigate();

  const containerCn = () => cntl`
    pt-16
    mx-auto
    xl:pt-24
    container
    px-4 md:px-8 py-16 md:py-24
  `;

  const contentAndItemCn = () => cntl`
    flex
    pb-16
    xl:pb-24
    flex-col
    gap-y-12
    xl:gap-y-24
    items-center
  `;

  const wrapperListCn = () => cntl`
    flex
    flex-col
    gap-y-12
    xl:gap-y-24
  `;

  const sectionItemCn = (index) => cntl`
    flex
    flex-col
    gap-x-24
    gap-y-10
    container
    xl:gap-y-8
    items-center
    xl:justify-between
    ${index % 2 ? "xl:flex-row-reverse" : "xl:flex-row"}
  `;

  const imageContainerCn = () => cntl`
    w-full
    relative
    xl:w-1/2
    h-[17.25rem] 
    sm:h-[25rem]
  `;

  const imageWrapperCn = (index) => cntl`
    py-6
    px-4
    left-0
    right-0
    xl:p-10
    absolute
    bg-grey-100
    rounded-3xl
    h-[17.25rem]
    sm:h-[25rem]
    max-w-[35rem]
    ${index % 2 ? "mx-auto xl:ml-0 xl:mr-auto" : "mx-auto xl:mr-0 xl:ml-auto"}
  `;

  const ItemContentCn = () => cntl`
    flex 
    w-full 
    gap-y-8
    xl:w-1/2 
    flex-col 
  `;

  return (
    <div className={containerCn()}>
      <div className={contentAndItemCn()}>
        {content && <Content {...content} position={POSITIONS.center} />}
        {sectionItem && (
          <div className={wrapperListCn()}>
            {sectionItem.map((item, index) => {
              return (
                <div key={index} className={sectionItemCn(index)}>
                  <div className={ItemContentCn()}>
                    <SectionItem {...item} icon={null} link={null} image={null} />
                    {item?.link?.label?.length && (
                      <TextLink
                        onAction={() => navigate(item.link.to)}
                        label={item.link.label}
                        iconPosition={POSITIONS.right}
                        icon={<IconArrowRight />}
                      />
                    )}
                  </div>
                  <div className={imageContainerCn()}>
                    <div className={imageWrapperCn(index)}>
                      {item.image && cloneElement(item.image, { className: "h-full w-full" })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

FeatureSectionAlternated02.prototype = propTypes;
export default FeatureSectionAlternated02;
