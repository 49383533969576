import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUp } from "@dbox/core/api/aldys/auth";
import { LoaderIcon } from "@dbox/components/icons";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, ButtonPrimary, Input } from "../atoms";
import { openSnackbar } from "@dbox/core/actions/common/ui";

function SignupForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const termsAndConditionsLink = process.env.REACT_APP_TERMS_LINK;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  const onFormSubmit = async (values) => {
    setIsLoading(true);
    const { name, email, password, password_confirmation } = values;
    try {
      await signUp({
        name,
        email,
        password,
        password_confirmation,
      });
      navigate("/login");
    } catch (err) {
      dispatch(
        openSnackbar({
          type: "error",
          message: err.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-row items-center justify-center h-screen">
          <LoaderIcon className="animate-spin" />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex flex-col gap-y-4"
        >
          <Input
            name="name"
            type="text"
            label={t("input.label.username")}
            formRegistration={register("name", {
              required: {
                value: true,
                message: t("input.field.required_field"),
              },
            })}
            errors={errors}
          />
          <Input
            name="email"
            type="email"
            label={t("input.label.email")}
            formRegistration={register("email", {
              required: {
                value: true,
                message: t("input.field.required_field"),
              },
            })}
            errors={errors}
          />
          <Input
            isPassword
            name="password"
            label={t("input.label.password")}
            formRegistration={register("password", {
              required: {
                value: true,
                message: t("input.field.required_field"),
              },
              minLength: {
                value: 8,
                message: t("input.field.min_char"),
              },
              validate: (value) =>
                value === watch("password") ||
                t("form_error__password_not_same"),
            })}
            errors={errors}
          />
          <Input
            isPassword
            name="password_confirmation"
            label={t("input.label.password_confirmation")}
            formRegistration={register("password_confirmation", {
              required: {
                value: true,
                message: t("input.field.required_field"),
              },
              minLength: {
                value: 8,
                message: t("input.field.min_char"),
              },
              validate: (value) =>
                value === watch("password") ||
                t("input.field.password_not_same"),
            })}
            errors={errors}
          />
          <Checkbox
            id="term-and-condition"
            className="my-4"
            label={
              <Trans
                i18nKey="page.sign_up.body.paragraph_2"
                values={{ link: t("page.sign_up.body.terms_and_condition") }}
                components={{
                  l: (
                    <a
                      href={termsAndConditionsLink}
                      onClick={(e) => e.stopPropagation()}
                      className="text-primary-600 font-medium cursor-pointer"
                    ></a>
                  ),
                }}
              />
            }
            onChange={() => setIsChecked(!isChecked)}
          />
          <ButtonPrimary
            type="submit"
            disabled={!isChecked}
            label={t("button.label.register_account")}
            data-cypress="form-btn"
            className="w-full justify-center"
          />
        </form>
      )}
    </>
  );
}

export default SignupForm;
