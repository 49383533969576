import { Provider } from "react-redux";
import React, { Suspense } from "react";
import { store } from "@dbox/cromie-common";
import { Snackbar } from "@dbox/components";
import CromieRoutes from "./routes/CromieRoutes";
import Bootstrap from "@dbox/core/components/Bootstrap";

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Snackbar />
        <Bootstrap app={"cromie"}>
          <CromieRoutes />
        </Bootstrap>
      </Provider>
    </Suspense>
  );
}

export default App;
