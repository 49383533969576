import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useState } from "react";
import NavItemBase from "./NavItemBase";
import { NavLink } from "react-router-dom";

const propTypes = {
  colors: PropTypes.object,
  subItem: PropTypes.array,
  className: PropTypes.string,
  dropdownPath: PropTypes.string,
};

const NavItemHeader = ({
  colors,
  subItem,
  className,
  dropdownPath,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const liWrapperCn = () => cntl`
    mx-4
    my-0.5
  `;

  const navLinkCn = ({ isActive, isExpanded }) => cntl`
    block
    rounded-md
    ${isActive || isExpanded ? colors?.item?.active : colors?.item?.noActive}
  `;

  const onExpandChange = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const resolveLinkPath = (childTo, parentTo) => {
    if (childTo?.length && parentTo?.length) {
      return `${parentTo}/${childTo}`;
    }
    return childTo;
  };

  return (
    <li tabIndex={0} className={liWrapperCn()}>
      <div className="group">
        <NavLink
          to={resolveLinkPath(dropdownPath)}
          className={({ isActive }) => navLinkCn({ isActive, isExpanded })}
        >
          <NavItemBase
            isDropdown
            colors={colors}
            isOpen={isExpanded}
            onClick={onExpandChange}
            {...props}
          />
        </NavLink>
      </div>
      {isExpanded && (
        <ul>
          {subItem?.map(({ label, path, children }, index) => {
            if (children?.length) {
              return (
                <NavItemHeader
                  label={label}
                  colors={colors}
                  subItem={children}
                  key={`${label}-${index}`}
                  dropdownPath={resolveLinkPath(path, dropdownPath)}
                />
              );
            } else {
              return (
                <li className="group my-0.5" key={`${label}-${index}`}>
                  <NavLink
                    to={resolveLinkPath(path, dropdownPath)}
                    className={({ isActive }) => navLinkCn({ isActive })}
                  >
                    <NavItemBase label={label} icon={null} />
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      )}
    </li>
  );
};

NavItemBase.propTypes = propTypes;
export default NavItemHeader;