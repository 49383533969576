import cntl from "cntl";
import { get } from "lodash";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  underLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  formRegistration: PropTypes.object,
};

function FormSingleInputFile({
  name,
  label,
  errors,
  className,
  underLabel,
  formRegistration = null,
  onChange: onOuterChange,
}) {
  const { t } = useTranslation();

  const inputCn = ({ hasErrors }) => cntl`
    h-9
    w-full
    relative
    rounded-lg
    box-content
    outline-none
    form-control
    border border-solid
    ${focused ? cntl`border-cyan` : null}
    ${focused ? cntl`focused` : null}
    ${focused ? cntl`outline-none` : null}
    ${focused ? cntl`shadow-none` : null}
    ${
      hasErrors
        ? cntl`focus:bg-primary-25 focus:border-error border-error`
        : cntl`border-grey-300 focus:bg-primary-25 focus:border-primary-400 focus:text-grey-900`
    } 
  `;

  const labelCn = ({ focused, filePath, hasErrors }) => cntl`
    ${hasErrors ? cntl`text-red` : cntl`text-gray`}  
    py-0
    mb-0
    pl-24
    px-1.25
    left-3.5
    absolute
    text-sm
    text-grey-500
    ${!focused && !!filePath ? cntl`hidden` : null}
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-xs
    text-error-600
    font-medium
    first-letter:uppercase
    ${!hasErrors && cntl`invisible`}
  `;

  const underLabelCn = ({ underLabel }) => cntl`
    text-xs
    font-medium
    mt-1
    text-grey-500
    first-letter:uppercase
    ${!underLabel && cntl`invisible`}
  `;

  const errorMessage = get(errors, `[${name}].message`, "");
  const [filePath, setFilePath] = useState("");
  const [focused, setFocused] = useState(false);

  const onChange = (e) => {
    if (formRegistration) {
      formRegistration.onChange(e);
    }

    setFilePath(e.target.value);

    if (onOuterChange) {
      onOuterChange(e);
    }
  };

  const contentLabel = filePath || label;

  return (
    <div className={`flex flex-col  ${className ? className : undefined}`}>
      <div className={`form-group relative flex items-center flex-wrap`}>
        <div className={inputCn({ focused, hasErrors: !!errorMessage })} />
        <input
          type="file"
          className="z-10 absolute inset-0 w-full h-full opacity-0"
          id={name}
          name={name}
          aria-invalid={errorMessage ? "true" : "false"}
          placeholder=" "
          onChange={onChange}
          ref={formRegistration ? formRegistration.ref : null}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={(e) => {
            if (formRegistration) {
              formRegistration.onBlur(e);
            }
            setFocused(false);
          }}
        />
        <label
          htmlFor={name}
          className={labelCn({ hasErrors: !!errorMessage, filePath, focused })}
        >
          <p className="first-letter:capitalize">{contentLabel}</p>
        </label>
        <div className="absolute left-0 px-3 h-9 bg-primary-600 text-white flex items-center text-sm font-medium rounded-l-lg">
          <p className="first-letter:capitalize">
            {t("input_file.label.choose_file")}
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-x-2">
        {errorMessage && (
          <div className={errorCn({ hasErrors: !!errorMessage })}>
            {errorMessage ? errorMessage : t("label__error_message")}
          </div>
        )}
        {underLabel?.length && (
          <div className={underLabelCn({ underLabel })}>
            {underLabel ? underLabel : t("label__under_label")}
          </div>
        )}
      </div>
    </div>
  );
}

FormSingleInputFile.propTypes = propTypes;
export default FormSingleInputFile;
