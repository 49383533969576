import PropTypes from "prop-types";

const propTypes = {
  iconDropdownBtn: PropTypes.node.isRequired,
  onClickDropdownBtn: PropTypes.func.isRequired,
  classNameDropdownBtn: PropTypes.string.isRequired,
};

function DropdownButton({
  iconDropdownBtn,
  onClickDropdownBtn,
  classNameDropdownBtn,
}) {
  return (
    <div className={classNameDropdownBtn} onClick={onClickDropdownBtn}>
      {iconDropdownBtn}
    </div>
  );
}

DropdownButton.propTypes = propTypes;
export default DropdownButton;
