import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { TriangolTooltip } from "../icons";

const propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  className: PropTypes.string,
  arrowPosition: PropTypes.string,
};

function OldTooltip({ text, icon, arrowPosition = "bottom", className }) {
  const [rotation, setRotation] = useState("");

  useEffect(() => {
    switch (arrowPosition?.toLowerCase()) {
      case "up":
        setRotation("rotate-180 -translate-y-full");
        break;
      case "bottom":
        setRotation("rotate-0");
        break;
      default:
        setRotation("rotate-0");
        break;
    }
  }, [arrowPosition]);

  return (
    <div className={`absolute hidden z-10 ${className || ""}`}>
      <div className="relative">
        {arrowPosition?.toLowerCase() === "up" && (
          <div className={`absolute left-1/2 -translate-x-2/4 ${rotation}`}>
            <TriangolTooltip direction="horizontal" />
          </div>
        )}
        <div className="flex items-center bg-grey-900 rounded-lg px-2 h-9">
          <div className="mr-2.5">{icon}</div>
          <div className="text-white text-sm first-letter:capitalize">
            {text}
          </div>
        </div>
        {arrowPosition === "bottom" && (
          <div className={`absolute left-1/2 -translate-x-2/4 ${rotation}`}>
            <TriangolTooltip direction="horizontal" />
          </div>
        )}
      </div>
    </div>
  );
}

OldTooltip.propTypes = propTypes;
export default OldTooltip;
