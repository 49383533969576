import cntl from "cntl";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { ButtonPrimary, TextLink } from "@dbox/components";
import { LINK_VARIANTS, POSITIONS, SIZE } from "@dbox/components/constants";
import VerificationTitleAndSubtitle from "../atoms/VerificationTitleAndSubtitle";
import { IconMail } from "@tabler/icons-react";

const propTypes = {
  email: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  onClickTextLink: PropTypes.func,
};

function ContainerVerificationStep1({
  email,
  onClick,
  className,
  onClickTextLink,
}) {
  const { t } = useTranslation();

  const container = () => cntl`
    px-4
    xs:px-0
    xs:w-[22.5rem]
    ${className ? className : undefined}
  `;

  const wrapperCn = () => cntl`
    flex
    gap-y-8
    flex-col
  `;

  const textLinkCn = () => cntl`
    self-center
  `;

  return (
    <section className={container()}>
      <div className={wrapperCn()}>
        <VerificationTitleAndSubtitle
          icon={<IconMail />}
          title={t("page.verification.step1.title")}
          subTitle={
            <Trans
              i18nKey="page.verification.step1.sub_title"
              values={{
                email: email,
              }}
              components={{
                e: <span className="font-semibold" />,
              }}
            />
          }
        />
        <ButtonPrimary
          size={SIZE.lg}
          onClick={onClick}
          label={t("button.label.enter_code_manually")}
        />
        <TextLink
          className={textLinkCn()}
          onAction={onClickTextLink}
          variant={LINK_VARIANTS.grey}
          iconPosition={POSITIONS.left}
          label={t("text_link.label.back_to_login")}
        />
      </div>
    </section>
  );
}

ContainerVerificationStep1.propTypes = propTypes;
export default ContainerVerificationStep1;
