import T from "prop-types";
import { useState, cloneElement, Children, Fragment } from "react";

const propTypes = {
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

function QuoteAccordion({ className, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const onStateAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="list-none">
      <div className={className ? className : undefined}>
        {children &&
          Children.map(children, (child, index) => {
            return (
              <Fragment key={`accordion-child-${index}`}>
                {cloneElement(child, {
                  isOpen: isOpen,
                  onStateAccordion: onStateAccordion,
                })}
              </Fragment>
            );
          })}
      </div>
    </li>
  );
}

QuoteAccordion.propTypes = propTypes;
export default QuoteAccordion;
