import { useState } from "react";
import PropTypes from "prop-types";
import { BadgeText } from "../atoms";
import { formatValues, STATUS_VALUES } from "../constants";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const propTypes = {
  title: PropTypes.string,
  badgeValue: PropTypes.oneOf([formatValues, STATUS_VALUES]),
  className: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasHeader: PropTypes.bool,
  openAccordionNoHeader: PropTypes.bool,
};

function Accordion({
  title,
  className,
  badgeValue,
  icon,
  onClick,
  children,
  hasHeader = true,
  openAccordionNoHeader,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onIconClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className="flex flex-col rounded-lg">
      {hasHeader && (
        <div
          className={`flex items-center px-4 h-14 border border-solid border-grey-300 hover:cursor-pointer
        ${isOpen ? `bg-grey-25 rounded-t-lg ` : `bg-white rounded-lg `}
        ${className ? className : ``}
      `}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-row items-center gap-x-4">
              {title && (
                <div className="first-letter:capitalize font-medium text-base text-grey-500">
                  {title}
                </div>
              )}
              {!!badgeValue && (
                <BadgeText label={badgeValue} />
              )}
            </div>
            <div className="flex flex-row items-center gap-x-6">
              <div
                className="flex items-center justify-center w-10 h-10 lg:w-5 lg:h-5"
                onClick={(e) => onIconClick(e)}
              >
                {icon && icon}
              </div>
              <div className="flex items-center justify-center w-5 h-5">
                {isOpen ? <IconChevronUp className="w-5 h-5 text-primary-500" /> : <IconChevronDown className="w-5 h-5 text-primary-500" />}
              </div>
            </div>
          </div>
        </div>
      )}
      {(hasHeader ? isOpen : openAccordionNoHeader) && (
        <div
          className={`w-full border-solid
            ${
              hasHeader
                ? `border-grey-300 border-b border-l border-r rounded-b-lg p-4`
                : `border-l-4 border-l-primary-300 pl-4 mt-4`
            }
          `}
        >
          {!hasHeader && title && (
            <div className="text-xl text-grey-900 font-semibold first-letter:uppercase mb-2">
              {title}
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
}

Accordion.propTypes = propTypes;
export default Accordion;
