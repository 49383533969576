import PropTypes from "prop-types";
import { BadgeText, IconWithLink } from "../atoms";
import IconWithTitle from "./IconWithTitle";

const propTypes = {
  overline: PropTypes.string,
  leftIcon: PropTypes.node,
  leftTitle: PropTypes.string,
  badgeValue: PropTypes.string,
  rightData: PropTypes.array,
  rightIcon: PropTypes.node,
  rightName: PropTypes.string,
  className: PropTypes.string,
};

function ProductSubsectionsDetailsBox({
  overline,
  leftIcon,
  leftTitle,
  badgeValue,
  rightData,
  rightIcon,
  rightName,
  className,
}) {
  return (
      <div className={`flex flex-col ${className ? className : undefined}`}>
        <div className="flex flex-row text-xs text-teal-500 uppercase">
          {overline}
        </div>
        <div className="flex flex-row items-center justify-between my-2">
          <IconWithTitle icon={leftIcon} text={leftTitle} />
          <BadgeText label={badgeValue} />
          {rightData &&
            rightData?.map((item, index) => {
              return (
                <div
                  key={`item-${index}`}
                  className="flex flex-row text-base text-grey-900"
                >
                  <div className="text-grey-500 first-letter:capitalize">
                    {item.label}
                  </div>
                  <div className="text-grey-500 mr-1">{item.separator}</div>
                  <>{item.value}</>
                </div>
              );
            })}
        </div>
        <div className="flex flex-row items-center justify-end">
          <IconWithLink isGoBack icon={rightIcon} text={rightName} />
        </div>
      </div>
  );
}

ProductSubsectionsDetailsBox.propTypes = propTypes;
export default ProductSubsectionsDetailsBox;
