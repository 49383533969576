import T from "prop-types";
import { useState, useEffect } from "react";
import { BadgeText, Divider } from "../atoms";
import { useTranslation } from "react-i18next";

const propTypes = {
  rowData: T.object,
};

function CustomerPreview({ rowData }) {
  const { t } = useTranslation();
  const [cic, setCic] = useState("");
  const [name, setName] = useState("");
  const [orders, setOrders] = useState([]);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    setCic(rowData?.cic);
    setQuotes(rowData?.counts?.quotes || []);
    setOrders(rowData?.counts?.orders || []);
    setName(rowData?.firstname + " " + rowData?.lastname);
  }, [rowData]);

  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="text-xl font-bold first-letter:capitalize">
            {name}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="text-base font-normal text-grey-500">{cic}</div>
        </div>
      </div>
      <Divider marginY="my-4" tone="dark" />
      <div className="flex flex-col">
        <div className="mb-2 text-base font-medium text-grey-900 first-letter:uppercase">
          {t("preview.customer.quotes")}
        </div>
        <div>
          {Object.entries(quotes).map(([key, value], index) => {
            return (
              <div
                className="flex justify-between items-center my-2"
                key={`badge-quote-${index}`}
              >
                <BadgeText label={key} />
                <div className="text-sm text-grey-900">{value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <Divider marginY="my-4" />
      <div className="flex flex-col">
        <div className="mb-2 text-base font-medium text-grey-900 first-letter:uppercase">
          {t("preview.customer.orders")}
        </div>
        <div>
          {Object.entries(orders).map(([key, value], index) => {
            return (
              <div
                className="flex justify-between items-center my-2"
                key={`badge-order-${index}`}
              >
                <BadgeText label={key} />
                <div className="text-sm text-grey-900">{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

CustomerPreview.propTypes = propTypes;
export default CustomerPreview;
