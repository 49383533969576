import cntl from "cntl";
import PropTypes from "prop-types";
import { COLOR, SIZE } from "../constants";
import { useMemo, useState } from "react";
import SectionContent from "./SectionContent";

const propTypes = {
  content: PropTypes.object,
  description: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  theme: PropTypes.oneOf([
    COLOR.dark,
    COLOR.grey,
    COLOR.light,
    COLOR.primary,
    COLOR.primary_light,
  ]),
};

function SectionHeader({ content, theme, description, size }) {
  const [bgColor, setBgColor] = useState("");
  const [descriptionColor, setDescriptionColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    mx-auto
    container
    px-3 md:px-8 py-16 md:py-24
  `;

  const contentAndDescriptionCn = () => cntl`
    flex
    flex-col
    gap-x-16
    ${description?.length ? cntl`justify-between md:flex-row` : cntl``}
  `;

  const descriptionCn = () => cntl`
    mt-4
    w-full
    text-xl
    md:w-1/3
    md:mt-12
    ${descriptionColor}
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-grey-800");
        setDescriptionColor("text-grey-200");
        break;
      case COLOR.grey:
        setBgColor("bg-grey-50");
        setDescriptionColor("text-grey-500");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-800");
        setDescriptionColor("text-primary-200");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-50");
        setDescriptionColor("text-primary-700");
        break;
      case COLOR.light:
        setBgColor("bg-white");
        setDescriptionColor("text-grey-500");
        break;
      default:
        setBgColor("bg-white");
        setDescriptionColor("text-grey-500");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentAndDescriptionCn()}>
          <SectionContent size={size} {...content} theme={theme} />
          {description?.length && (
            <div className={descriptionCn()}>{description}</div>
          )}
        </div>
      </div>
    </div>
  );
}

SectionHeader.prototype = propTypes;
export default SectionHeader;
