import PropTypes from "prop-types";
import { Children, useState, Fragment, cloneElement } from "react";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const CardSwitcherSelection = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState();

  const handleClick = (index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex();
    }
  };

  return (
    children &&
    Children.map(children, (child, index) => {
      return (
        <Fragment key={`card-selection-child-${index}`}>
          {cloneElement(child, {
            onSelectionClick: () => handleClick(index),
            isActive: selectedIndex === index,
          })}
        </Fragment>
      );
    })
  );
};

CardSwitcherSelection.propTypes = propTypes;
export default CardSwitcherSelection;
