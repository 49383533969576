function VisaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="8"
      viewBox="0 0 26 8"
      fill="none"
    >
      <path
        d="M16.7237 0C14.9766 0 13.4154 0.908687 13.4154 2.58755C13.4154 4.5129 16.1844 4.64588 16.1844 5.61312C16.1844 6.02038 15.7192 6.38495 14.9248 6.38495C13.7974 6.38495 12.9548 5.87554 12.9548 5.87554L12.5943 7.56971C12.5943 7.56971 13.565 8 14.8537 8C16.7638 8 18.2669 7.0467 18.2669 5.33912C18.2669 3.30465 15.4863 3.17562 15.4863 2.27786C15.4863 1.95883 15.8682 1.60926 16.6603 1.60926C17.554 1.60926 18.2832 1.97974 18.2832 1.97974L18.6361 0.343464C18.6361 0.343464 17.8427 0 16.7237 0ZM0.739463 0.123493L0.697159 0.370478C0.697159 0.370478 1.43217 0.505461 2.09416 0.774723C2.94653 1.08349 3.00725 1.26323 3.1508 1.82151L4.7151 7.87265H6.81205L10.0426 0.123493H7.95041L5.87462 5.39219L5.02757 0.926194C4.94989 0.415065 4.55641 0.123493 4.07477 0.123493H0.739463ZM10.8838 0.123493L9.24262 7.87265H11.2376L12.8731 0.123493H10.8838ZM22.0108 0.123493C21.5297 0.123493 21.2748 0.381942 21.0878 0.833575L18.165 7.87265H20.2571L20.6619 6.69947H23.2107L23.4568 7.87265H25.3028L23.6924 0.123493H22.0108ZM22.2829 2.21708L22.903 5.12494H21.2416L22.2829 2.21708Z"
        fill="#1434CB"
      />
    </svg>
  );
}

export default VisaIcon;
