import cntl from "cntl";
import PropTypes from "prop-types";
import { get } from "lodash";

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  formRegistration: PropTypes.object,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
};

function FormTextarea({
  className,
  type = "text",
  name = "",
  label = "",
  disabled,
  value,
  onBlur,
  onChange,
  errors,
  hasError = true,
  formRegistration = null,
}) {
  const labelCn = () => cntl`
    mb-1
    block
    text-xs
    font-medium
    text-grey-500
    first-letter:uppercase
  `;

  const inputCn = ({ hasErrors }) => cntl`
    min-h-32
    w-full
    py-2.5
    px-4
    text-sm
    bg-white bg-clip-padding
    relative
    border border-solid
    rounded-lg
    text-grey-900
    focus:outline-none
    ${
      hasErrors
        ? cntl`focus:bg-primary-25 focus:border-error border-error`
        : cntl`border-grey-300 focus:bg-primary-25 focus:border-primary-400 focus:text-grey-900`
    }  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-xs
    text-error-600
    font-medium
    ${!hasErrors && cntl`invisible`}
  `;

  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedName = name || formName;

  const errorMessage = get(errors, `[${computedName}].message`, "");

  return (
    <div className={`flex flex-col ${className ? className : undefined}`}>
      <label className={labelCn()} htmlFor={computedName}>
        {label}
      </label>
      <textarea
        type={type}
        className={inputCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        id={computedName}
        disabled={disabled}
        name={computedName}
        aria-invalid={errorMessage ? "true" : "false"}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
        }}
        value={value}
        {...rest}
      />
      {hasError && (
        <div className={errorCn({ hasErrors: !!errorMessage })}>
          <p className="first-letter:capitalize">
            {errorMessage ? errorMessage : "Error message"}
          </p>
        </div>
      )}
    </div>
  );
}

FormTextarea.propTypes = propTypes;

export default FormTextarea;
