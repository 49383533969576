import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getBadgeColors } from "@dbox/core/utils/getBadgeColors";
import { IconCircleCheck } from "@tabler/icons-react";

const propTypes = {
  text: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

const SelectionSmall = ({
  text,
  onClick,
  isSelected,
  className,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const res = useMemo(() => getBadgeColors(text), [text]);

  return (
    <>
      {res && (
        <div
          onClick={onClick}
          className={`h-11 rounded-lg hover:cursor-pointer border border-solid ease-out duration-200 w-40
        ${className ? className : ""}
        ${
          isSelected && res?.bordersColor
            ? res?.bordersColor
            : "border-grey-300"
        }
        ${
          isSelected && res?.outlineColor
            ? `outline outline-1 ${res?.outlineColor}`
            : ``
        }
        ${
          isSelected && res?.backgroundColor ? res?.backgroundColor : "bg-white"
        }
      `}
        >
          <div className="flex justify-between items-center w-full h-full px-4">
            <div
              className={`text-base first-letter:capitalize ${
                isSelected
                  ? res?.textColor
                  : isDisabled
                  ? `text-grey-500`
                  : `text-grey-500`
              }`}
            >
              {t(`selection.small.${text}`)}
            </div>
            <div className="flex items-center justify-center w-5 h-5 ml-1">
              {isSelected && (
                <IconCircleCheck className={`w-5 h-5 ${res?.textColor}`} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SelectionSmall.propTypes = propTypes;
export default SelectionSmall;
