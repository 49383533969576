import PropTypes from "prop-types";
import { LabelsWithValues, IconWithTitle } from "../molecules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";

const propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  data: PropTypes.array,
  image: PropTypes.object,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

const DetailsBoxWithImageBox = ({
  icon,
  title,
  image,
  data,
  isLoading,
  className,
}) => {
  return (
    <div
      className={`flex justify-between ${className ? className : undefined}`}
    >
      <div className="flex flex-col">
        <IconWithTitle icon={icon} text={title} isLoading={isLoading} />
        <div className="flex flex-row gap-x-8 mt-4 items-end">
          <LabelsWithValues
            data={data}
            isLoading={isLoading}
          />
        </div>
      </div>
      {image && (
        <div className="flex">
          <div className="w-24 h-24 rounded-lg">
            <LazyLoadImage
              alt="profile"
              className="rounded-lg"
              src={`data:image/png;base64,${arrayBufferToBase64(
                image?.buffer ?? null
              )}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DetailsBoxWithImageBox.propTypes = propTypes;
export default DetailsBoxWithImageBox;
