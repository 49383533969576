import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement, useMemo, useState } from "react";
import { COLOR, FEATURED_ICON_VARIANTS, SIZE } from "../constants";
import MetricItemsList from "../molecules/MetricItemsList";

const proptypes = {
  multiCols: PropTypes.bool,
  items: PropTypes.array,
  text: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.grey, COLOR.primary, COLOR.primary_light]),
};

function MetricSection({ title, text, icon, items, multiCols, theme }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [iconColor, setIconColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.grey:
        setBgColor("bg-grey-50");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        setIconColor(COLOR.primary_light);
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-50");
        setTextColor("text-primary-500");
        setTitleColor("text-primary-900");
        setIconColor(COLOR.primary_light);
        break;
      case COLOR.primary:
        setBgColor("bg-primary-800");
        setTextColor("text-primary-200");
        setTitleColor("text-white");
        setIconColor(COLOR.primary);
        break;
      case COLOR.dark:
        setBgColor("bg-grey-800");
        setTextColor("text-grey-200");
        setTitleColor("text-white");
        setIconColor(COLOR.grey);
        break;
      case COLOR.light:
        setBgColor("bg-white");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        setIconColor(COLOR.primary_light);
        break;
      default:
        setBgColor("bg-white");
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        setIconColor(COLOR.primary_light);
        break;
    }
  }, [theme]);

  const containerWrapper = () => cntl`
    ${bgColor}
    ${textColor}
  `;

  const wrapperCn = () => cntl`
    flex
    flex-col
    justify-center
    items-center
    gap-y-12
    lg:gap-y-16
    container
    py-24
    mx-auto
  `;
  const headerCn = () => cntl`
    flex
    flex-col
    justify-center
    items-center
  `;
  const titleCn = () => cntl`
    pt-6
    pb-5
    font-semibold
    text-4xl
    ${titleColor}
  `;
  const textCn = () => cntl`
    font-normal
    text-xl	
  `;

  return (
    <div className={containerWrapper()}>
      <div className={wrapperCn()}>
        <div className={headerCn()}>
          {cloneElement(icon, {
            size: SIZE.lg,
            variant: FEATURED_ICON_VARIANTS.light_circle_outline,
            color: iconColor,
          })}
          <div className={titleCn()}>{title}</div>
          <div className={textCn()}>{text}</div>
        </div>
        <MetricItemsList items={items} multiCols={multiCols} theme={theme} />
      </div>
    </div>
  );
}

MetricSection.prototype = proptypes;
export default MetricSection;
