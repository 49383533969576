import cntl from "cntl";
import PropTypes from "prop-types";
import { POSITIONS } from "../constants";
import { useTranslation } from "react-i18next";
import { PaginationCounter } from "../molecules";
import { PaginationButtonGroupBase } from "../atoms";

const propTypes = {
  isMobile: PropTypes.bool,
  nextPage: PropTypes.func,
  goToPage: PropTypes.func,
  previousPage: PropTypes.func,
  isDetailsVisible: PropTypes.bool,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
  paginationProps: PropTypes.exact({
    canNextPage: PropTypes.bool,
    canPreviousPage: PropTypes.bool,
    paginationRange: PropTypes.array,
  }),
};

function PaginationContentButtonGroup({
  isMobile,
  nextPage,
  goToPage,
  pagesInfo,
  previousPage,
  paginationProps,
  isDetailsVisible,
}) {
  const { t } = useTranslation();

  const paginationContentNumbersCn = () => cntl`
    flex-row
    shadow-sm
    rounded-lg
    inline-flex
    items-center
    overflow-hidden
    border border-grey-300
  `;

  return (
    <div className={paginationContentNumbersCn()}>
      <PaginationButtonGroupBase
        onClick={() => previousPage()}
        positionArrow={POSITIONS.left}
        disabled={!paginationProps?.canPreviousPage}
        label={!isMobile ? t("button.label.previous") : ""}
      />
      <PaginationCounter
        isButtonGroup
        pagesInfo={pagesInfo}
        isDetailsVisible={isDetailsVisible}
        range={paginationProps?.paginationRange}
        onPageClick={(value) => goToPage(value - 1)}
      />
      <PaginationButtonGroupBase
        className="border-r-0"
        onClick={() => nextPage()}
        positionArrow={POSITIONS.right}
        disabled={!paginationProps?.canNextPage}
        label={!isMobile ? t("button.label.next") : ""}
      />
    </div>
  );
}

PaginationContentButtonGroup.propTypes = propTypes;
export default PaginationContentButtonGroup;
