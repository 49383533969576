import PropTypes from "prop-types";

const propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  formRegistration: PropTypes.object,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
};

function FormRadioButton({
  disabled,
  label = "",
  name = "",
  value,
  onChange,
  formRegistration = null,
  className,
  isChecked,
}) {
  const {
    onChange: onFormChange,
    name: formName,
    ...rest
  } = formRegistration || {};

  const computedName = name || formName;

  return (
    <div className={className || ""}>
      <label className="flex flex-row items-start cursor-pointer">
        <input
          checked={isChecked}
          type="radio"
          className="hidden peer"
          name={computedName}
          id={computedName}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            if (onFormChange) {
              onFormChange(e);
            }
            if (onChange) {
              onChange(e);
            }
          }}
          {...rest}
        />
        {label && (
          <div className="ml-2 text-sm text-grey-500 order-1 first-letter:capitalize">
            {label}
          </div>
        )}
        <div
          className="shrink-0 relative w-4 h-4 top-0.5 rounded-full border border-grey-300 bg-white peer-checked:border-primary-700 peer-checked:bg-primary-700
                    peer-checked:after:bg-white peer-checked:after:w-2 peer-checked:after:h-2 peer-checked:after:block
                    peer-checked:after:rounded-full peer-checked:after:absolute peer-checked:after:top-1/2 peer-checked:after:left-1/2
                    peer-checked:after:-translate-y-1/2	peer-checked:after:-translate-x-1/2"
        />
      </label>
    </div>
  );
}

FormRadioButton.propTypes = propTypes;
export default FormRadioButton;
