import cntl from "cntl";
import PropTypes from "prop-types";
import { DatePicker } from "@dbox/components";
import { useState, useRef, useEffect } from "react";
import { debounce, throttle } from "lodash";
import { PickerPositions } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { closeAllPickers, openPicker } from "@dbox/core/actions/common/ui";

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  className: PropTypes.string,
  onPagination: PropTypes.bool,
};

function InputDate({
  name = "",
  label = "",
  id,
  disabled,
  onChange,
  onInputChange,
  className,
  placeholder = " ",
  onBlur,
  appendIcon,
  onPagination = false,
}) {
  const inputDateRef = useRef();

  const [pickerInfo, setPickerInfo] = useState({
    position: "",
  });

  const [selectedValue, setSelectedValue] = useState("");
  const [pickerVisible, setPickerVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const onResize = () => {
      const rect = inputDateRef.current?.getBoundingClientRect();
      const clientHeight = document.documentElement?.clientHeight;
      if (clientHeight - rect?.bottom > 322) {
        setPickerInfo({ position: PickerPositions.BOTTOM });
        return;
      }
      if (rect?.top > 322) {
        setPickerInfo({ position: PickerPositions.TOP });
        return;
      }
      console.error("Not enough space to show the picker");
      setPickerVisible(false);
      setPickerInfo({ position: null });
    };

    onResize();

    window.addEventListener("resize", throttle(onResize, 500));
    return () => {
      window.removeEventListener("resize", throttle(onResize, 500));
    };
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setPickerVisible(false);
    };

    window.addEventListener("scroll", debounce(onScroll));
    return () => {
      window.removeEventListener("scroll", debounce(onScroll));
    };
  }, []);

  const inputCn = ({ onPagination }) => cntl`
    form-input
    py-0
    border
    enable:bg-white
    disabled:bg-grey-50
    shadow-sm
    rounded-lg
    focus:ring-4
    enabled:text-grey-900
    disabled:text-grey-500
    focus:ring-offset-0
    h-11
    w-full
    text-base
    border-grey-300 focus:ring-primary-100 focus:border-primary-300
    ${!onPagination ? "px-4 rounded-lg" : "px-1 shadow-inner"}
  `;

  const labelCn = () => cntl`
    mb-1
    block
    text-xs
    font-medium
    text-grey-500
    first-letter:capitalize
  `;

  const pickerCn = cntl`
    absolute
    z-10
    left-1/2
    -translate-x-2/4
    ${pickerInfo.position === PickerPositions.TOP && cntl`-top-[22rem]`}
    ${pickerInfo.position === PickerPositions.BOTTOM && cntl`top-16`}
  `;

  const hasOnePickerOpened = useSelector(
    (state) => state.ui.hasOnePickerOpened
  );

  const onTextChange = (event) => {
    const val = event.target.value;
    onInputChange(val);
  };

  const onOk = (date) => {
    Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set.call(inputDateRef.current, date);
    inputDateRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    setSelectedValue(date);
    setPickerVisible(false);
  };

  const onCancel = () => {
    setPickerVisible(false);
  };

  useEffect(() => {
    if (!hasOnePickerOpened) {
      setPickerVisible(false);
    }
  }, [hasOnePickerOpened]);

  const setPickerOpen = () => {
    if (hasOnePickerOpened) {
      dispatch(closeAllPickers());
    }
    setTimeout(() => {
      setPickerVisible(true);
      dispatch(openPicker());
    }, 0);
  };

  return (
    <>
      <div className={className ? className : undefined}>
        {label && !onPagination && (
          <label className={labelCn()} htmlFor={id}>
            {label}
          </label>
        )}
        <div className="relative" ref={inputDateRef}>
          {pickerVisible && (
            <div className={pickerCn}>
              <DatePicker
                onOk={onOk}
                onCancel={onCancel}
                initialDate={selectedValue}
              />
            </div>
          )}
          <input
            autoComplete="off"
            className={inputCn({ onPagination })}
            id={id}
            name={name}
            placeholder={placeholder}
            value={selectedValue}
            disabled={disabled}
            readOnly={true}
            onClick={setPickerOpen}
            ref={(ref) => {
              inputDateRef.current = ref;
            }}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              if (onInputChange) {
                onTextChange(e);
              }
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
            }}
          />
          {appendIcon && (
            <div
              className="absolute top-1/2 transform -translate-y-2/4 right-3 p-1 cursor-pointer"
              onClick={setPickerOpen}
            >
              {appendIcon()}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

InputDate.propTypes = propTypes;

export default InputDate;
