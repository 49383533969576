import { get } from "lodash";
import { httpClient } from "../../httpClient";

export async function fetchMe() {
  try {
    const res = await httpClient.get(`/users/me`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function updateUserMe(body) {
  try {
    const res = await httpClient.put(`/users/me`, {
      user: body,
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function fetchUserMeProfile() {
  try {
    const res = await httpClient.get(`/users/me/profiles`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function addUserImage(body) {
  return httpClient.post("/users/me/image", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function fetchUserImage() {
  try {
    const res = await httpClient.get(`/users/me/image`, {
      responseType: "arraybuffer",
      transformResponse: (data, headers) => {
        return {
          data: {
            buffer: data,
            contentType: headers["content-type"],
          },
        };
      },
    });

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function switchUserMeProfile(profileId) {
  try {
    const res = await httpClient.put(`/users/me/profiles/${profileId}`);

    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}
