import { BADGE_VALUES } from "../constants/index";

export function getBadgeColors(value) {
  switch (value) {
    case BADGE_VALUES.enabled:
    case BADGE_VALUES.online:
    case BADGE_VALUES.completed:
    case BADGE_VALUES.confirmed:
    case BADGE_VALUES.accepted:
    case BADGE_VALUES.executed:
    case BADGE_VALUES.successful:
    case BADGE_VALUES.true:
    case BADGE_VALUES.tenant_administrator:
      return {
        iconDotColor: "bg-success-500",
        iconColor: "text-success-500",
        textColor: "text-success-700",
        backgroundColor: "bg-success-50",
        bordersColor: "border-success-700",
      };
    case BADGE_VALUES.disabled:
    case BADGE_VALUES.false:
    case BADGE_VALUES.currentPlan:
    case BADGE_VALUES.not_tenant_administrator:
      return {
        iconDotColor: "bg-grey-500",
        iconColor: "text-grey-500",
        textColor: "text-grey-700",
        backgroundColor: "bg-grey-100",
        bordersColor: "border-grey-700",
      };
    case BADGE_VALUES.paid:
      return {
        iconDotColor: "bg-cyan-500",
        iconColor: "text-cyan-500",
        textColor: "text-cyan-700",
        backgroundColor: "bg-cyan-50",
        bordersColor: "border-cyan-700",
      };
    case BADGE_VALUES.sent:
      return {
        iconDotColor: "bg-violet-500",
        iconColor: "text-violet-500",
        textColor: "text-violet-700",
        backgroundColor: "bg-violet-50",
        bordersColor: "border-violet-700",
      };
    case BADGE_VALUES.toPay:
    case BADGE_VALUES.pending:
    case BADGE_VALUES.toProcess:
      return {
        iconDotColor: "bg-amber-500",
        iconColor: "text-amber-500",
        textColor: "text-amber-700",
        backgroundColor: "bg-amber-50",
        bordersColor: "border-amber-700",
      };
    case BADGE_VALUES.created:
      return {
        iconDotColor: "bg-lime-500",
        iconColor: "text-lime-500",
        textColor: "text-lime-700",
        backgroundColor: "bg-lime-50",
        bordersColor: "border-lime-700",
      };

    case BADGE_VALUES.general:
      return {
        iconDotColor: "bg-slate-500",
        iconColor: "text-slate-500",
        textColor: "text-slate-800",
        backgroundColor: "bg-slate-100",
        bordersColor: "border-slate-800",
      };
    case BADGE_VALUES.single:
      return {
        iconDotColor: "bg-sky-500",
        iconColor: "text-sky-500",
        textColor: "text-sky-800",
        backgroundColor: "bg-sky-100",
        bordersColor: "border-sky-800",
      };
    case BADGE_VALUES.pages:
      return {
        iconDotColor: "bg-orange-500",
        iconColor: "text-orange-500",
        textColor: "text-orange-800",
        backgroundColor: "bg-orange-100",
        bordersColor: "border-orange-800",
      };
    case BADGE_VALUES.support:
      return {
        iconDotColor: "bg-teal-500",
        iconColor: "text-teal-500",
        textColor: "text-teal-800",
        backgroundColor: "bg-teal-100",
        bordersColor: "border-teal-800",
      };
    case BADGE_VALUES.cover:
      return {
        iconDotColor: "bg-indigo-500",
        iconColor: "text-indigo-500",
        textColor: "text-indigo-800",
        backgroundColor: "bg-indigo-100",
        bordersColor: "border-indigo-800",
      };
    case BADGE_VALUES.frontCover:
      return {
        iconDotColor: "bg-purple-500",
        iconColor: "text-purple-500",
        textColor: "text-purple-800",
        backgroundColor: "bg-purple-100",
        bordersColor: "border-purple-800",
      };
    case BADGE_VALUES.backCover:
      return {
        iconDotColor: "bg-rose-500",
        iconColor: "text-rose-500",
        textColor: "text-rose-800",
        backgroundColor: "bg-rose-100",
        bordersColor: "border-rose-800",
      };
    default:
      return {
        iconDotColor: "bg-grey-500",
        iconColor: "text-grey-500",
        textColor: "text-grey-700",
        backgroundColor: "bg-grey-100",
        bordersColor: "border-grey-700",
      };
  }
}
