import T from "prop-types";
import { useState, useEffect } from "react";
import { Divider } from "../atoms";
import { useTranslation } from "react-i18next";

const propTypes = {
  className: T.string,
  rowData: T.object,
};

function SimplePreview({ className, rowData }) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    setName(rowData?.name);
    setTitle(t("card.attributes"));
    setAttributes(rowData?.attributes);
  }, [rowData, t]);

  return (
    <div className={`flex flex-col p-4 ${className ? className : undefined}`}>
      <div className="flex flex-row">
        <div className="text-xl text-grey-900 font-medium first-letter:uppercase">
          {name}
        </div>
      </div>
      <Divider marginY="my-4" tone="dark" />
      <div className="flex flex-row mb-3">
        <div className="text-base text-grey-900 font-medium first-letter:uppercase">
          {title}
        </div>
      </div>
      <div className="flex flex-col">
        {attributes?.map((attribute, index) => {
          return (
            <div
              className="flex flex-row items-center justify-between my-2"
              key={`attribute-${index}`}
            >
              <div className="text-sm text-grey-900 first-letter:uppercase">
                {attribute?.name}
              </div>
              <div className="text-sm text-grey-500 first-letter:uppercase">
                {attribute?.type}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SimplePreview.propTypes = propTypes;
export default SimplePreview;
