import cntl from "cntl";
import { get, isNumber } from "lodash";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { SELECT_VARIANTS, SIZE, WIDTH } from "../constants";

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isFilter: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  e2eSelect: PropTypes.string,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  formRegistration: PropTypes.object,
  options: PropTypes.array.isRequired,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
  variant: PropTypes.oneOf([SELECT_VARIANTS.picker]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOf([WIDTH.xs, WIDTH.sm, WIDTH.md, WIDTH.lg, WIDTH.xl]),
};

function Select({
  name,
  size,
  label,
  width,
  value,
  onBlur,
  errors,
  options,
  onChange,
  variant,
  isFilter,
  className,
  e2eSelect,
  isDisabled,
  placeholder,
  labelClassName,
  formRegistration = null,
}) {
  const [style, setStyle] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [selectSize, setSelectSize] = useState("");

  const { onChange: onFormChange, onBlur: onFormBlur, name: formName, ...rest } = formRegistration || {};

  const computedName = formName || name;

  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    select-wrapper
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1.5
    text-sm
    font-medium
    text-grey-700
    first-letter:uppercase
    ${labelClassName ? labelClassName : undefined}
  `;

  const selectCn = ({ hasErrors }) => cntl`
    form-select
    py-0
    rounded-lg
    enable:bg-white
    disabled:bg-grey-50
    enabled:text-grey-900
    disabled:text-grey-500
    ${style}
    ${fontStyle}
    ${selectSize}
    ${
      hasErrors
        ? cntl`focus:ring-error-100 focus:border-error-300 border-error-300`
        : cntl`border-grey-300 focus:ring-primary-100 focus:border-primary-300`
    } 
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1.5
    text-sm
    text-error-500
    first-letter:uppercase
    ${!hasErrors && cntl`invisible`}
  `;

  useMemo(() => {
    switch (variant) {
      case SELECT_VARIANTS.picker:
        setStyle("border-none focus:ring-0 font-semibold text-grey-700 shadow-none pl-1.5 pr-7");
        break;
      default:
        setStyle("border focus:ring-4 focus:ring-offset-0 shadow-sm");
        break;
    }
  }, [variant]);

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-sm");
        setSelectSize(`px-3.5 h-9 ${width ? width : `w-full`}`);
        break;
      case SIZE.md:
        setFontStyle("text-base");
        setSelectSize(`px-3.5 h-11 ${width ? width : `w-full`}`);
        break;
      default:
        setFontStyle("text-base");
        setSelectSize(`px-3.5 h-11 ${width ? width : `w-full`}`);
        break;
    }
  }, [size, width]);

  return (
    <div className={wrapperCn()}>
      {!!label && <label className={labelCn()}>{label}</label>}
      <select
        id={computedName}
        name={computedName}
        disabled={isDisabled}
        data-cypress={e2eSelect}
        value={value?.toString()?.toLowerCase()}
        className={selectCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      >
        <>
          {isFilter ? (
            <option key={`option-${placeholder}`} value="" defaultValue>
              {placeholder}
            </option>
          ) : (
            <option key={`option-${placeholder}`} value="" disabled>
              {placeholder}
            </option>
          )}
          {options?.map((option, index) => {
            return (
              <option
                disabled={option?.disabled}
                key={`${option?.value}-${index}`}
                id={`option-${option?.value}-${index}`}
                value={option?.value?.toString()?.toLowerCase()}
              >
                {option?.label}
              </option>
            );
          })}
        </>
      </select>
      {!!errorMessage && (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      )}
    </div>
  );
}

Select.propTypes = propTypes;
export default Select;
