import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import { IconSearch } from "@tabler/icons-react";

const propTypes = {
  name: PropTypes.string,
  cypress: PropTypes.string,
  disabled: PropTypes.bool,
  onOptionClick: PropTypes.func,
  fetcher: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      value: PropTypes.string.isRequired,
      endElement: PropTypes.node,
      startElement: PropTypes.node,
    })
  ),
  label: PropTypes.string,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
};

const ListItem = ({
  label,
  value,
  description,
  endElement,
  startElement,
  searchString,
  ...props
}) => {
  const start = label?.toLowerCase()?.search(searchString?.toLowerCase());

  return (
    <li
      className={`group flex flex-row items-center justify-between hover:bg-grey-50 p-2 rounded relative ${
        value ? `cursor-pointer` : `cursor-auto`
      }`}
      {...props}
    >
      <div className="flex flex-row items-center">
        {startElement && <div className="mr-2">{startElement}</div>}
        <div>
          {start !== -1 ? (
            <p className="text-sm font-medium text-grey-500 group-hover:text-grey-900">
              {label?.substring(0, start)}
              <span className="text-primary-700">{searchString}</span>
              {label?.substring(start + searchString.length)}
            </p>
          ) : (
            <p className="text-sm font-medium text-grey-500 group-hover:text-grey-900">
              {label}
            </p>
          )}
          <p className="text-sm font-medium text-grey-500">{description}</p>
        </div>
      </div>
      {endElement}
    </li>
  );
};

function FormAutocomplete({
  name,
  cypress,
  disabled,
  onOptionClick,
  label,
  fetcher,
  options,
  errors,
  value,
  setValue,
  getValues,
  formRegistration,
  className,
}) {
  const optionRef = useRef(null);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [searchString, setSearchString] = useState(undefined);
  const [autocompleteInfo, setAutocompleteInfo] = useState({
    height: null,
    position: "top",
  });
  const fetcherRef = useRef(fetcher);
  fetcherRef.current = fetcher;

  // TODO Old code, for some reason it's not working
  const handleAutocompleteResize = useCallback(() => {
    const el = optionRef?.current;
    const autocompleteHeight = el?.offsetHeight;
    const domRect = el?.getBoundingClientRect();
    const distanceFromTop = domRect?.top;
    const distanceToBottom = window.innerHeight - domRect?.bottom;

    if (autocompleteHeight < distanceToBottom) {
      setAutocompleteInfo({
        height: null,
        position: "top",
      });
      return;
    }

    if (
      autocompleteHeight > distanceToBottom &&
      autocompleteHeight < distanceFromTop
    ) {
      setAutocompleteInfo({
        height: null,
        position: "bottom",
      });
      return;
    }
    setAutocompleteInfo({
      height: distanceToBottom - 24,
      position: "top",
    });
  }, []);

  useEffect(() => {
    if (autocompleteOpen) {
      handleAutocompleteResize();
    }
  }, [autocompleteOpen, handleAutocompleteResize]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionRef.current && !optionRef.current.contains(e.target)) {
        setAutocompleteOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("resize", handleAutocompleteResize, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("resize", handleAutocompleteResize, true);
    };
  }, [handleAutocompleteResize]);

  const onClick = (option) => {
    setAutocompleteOpen(false);
    onOptionClick(option);
    setSearchString(undefined);

    setValue?.(name, option.value);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      await fetcherRef.current?.(searchString);
      setAutocompleteOpen(true);
    };

    if (searchString) {
      fetchOptions();
    } else {
      setAutocompleteOpen(false);
    }
  }, [searchString]);

  return (
    <div className={`relative ${className ? className : undefined}`}>
      <Input
        className="mb-4"
        errors={errors}
        formRegistration={formRegistration}
        onClick={() => {
          if (options?.length && searchString) {
            setAutocompleteOpen(true);
          }
        }}
        disabled={disabled}
        name={name}
        label={label}
        onChange={async (e) => {
          setSearchString(e.target.value);
        }}
        iconLeft={<IconSearch className="w-5 h-5 text-grey-500" />}
        value={
          searchString ??
          options?.find((option) => option.value === getValues?.(name))
            ?.label ??
          options?.find((option) => option.value === value)?.label ??
          ""
        }
      />
      {autocompleteOpen && options?.length ? (
        <div
          className="absolute drop-shadow-md bg-white rounded p-2 w-full max-h-80 overflow-y-auto"
          ref={optionRef}
          style={{
            height: autocompleteInfo.height || null,
            top: autocompleteInfo.position === "top" ? "3rem" : null,
            bottom: autocompleteInfo.position === "bottom" ? "3rem" : null,
          }}
          data-cypress={cypress}
        >
          <ol>
            {options.map((option, index) => {
              return (
                <ListItem
                  searchString={searchString}
                  tabIndex={index}
                  key={`form-autocomplete-${name}-${index}`}
                  onClick={() => {
                    if (option?.value) {
                      onClick(option);
                    }
                  }}
                  label={option?.label}
                  description={option?.description}
                  startElement={option?.startElement}
                  endElement={option?.endElement}
                  value={option?.value}
                />
              );
            })}
          </ol>
        </div>
      ) : null}
    </div>
  );
}

FormAutocomplete.propTypes = propTypes;
export default FormAutocomplete;
