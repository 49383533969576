import cntl from "cntl";
import PropTypes from "prop-types";
import { SpinnerXs } from "@dbox/components";
import { SIZE, POSITIONS } from "../constants/index";
import { useState, useMemo, cloneElement } from "react";

const propTypes = {
  icon: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
  buttonRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
};

function Button({
  size,
  icon,
  name,
  label,
  onClick,
  disabled,
  buttonRef,
  iconColor,
  className,
  isLoading,
  buttonProps,
  iconPosition,
  type = "button",
  ...rest
}) {
  const [iconSize, setIconSize] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [buttonSize, setButtonSize] = useState("");

  const buttonCn = () => cntl`
    flex
    border
    flex-row
    box-border
    rounded-lg
    items-center
    justify-center
    whitespace-nowrap
    ${fontStyle}
    ${buttonSize}
    ${className ? className : undefined}
    ${isLoading ? cntl`pointer-events-none` : cntl`pointer-events-auto`}
  `;

  const iconCn = () => cntl`
    flex
    shrink-0
    items-center
    ${label?.length ? `gap-x-2` : `gap-0`}
    ${iconPosition === POSITIONS.left ? `flex-row` : `flex-row-reverse`}
  `;

  const labelCn = () => cntl` 
    first-letter:uppercase
  `;

  useMemo(() => {
    if (label?.length) {
      switch (size) {
        case SIZE.sm:
          setIconSize("w-5 h-5");
          setButtonSize("px-3.5 h-9");
          setFontStyle("text-sm font-semibold tracking-wide");
          break;
        case SIZE.md:
          setIconSize("w-5 h-5");
          setButtonSize("px-4 h-10");
          setFontStyle("text-sm font-semibold tracking-wide");
          break;
        case SIZE.lg:
          setIconSize("w-5 h-5");
          setButtonSize("px-[1.125rem] h-11");
          setFontStyle("text-base font-semibold");
          break;
        case SIZE.xl:
          setIconSize("w-5 h-5");
          setButtonSize("px-5 h-12");
          setFontStyle("text-base font-semibold");
          break;
        case SIZE["2xl"]:
          setIconSize("w-6 h-6");
          setButtonSize("px-7 h-[3.75rem]");
          setFontStyle("text-lg font-semibold");
          break;
        default:
          setIconSize("w-5 h-5");
          setButtonSize("px-[1.125rem] h-11");
          setFontStyle("text-base font-semibold");
          break;
      }
    } else {
      switch (size) {
        case SIZE.sm:
          setIconSize("w-5 h-5");
          setButtonSize("w-9 h-9");
          break;
        case SIZE.md:
          setIconSize("w-5 h-5");
          setButtonSize("w-10 h-10");
          break;
        case SIZE.lg:
          setIconSize("w-5 h-5");
          setButtonSize("w-11 h-11");
          break;
        case SIZE.xl:
          setIconSize("w-5 h-5");
          setButtonSize("w-12 h-12");
          break;
        case SIZE["2xl"]:
          setIconSize("w-6 h-6");
          setButtonSize("w-[3.75rem] h-[3.75rem]");
          break;
        default:
          setIconSize("w-5 h-5");
          setButtonSize("w-10 h-10");
          break;
      }
    }
  }, [size, label?.length]);

  const Loader = () => {
    if (size !== SIZE.xl && size !== SIZE["2xl"]) {
      return <SpinnerXs className="animate-spin" />;
    }
  };

  return (
    <button
      name={name}
      type={type}
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      className={buttonCn()}
      {...buttonProps}
      {...rest}
    >
      {!isLoading ? (
        <div className={iconCn()}>
          {icon &&
            cloneElement(icon, {
              className: `${iconSize} ${iconColor}`,
            })}
          <span className={labelCn()}>{label}</span>
        </div>
      ) : (
        <Loader />
      )}
    </button>
  );
}

Button.propTypes = propTypes;
export default Button;
