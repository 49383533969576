import { Fragment, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { STATUS_VALUES } from "../constants";
import { Divider } from "../atoms";
import { CustomerInfoViewBody, CustomerInfoViewHeader } from "../molecules";
import { IconMapPin, IconUserCircle } from "@tabler/icons-react";

const propTypes = {
  customer: PropTypes.object,
  variant: PropTypes.oneOf([STATUS_VALUES.ENABLED, STATUS_VALUES.DISABLED]),
};

function QuoteCustomerInfoView({ customer, variant = STATUS_VALUES.ENABLED }) {
  const { t } = useTranslation();
  const [baseStyle, setBaseStyle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useMemo(() => {
    switch (variant) {
      case STATUS_VALUES.DISABLED:
        setIsDisabled(true);
        setBaseStyle(undefined);
        break;
      default:
        setIsDisabled(false);
        setBaseStyle("shadow rounded-2xl border border-grey-300");
        break;
    }
  }, [variant]);

  return (
    <div
      className={`flex flex-row lg:flex-col justify-between p-4 bg-white ${baseStyle}`}
    >
      <CustomerInfoViewHeader
        user={customer}
        profileIcon={
          <IconUserCircle className="h-10 w-10 mr-4 text-grey-500" />
        }
      />
      {!isDisabled && (
        <>
          <Divider marginY="my-4" className="hidden lg:flex" />
          <div className="flex flex-col gap-y-4">
            {customer?.addresses?.map((address, index) => {
              return (
                <Fragment key={`customer-address-${index}`}>
                  <CustomerInfoViewBody
                    icon={
                      <IconMapPin className="text-teal-500 w-5 h-5" />
                    }
                    addressType={address?.type}
                    address={`
                      ${address?.street}
                       ${t("data.general.comma")}
                       ${address?.postcode}
                       ${t("data.general.comma")}
                       ${address?.city}
                       ${t("data.general.comma")}
                       ${address?.region}
                       ${t("data.general.comma")}
                       ${address?.country}
                       `}
                  />
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

QuoteCustomerInfoView.propTypes = propTypes;
export default QuoteCustomerInfoView;
