import { IconAlertTriangle, IconCircleCheck } from "@tabler/icons-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextLink } from "../atoms";
import BadgeText from "../atoms/BadgeText";

const propTypes = {
  title: PropTypes.string,
  text_first_line: PropTypes.string,
  text_second_line: PropTypes.string,
};

function ContentTransferSelectionComplex({
  title,
  status,
  isSelected,
  text_first_line,
  text_second_line,
}) {
  const { t } = useTranslation();

  return (
    <>
      {title?.length && (
        <div className="flex items-center justify-between flex-wrap">
          <div
            className={`text-xl font-medium ${
              isSelected ? "text-primary-700" : "text-grey-500"
            }`}
          >
            {title}
          </div>
          {isSelected &&
            (status ? (
              <div className="pl-1">
                <IconCircleCheck className="w-5 h-5 text-primary-900" />
              </div>
            ) : (
              <div className="pl-1">
                <IconAlertTriangle className="w-5 h-5 text-amber-500" />
              </div>
            ))}
        </div>
      )}
      <div className="flex flex-col gap-y-1 my-2">
        {text_first_line?.length && (
          <div className="text-sm font-medium text-grey-500">
            {text_first_line}
          </div>
        )}
        {text_second_line?.length && (
          <div className="text-sm font-medium text-grey-500">
            {text_second_line}
          </div>
        )}
      </div>
      <div className="flex items-center">
        <BadgeText label={status} />
        {!status && isSelected && (
          <div className="flex items-center justify-between w-full ml-2">
            <div className="text-sm font-medium text-warning-600">
              {t("selection_complex.user_not_active")}
            </div>
            <TextLink
              iconPosition="right"
              label={t("selection_complex.active")}
              onAction={() => null}
            />
          </div>
        )}
      </div>
    </>
  );
}

ContentTransferSelectionComplex.propTypes = propTypes;
export default ContentTransferSelectionComplex;
