import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";
import { IconCircleCheck, IconUser } from "@tabler/icons-react";

const propTypes = {
  reference: PropTypes.object,
  letters: PropTypes.string,
  className: PropTypes.string,
  onAvatarClick: PropTypes.func,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  picture: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

function AvatarProfilePhoto({
  size,
  picture,
  letters,
  className,
  reference,
  onAvatarClick,
}) {
  const [fontSize, setFontSize] = useState("");
  const [iconSize, setIconSize] = useState("");
  const [avatarSize, setAvatarSize] = useState("");
  const [wrapperSize, setWrapperSize] = useState("");
  const [childPosition, setChildPosition] = useState("");

  const avatarWrapperCn = () => cntl`
    flex
    relative
    flex-row
    shadow-lg
    rounded-full
    items-center
    bg-grey-50
    cursor-pointer
    justify-center
    overflow-visible
    outline
    outline-4
    outline-white
    ${wrapperSize}
    ${!!!picture && "bg-grey-50"}
    ${className ? className : undefined}
  `;

  const pictureCn = () => cntl`
    rounded-full
    overflow-hidden
  `;

  const lettersCn = () => cntl`
    text-center
    font-medium
    text-grey-600
    ${fontSize}
  `;

  const userIconCn = () => cntl`
    text-grey-600
    ${avatarSize}
  `;

  const childrenCn = () => cntl`
    absolute
    ${childPosition}
  `;

  const iconCn = () => cntl`
    text-primary-600
    ${iconSize}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setIconSize("w-5 h-5");
        setFontSize("text-4xl");
        setAvatarSize("w-12 h-12");
        setChildPosition("right-0 bottom-0");
        setWrapperSize("w-[4.5rem] h-[4.5rem]");
        break;
      case SIZE.md:
        setIconSize("w-6 h-6");
        setFontSize("text-4xl");
        setAvatarSize("w-12 h-12");
        setWrapperSize("w-24 h-24");
        setChildPosition("right-0 bottom-0");
        break;
      case SIZE.lg:
        setIconSize("w-8 h-8");
        setFontSize("text-6xl");
        setAvatarSize("w-20 h-20");
        setWrapperSize("w-40 h-40");
        setChildPosition("right-2.5 bottom-0");
        break;
      default:
        setIconSize("w-5 h-5");
        setFontSize("text-4xl");
        setAvatarSize("w-12 h-12");
        setChildPosition("right-0 bottom-0");
        setWrapperSize("w-[4.5rem] h-[4.5rem]");
        break;
    }
  }, [size]);

  return (
    <div
      reference={reference}
      onClick={onAvatarClick}
      id="avatar-profile-photo"
      className={avatarWrapperCn()}
    >
      {picture?.buffer ? (
        <LazyLoadImage
          alt="image-avatar"
          className={pictureCn()}
          src={`data:image/png;base64,${arrayBufferToBase64(
            picture?.buffer ?? null
          )}`}
        />
      ) : letters ? (
        <div className={lettersCn()}>{letters}</div>
      ) : (
        <IconUser className={userIconCn()} />
      )}
      <div className={childrenCn()}>
        <IconCircleCheck className={iconCn()} />
      </div>
    </div>
  );
}

AvatarProfilePhoto.propTypes = propTypes;
export default AvatarProfilePhoto;
