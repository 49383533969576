import { useState, useRef, useEffect } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { get, throttle } from "lodash";
import { PickerPositions } from "../constants";
import { DatePicker } from "../molecules";

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  formRegistration: PropTypes.object,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onPagination: PropTypes.bool,
  isFilter: PropTypes.bool,
  placeholder: PropTypes.string,
};

function FormInputDate({
  name = "",
  label = "",
  id,
  value,
  disabled,
  onChange,
  formRegistration = null,
  errors,
  onInputChange,
  className,
  step,
  onBlur,
  appendIcon,
  onPagination = false,
  isFilter = false,
  placeholder,
  datatype,
  showErrorMessage = true,
  height,
}) {
  const [pickerVisible, setPickerVisible] = useState(false);
  const inputDateRef = useRef();

  const [pickerInfo, setPickerInfo] = useState({
    position: "",
  });

  useEffect(() => {
    const onResize = () => {
      const rect = inputDateRef.current?.getBoundingClientRect();
      const clientHeight = document.documentElement?.clientHeight;
      if (clientHeight - rect?.bottom > 322) {
        setPickerInfo({ position: PickerPositions.BOTTOM });
        return;
      }
      if (rect?.top > 322) {
        setPickerInfo({ position: PickerPositions.TOP });
        return;
      }
      console.error("Not enough space to show the picker");
      setPickerVisible(false);
      setPickerInfo({ position: null });
    };

    onResize();

    window.addEventListener("resize", throttle(onResize, 500));
    return () => {
      window.removeEventListener("resize", throttle(onResize, 500));
    };
  }, []);

  const inputCn = ({ hasErrors, onPagination }) => cntl`
    form-input
    py-0
    border
    enable:bg-white
    disabled:bg-grey-50
    shadow-sm
    rounded-lg
    focus:ring-4
    enabled:text-grey-900
    disabled:text-grey-500
    focus:ring-offset-0
    w-full
    text-base
    border-grey-300 focus:ring-primary-100 focus:border-primary-300
    ${height ? height : cntl`h-11`}
    ${!onPagination ? "px-4 rounded-lg" : "px-1 shadow-inner"}
    ${
      hasErrors
        ? cntl`focus:ring-error-100 focus:border-error-300 border-error-300`
        : cntl`border-grey-300 focus:ring-primary-100 focus:border-primary-300`
    }
  `;

  const labelCn = () => cntl`
    mb-1.5
    text-sm
    font-medium
    text-grey-700
    first-letter:uppercase
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-xs
    font-medium
    text-error-600
    ${!hasErrors && cntl`invisible`}
  `;

  const pickerCn = cntl`
    absolute
    z-10
    left-1/2
    -translate-x-2/4
    ${pickerInfo.position === PickerPositions.TOP && cntl`-top-[22rem]`}
    ${pickerInfo.position === PickerPositions.BOTTOM && cntl`top-16`}
  `;

  const {
    onChange: onFormChange,
    onBlur: onFormBlur,
    name: formName,
    ref: registerRef,
  } = formRegistration || {};

  const computedName = name || formName;

  const errorMessage = get(errors, `[${computedName}].message`, "");

  const onTextChange = (event) => {
    const val = event.target.value;
    onInputChange(val);
  };

  const onOk = (date) => {
    Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set.call(inputDateRef.current, date);
    inputDateRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    setPickerVisible(false);
  };

  const onCancel = () => {
    setPickerVisible(false);
  };

  const computedId = id || computedName;

  const hasErrorMessage = onPagination || isFilter;

  return (
    <>
      <div className={className || ""}>
        {label && !onPagination && (
          <label className={labelCn(isFilter)} htmlFor={computedId}>
            {label}
          </label>
        )}
        <div className="relative" ref={inputDateRef}>
          {pickerVisible && (
            <div className={pickerCn}>
              <DatePicker onOk={onOk} onCancel={onCancel} initialDate={value} />
            </div>
          )}
          <input
            autoComplete="off"
            type="date"
            step={step}
            className={inputCn({ hasErrors: !!errorMessage, onPagination })}
            id={computedId}
            name={computedName}
            placeholder={!!placeholder ? placeholder : label}
            value={value}
            readOnly={true}
            ref={(ref) => {
              inputDateRef.current = ref;
              if (registerRef) {
                registerRef(ref);
              }
            }}
            datatype={datatype}
            disabled={disabled}
            aria-invalid={errorMessage ? "true" : "false"}
            onChange={(e) => {
              if (onFormChange) {
                onFormChange(e);
              }
              if (onChange) {
                onChange(e);
              }
              if (onInputChange) {
                onTextChange(e);
              }
            }}
            onClick={() => setPickerVisible(true)}
            onBlur={(e) => {
              if (onFormBlur) {
                onFormBlur(e);
              }
              if (onBlur) {
                onBlur(e);
              }
            }}
          />
          {appendIcon && (
            <div
              className="absolute top-1/2 transform -translate-y-2/4 right-3 cursor-pointer"
              onClick={() => {
                setPickerVisible(true);
              }}
            >
              {appendIcon()}
            </div>
          )}
        </div>
        {!!errorMessage && (
          <div
            className={errorCn({
              hasErrors: !!errorMessage,
            })}
            data-cypress="form-error-msg"
          >
            {errorMessage ? errorMessage : "Error message"}
          </div>
        )}
      </div>
    </>
  );
}

FormInputDate.propTypes = propTypes;

export default FormInputDate;
