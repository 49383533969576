import PropTypes from "prop-types";

const propTypes = {
  icon: PropTypes.node,
  address: PropTypes.string,
  addressType: PropTypes.string,
};

function CustomerInfoViewBody({ icon, addressType, address }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <>{icon}</>
        <div className="text-sm text-teal-500 font-medium ml-2 first-letter:capitalize">
          {addressType}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="mt-1.5 text-sm text-grey-500 font-normal first-letter:capitalize">
          {address}
        </div>
      </div>
    </div>
  );
}

CustomerInfoViewBody.propTypes = propTypes;
export default CustomerInfoViewBody;
