import { IconHome } from "@tabler/icons-react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

function BreadcrumbGrey({ link, label, isActive, isHomePage = false }) {
  const baseLiCn = () => cntl`
    text-sm
    list-none
    capitalize
    rounded-md
    font-medium
    inline-flex
  `;

  const iconCn = () => cntl`
    w-5
    h-5
  `;

  const active = (textColor) => cntl`
    ${
      isActive
        ? cntl`text-grey-700 hover:text-grey-700 bg-grey-50`
        : cntl`hover:text-grey-700 hover:bg-grey-50 ${
            textColor ? textColor : cntl`text-grey-900`
          }`
    }
  `;

  const iconLiCn = () => cntl`
    p-1
    ${active()}
    ${baseLiCn()}
  `;

  const labelLiCn = () => cntl`
    py-1
    px-4
    ${baseLiCn()}
    ${active(`text-grey-500`)}
  `;

  const renderBreadcrumb = () => {
    if (isHomePage) {
      return (
        <li className={iconLiCn()}>
          <Link to={link}>
            <IconHome className={iconCn()} />
          </Link>
        </li>
      );
    } else {
      return (
        <li className={labelLiCn()}>
          <Link
            to={link}
            className={isActive ? "cursor-auto" : "cursor-pointer"}
          >
            {label}
          </Link>
        </li>
      );
    }
  };

  return renderBreadcrumb();
}

BreadcrumbGrey.propTypes = propTypes;
export default BreadcrumbGrey;
