import PropTypes from "prop-types";
import IconWithLink from "./IconWithLink";

const propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  isGoBack: PropTypes.bool,
  className: PropTypes.string,
};

function ProcessDetailsBox({ className, title, icon, text, isGoBack }) {
  return (
    <div className={`flex flex-col ${className ? className : undefined}`}>
      <div className="first-letter:capitalize font-medium text-xl mb-2">
        {title}
      </div>
      <IconWithLink isGoBack={isGoBack} icon={icon} text={text} />
    </div>
  );
}

ProcessDetailsBox.propTypes = propTypes;
export default ProcessDetailsBox;
