import cntl from "cntl";
import PropTypes from "prop-types";
import { DOTS } from "../constants";
import { useCallback } from "react";
import { Trans } from "react-i18next";
import { PaginationButtonGroupBase, PaginationNumberBase } from "../atoms";

const propTypes = {
  range: PropTypes.array,
  onPageClick: PropTypes.func,
  isButtonGroup: PropTypes.bool,
  isDetailsVisible: PropTypes.bool,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

const PaginationCounter = ({
  range,
  pagesInfo,
  onPageClick,
  isDetailsVisible,
  isButtonGroup = false,
}) => {
  const counterCn = () => cntl`
    flex
    text-sm
    flex-row
  `;

  const buttonsCn = () => cntl`
    flex
    flex-row
    gap-x-1
    ${!isButtonGroup ? cntl`gap-x-1` : cntl`gap-x-0`}
  `;

  const pageValueCn = () => cntl`
    font-medium
  `;

  const isEqualToIndex = (index) => {
    return (
      range?.findIndex((number) => number === pagesInfo?.currentPage + 1) ===
      index
    );
  };

  const renderPagination = useCallback(() => {
    return range?.map((pageValue, index) => {
      if (pageValue === DOTS) {
        if (!isButtonGroup) {
          return (
            <PaginationNumberBase
              id=""
              value="..."
              isDisabled={true}
              key={`page-button-dots-${index}`}
            />
          );
        } else {
          return (
            <PaginationButtonGroupBase
              label="..."
              isDisabled={true}
              key={`page-button-dots-${index}`}
            />
          );
        }
      } else {
        if (!isButtonGroup) {
          return (
            <PaginationNumberBase
              value={pageValue?.toString()}
              isActive={isEqualToIndex(index)}
              isDisabled={isEqualToIndex(index)}
              key={`page-button-number-${index}`}
              onClick={(value) => onPageClick(value)}
            />
          );
        } else {
          return (
            <PaginationButtonGroupBase
              label={pageValue?.toString()}
              isActive={isEqualToIndex(index)}
              isDisabled={isEqualToIndex(index)}
              key={`page-button-number-${index}`}
              onClick={(value) => onPageClick(value)}
            />
          );
        }
      }
    });
  }, [range, onPageClick, isEqualToIndex]);

  return (
    <>
      {isDetailsVisible ? (
        <div className={counterCn()}>
          <Trans
            i18nKey="pagination.counter_info"
            values={{
              totalPage: pagesInfo?.lastPage,
              currentPage: pagesInfo?.currentPage,
            }}
            components={{
              p: <span className={pageValueCn()} />,
            }}
          />
        </div>
      ) : (
        <div className={buttonsCn()}>{renderPagination()}</div>
      )}
    </>
  );
};

PaginationCounter.propTypes = propTypes;
export default PaginationCounter;
