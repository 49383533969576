import T from "prop-types";
import { dimensions } from "../constants";
import {
  QuoteCardHeader,
  QuoteCardHeaderSmall,
  QuoteCardTable,
} from "../molecules";

const propTypes = {
  onClick: T.func,
  quote: T.object,
  order: T.object,
  onDelete: T.func,
  className: T.string,
  expiringDate: T.object,
  size: T.oneOf([dimensions.SMALL, dimensions.DEFAULT, dimensions.LARGE]),
};

function QuoteCard({
  quote,
  order,
  onClick,
  onDelete,
  className,
  expiringDate,
  size = "default",
}) {
  return (
    <div
      className={`hover:cursor-pointer ${className ? className : undefined}`}
      onClick={onClick}
    >
      {size === dimensions.SMALL && (
        <div className="h-60 border border-solid border-grey-300 shadow-sm rounded-lg p-4">
          <QuoteCardHeaderSmall
            quote={quote || {}}
            order={order || {}}
          />
        </div>
      )}
      {size === dimensions.DEFAULT && (
        <div className="flex flex-col h-96 border border-solid border-grey-300 shadow-sm rounded-lg p-4 overflow-hidden">
          <QuoteCardHeader
            onClick={onDelete}
            quote={quote || {}}
            expirationDate={expiringDate}
          />
          <QuoteCardTable data={quote?.quote_items || []} />
        </div>
      )}
    </div>
  );
}

QuoteCard.propTypes = propTypes;
export default QuoteCard;
