function BackgroundMinumumResolutionCromie() {
  return (
    <svg
      width="211"
      height="264"
      viewBox="0 0 211 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.004 239.533L143.967 242.451L148.091 244.936L148.128 242.017L144.004 239.533Z"
        fill="#1B191A"
      />
      <path
        d="M148.124 242.017L148.087 244.936L157.375 239.759L157.412 236.84L148.124 242.017Z"
        fill="#161414"
      />
      <path
        d="M144.001 239.532L148.125 242.017L157.413 236.84L153.289 234.355L144.001 239.532Z"
        fill="#171515"
      />
      <path
        d="M83.6057 205.2L83.5688 208.118L87.6929 210.603L87.7344 207.68L83.6057 205.2"
        fill="#1B191A"
      />
      <path
        d="M87.737 207.68L87.6955 210.603L96.9875 205.431L97.0244 202.507L87.737 207.68"
        fill="#161414"
      />
      <path
        d="M83.6094 205.2L87.7381 207.68L97.0255 202.508L92.9014 200.028L83.6094 205.2"
        fill="#171515"
      />
      <path
        d="M196.251 208.396L196.214 211.315L200.338 213.799L200.375 210.881L196.251 208.396Z"
        fill="#1B191A"
      />
      <path
        d="M200.371 210.881L200.334 213.799L209.621 208.622L209.658 205.703L200.371 210.881Z"
        fill="#161414"
      />
      <path
        d="M196.248 208.396L200.372 210.88L209.659 205.703L205.535 203.223L196.248 208.396Z"
        fill="#171515"
      />
      <path
        d="M148.22 242.286L209.667 206.379L209.662 197.036L148.216 232.943L148.22 242.286Z"
        fill="#DCCFCF"
      />
      <path
        d="M148.21 232.943L209.656 197.036L145.435 159.97L83.9883 195.877L148.21 232.943Z"
        fill="#333139"
      />
      <path
        d="M83.9883 195.877L83.9929 205.22L148.215 242.286L148.21 232.943L83.9883 195.877Z"
        fill="#F4ECED"
      />
      <path
        d="M148.216 231.687L209.662 195.78L209.694 178.835L148.248 214.742L148.216 231.687Z"
        fill="#DCCFCF"
      />
      <path
        d="M148.248 214.741L209.695 178.834L136.924 146.936L84.0221 177.68L148.248 214.741Z"
        fill="#333139"
      />
      <path
        d="M84.016 177.681L83.9883 194.62L148.21 231.687L148.242 214.742L84.016 177.681Z"
        fill="#F4ECED"
      />
      <path
        d="M148.216 213.282L209.662 177.37L209.694 160.426L148.248 196.337L148.216 213.282Z"
        fill="#DCCFCF"
      />
      <path
        d="M148.248 196.337L209.695 160.426L136.924 128.532L84.0221 159.271L148.248 196.337Z"
        fill="#333139"
      />
      <path
        d="M84.016 159.271L83.9883 176.215L148.21 213.282L148.242 196.337L84.016 159.271Z"
        fill="#F4ECED"
      />
      <path
        d="M148.216 194.874L209.662 158.962L209.694 142.022L148.248 177.93L148.216 194.874Z"
        fill="#DCCFCF"
      />
      <path
        d="M148.248 177.929L209.695 142.022L136.924 110.124L84.0221 140.868L148.248 177.929Z"
        fill="#333139"
      />
      <path
        d="M84.016 140.868L83.9883 157.808L148.21 194.874L148.242 177.93L84.016 140.868Z"
        fill="#F4ECED"
      />
      <path
        d="M148.581 176.104L210.032 140.197L210.194 103.874L148.747 139.786L148.581 176.104"
        fill="#DCCFCF"
      />
      <path
        d="M148.751 139.786L210.198 103.875L145.976 66.8083L84.5295 102.72L148.751 139.786Z"
        fill="#333139"
      />
      <path
        d="M84.3671 139.043L148.584 176.104L148.751 139.786L84.5286 102.72L84.3671 139.043"
        fill="#F4ECED"
      />
      <path
        d="M163.757 78.326L105.058 54.5511L104.892 90.8692L155.204 119.909L163.757 78.326Z"
        fill="#333139"
      />
      <path
        d="M129.037 126.805L148.586 138.179L148.752 101.857L129.203 90.4864L129.037 126.805Z"
        fill="#F4ECED"
      />
      <path
        d="M148.581 138.179L210.032 102.268L210.194 65.9449L148.747 101.857L148.581 138.179"
        fill="#DCCFCF"
      />
      <path
        d="M155.123 98.13L156.18 228.287L209.665 206.378L210.196 65.9452L155.123 98.13Z"
        fill="#DCCFCF"
      />
      <path
        d="M156.183 228.286L154.737 229.118V17.4525L156.178 16.6212L156.183 228.286Z"
        fill="#333139"
      />
      <path
        d="M103.715 193.516C103.715 194.251 103.974 195.068 104.394 195.798C104.814 196.523 105.392 197.16 106.034 197.53L123.477 207.602C124.303 208.073 125.033 207.995 125.444 207.473C125.666 207.186 125.795 206.775 125.795 206.258C125.795 205.524 125.532 204.702 125.112 203.972C124.696 203.247 124.114 202.61 123.477 202.245L106.034 192.172L105.022 191.858C104.251 191.849 103.715 192.459 103.715 193.516"
        fill="#232124"
      />
      <path
        d="M104.676 193.073C104.676 193.807 104.934 194.629 105.355 195.354C105.775 196.079 106.352 196.721 106.99 197.086L124.433 207.154L125.444 207.472C125.666 207.186 125.795 206.775 125.795 206.258C125.795 205.524 125.532 204.701 125.112 203.972C124.696 203.247 124.114 202.609 123.477 202.244L106.034 192.172L105.022 191.858C104.8 192.144 104.676 192.555 104.676 193.073"
        fill="#272529"
      />
      <path
        d="M103.715 175.186C103.715 175.92 103.974 176.742 104.394 177.467C104.814 178.193 105.392 178.83 106.034 179.199L123.477 189.272C124.303 189.747 125.033 189.664 125.444 189.142C125.666 188.861 125.795 188.45 125.795 187.932C125.795 187.198 125.532 186.371 125.112 185.646C124.696 184.921 124.114 184.279 123.477 183.915L106.034 173.847L105.022 173.528C104.251 173.519 103.715 174.128 103.715 175.186"
        fill="#232124"
      />
      <path
        d="M104.676 174.743C104.676 175.477 104.934 176.299 105.355 177.029C105.775 177.754 106.352 178.391 106.99 178.756L124.433 188.828L125.444 189.142C125.666 188.861 125.795 188.45 125.795 187.932C125.795 187.198 125.532 186.371 125.112 185.646C124.696 184.921 124.114 184.279 123.477 183.914L106.034 173.847L105.022 173.528C104.8 173.814 104.676 174.225 104.676 174.743"
        fill="#272529"
      />
      <path
        d="M103.715 157.3C103.715 158.034 103.974 158.851 104.394 159.581C104.814 160.306 105.392 160.943 106.034 161.313L123.477 171.385C124.303 171.856 125.033 171.778 125.444 171.256C125.666 170.974 125.795 170.563 125.795 170.046C125.795 169.312 125.532 168.485 125.112 167.76C124.696 167.035 124.114 166.393 123.477 166.028L106.034 155.96L105.022 155.642C104.251 155.632 103.715 156.242 103.715 157.3"
        fill="#232124"
      />
      <path
        d="M104.676 156.856C104.676 157.59 104.934 158.417 105.355 159.142C105.775 159.867 106.352 160.504 106.99 160.869L124.433 170.941L125.444 171.256C125.666 170.974 125.795 170.563 125.795 170.046C125.795 169.311 125.532 168.485 125.112 167.76C124.696 167.034 124.114 166.393 123.477 166.028L106.034 155.96L105.022 155.641C104.8 155.928 104.676 156.338 104.676 156.856"
        fill="#272529"
      />
      <path
        d="M145.975 27.2851L103.593 52.1176L156.796 72.7151L129.119 88.9253L148.751 100.263L176.368 84.0114L210.197 64.3514L145.975 27.2851"
        fill="#333139"
      />
      <path
        d="M148.755 103.243L210.201 67.3314L210.196 57.9886L148.75 93.9004L148.755 103.243Z"
        fill="#D0C3C4"
      />
      <path
        d="M148.751 93.8989L210.198 57.9872L145.976 20.9208L84.5295 56.8326L148.751 93.8989Z"
        fill="#333139"
      />
      <path
        d="M84.5295 56.834L84.5341 66.1768L148.756 103.243L148.751 93.9004L84.5295 56.834Z"
        fill="#DCCFCF"
      />
      <path
        d="M148.755 91.6917L210.201 55.7799L210.196 40.9505L148.75 76.8623L148.755 91.6917Z"
        fill="#D0C3C4"
      />
      <path
        d="M148.751 76.8628L210.198 40.951L145.976 3.88468L84.5295 39.7965L148.751 76.8628Z"
        fill="#F9F4F5"
      />
      <path
        d="M84.5295 39.796L84.5341 54.6253L148.756 91.6917L148.751 76.8623L84.5295 39.796Z"
        fill="#DCCFCF"
      />
      <path
        d="M114.445 53.0882L116.61 54.3444L148.486 72.8545L201.153 42.2676L203.319 41.0115L169.277 21.2452L114.445 53.0882"
        fill="#D0C3C4"
      />
      <path
        d="M116.609 54.3444L148.485 72.8545L201.152 42.2676L169.276 23.7621L116.609 54.3444"
        fill="#333139"
      />
      <path
        d="M99.5805 48.5798L84.5295 39.8974L84.5341 35.718L99.5805 44.3956V48.5798"
        fill="#D0C3C4"
      />
      <path
        d="M99.5759 44.3955L84.5295 35.7178L146.484 1.11441e-05L161.53 8.67778L99.5759 44.3955Z"
        fill="#F9F4F5"
      />
      <path
        d="M161.529 8.67792V12.8575L99.575 48.5798V44.3956L161.529 8.67792Z"
        fill="#DCCFCF"
      />
      <path
        d="M176.406 219.032L174.61 217.988L174.559 185.938L176.36 186.986L176.406 219.032"
        fill="#DCCFCF"
      />
      <path
        d="M176.36 186.986L174.559 185.938L204.993 168.37L206.79 169.418L176.36 186.986Z"
        fill="white"
      />
      <path
        d="M206.788 169.418L206.839 201.464L176.405 219.032L176.358 186.986L206.788 169.418Z"
        fill="#F9F4F5"
      />
      <path
        d="M176.406 181.102L174.61 180.059L174.559 163.364L176.36 164.412L176.406 181.102"
        fill="#DCCFCF"
      />
      <path
        d="M176.36 164.411L174.559 163.363L204.993 145.795L206.79 146.839L176.36 164.411"
        fill="white"
      />
      <path
        d="M206.788 146.839L206.839 163.534L176.405 181.102L176.358 164.412L206.788 146.839Z"
        fill="#F9F4F5"
      />
      <path
        d="M206.788 169.418L206.839 201.464L176.405 219.032L176.358 186.986L206.788 169.418Z"
        fill="#F9F4F5"
      />
      <path
        d="M206.77 73.2461L206.839 120.884L161.603 147.005L161.529 99.3624L206.77 73.2461"
        fill="#F4ECED"
      />
      <path
        d="M130.214 114.427L85.7585 88.5466L85.7539 86.9025L130.21 112.779L130.214 114.427"
        fill="#D0C3C4"
      />
      <path
        d="M130.21 112.778L85.7539 86.9021L104.292 68.0365L148.747 93.9173L130.21 112.778Z"
        fill="#F9F4F5"
      />
      <path
        d="M91.8306 83.4756L95.9225 85.8771L92.3848 89.4655L92.3017 89.5625L88.1961 87.1749L88.2977 87.0733L91.8306 83.4756"
        fill="#454550"
      />
      <path
        d="M100.37 81.3424L96.9062 84.8708L92.8099 82.4694L96.2827 78.9409L100.37 81.3424Z"
        fill="#454550"
      />
      <path
        d="M104.888 76.7468L101.355 80.3398L97.2681 77.9337L100.792 74.3546L104.888 76.7468Z"
        fill="#454550"
      />
      <path
        d="M109.398 72.1613L109.366 72.1891L105.874 75.7498L101.787 73.3483L105.279 69.7922L105.292 69.7783L109.398 72.1613Z"
        fill="#454550"
      />
      <path
        d="M100.371 88.482L101.211 88.9761L97.6736 92.5738L97.5952 92.6523L93.4526 90.2323L93.5403 90.1492L97.0733 86.5469L100.362 88.482H100.371"
        fill="#454550"
      />
      <path
        d="M105.657 84.4507L102.193 87.9744L101.362 87.4848L98.0596 85.5544L101.528 82.0261L104.844 83.9657L105.657 84.4507"
        fill="#454550"
      />
      <path
        d="M110.173 79.8549L106.649 83.4434L105.831 82.9677L102.511 81.0188L106.035 77.4303L109.374 79.3747L110.173 79.8549Z"
        fill="#454550"
      />
      <path
        d="M114.701 75.2507L114.659 75.2877L111.168 78.8484L110.369 78.3819L107.03 76.4238L110.526 72.8631L110.558 72.8354L114.701 75.2507Z"
        fill="#454550"
      />
      <path
        d="M102.363 89.6555L106.427 92.034L102.89 95.6317L102.83 95.6917L98.7564 93.3225L98.8256 93.2532L102.363 89.6555Z"
        fill="#454550"
      />
      <path
        d="M110.878 87.5084L107.405 91.0322L103.35 88.6491L106.814 85.1254L110.878 87.5084Z"
        fill="#454550"
      />
      <path
        d="M115.389 82.9128L111.865 86.5012L107.801 84.1227L111.334 80.5298L115.389 82.9128"
        fill="#454550"
      />
      <path
        d="M119.929 78.2945L119.874 78.3545L116.382 81.906L112.314 79.5275L115.81 75.9715L115.856 75.9206L119.929 78.2945"
        fill="#454550"
      />
      <path
        d="M107.581 92.7119L111.719 95.1365L108.186 98.7342L108.14 98.7849L103.993 96.3649L104.048 96.3096L107.581 92.7119"
        fill="#454550"
      />
      <path
        d="M116.172 90.6059L112.704 94.1389L108.566 91.7051L112.034 88.1813L116.172 90.6059Z"
        fill="#454550"
      />
      <path
        d="M120.679 86.0151L117.155 89.6082L113.017 87.1836L116.545 83.5859L120.679 86.0151"
        fill="#454550"
      />
      <path
        d="M125.235 81.3846L125.166 81.454L121.67 85.0146L117.523 82.59L121.019 79.0294L121.083 78.9693H121.093L125.235 81.3846"
        fill="#454550"
      />
      <path
        d="M112.866 95.8205L116.958 98.2128L113.425 101.81L113.393 101.843L109.296 99.4552L109.342 99.4089L112.866 95.8205Z"
        fill="#454550"
      />
      <path
        d="M121.407 93.6816L117.939 97.2053L113.856 94.8131L117.32 91.2893L121.407 93.6816"
        fill="#454550"
      />
      <path
        d="M125.923 89.0818L122.39 92.6794L118.307 90.278L121.836 86.6895L125.923 89.0818"
        fill="#454550"
      />
      <path
        d="M130.486 84.4405L130.398 84.5282L126.907 88.0842L122.82 85.692L126.311 82.1359L126.39 82.0573L130.486 84.4405Z"
        fill="#454550"
      />
      <path
        d="M118.107 98.8957L122.236 101.32L118.707 104.909L118.693 104.923L114.546 102.517L114.578 102.484L118.107 98.8957Z"
        fill="#454550"
      />
      <path
        d="M126.694 96.781L123.221 100.314L119.092 97.8894L122.565 94.361L126.694 96.781Z"
        fill="#454550"
      />
      <path
        d="M131.207 92.1912L127.684 95.7795L123.55 93.3549L127.079 89.7666L131.207 92.1912Z"
        fill="#454550"
      />
      <path
        d="M135.787 87.526L135.685 87.6368L132.185 91.1929L128.056 88.7683L131.547 85.2122L131.644 85.1153L135.787 87.526"
        fill="#454550"
      />
      <path
        d="M123.401 101.994L127.456 104.377L123.932 107.966L123.923 107.975L119.85 105.606L119.868 105.587L123.401 101.994"
        fill="#454550"
      />
      <path
        d="M131.91 99.8478L128.447 103.372L124.382 100.984L127.851 97.4602L131.91 99.8478Z"
        fill="#454550"
      />
      <path
        d="M136.426 95.2524L132.898 98.8408L128.834 96.4624L132.358 92.8694H132.367L136.426 95.2524"
        fill="#454550"
      />
      <path
        d="M141.024 90.5744L140.904 90.6943L137.408 94.2505L133.353 91.8674L136.844 88.3067L136.951 88.2051L141.024 90.5744"
        fill="#454550"
      />
      <path
        d="M128.614 105.057L132.725 107.467L129.21 111.047L125.197 108.71L125.086 108.645L128.614 105.057Z"
        fill="#454550"
      />
      <path
        d="M137.177 102.937L133.704 106.47L129.598 104.05L133.067 100.526L137.177 102.937"
        fill="#454550"
      />
      <path
        d="M141.695 98.3422L138.171 101.931L134.056 99.5199L137.585 95.9315L141.695 98.3422Z"
        fill="#454550"
      />
      <path
        d="M146.307 93.6506L146.173 93.7845L142.677 97.3406L138.562 94.9345L142.058 91.3738L142.187 91.2491L146.307 93.6506"
        fill="#454550"
      />
      <path
        d="M148.745 93.9177L148.749 95.5618L130.212 114.427L130.207 112.779L148.745 93.9177"
        fill="#DCCFCF"
      />
      <path
        d="M36.9948 248.82L36.9717 247.878C36.7592 244.65 38.0016 241.925 39.1746 238.941C39.6179 237.833 40.0936 236.665 41.0312 235.921C43.1602 234.221 46.5408 235.709 47.894 238.078C48.5174 239.172 48.8637 240.405 49.4272 241.537C50.494 243.684 52.6461 246.247 55.237 246.363C58.7192 246.52 62.6308 245.319 66.1592 245.107C67.5308 245.024 68.9533 244.977 70.2325 245.495C73.239 246.709 74.1073 251.563 73.0312 254.269C72.3662 255.946 70.8607 257.156 69.235 257.927C66.7966 259.091 63.5868 259.761 60.8944 259.557C58.0126 259.34 55.2462 259.631 52.6 260.878C48.0556 263.026 42.7723 266.106 38.1817 261.926C36.9579 260.809 36.3575 259.137 36.2143 257.484C35.9603 254.556 36.9532 251.725 36.9948 248.82"
        fill="#8A8A98"
      />
      <path
        d="M12.0344 242.18C12.39 241.067 13.1798 241.371 14.048 241.127C15.1241 240.817 16.1308 240.051 16.9621 239.321C18.0289 238.374 18.948 237.284 19.9363 236.259C22.4995 233.608 25.5521 231.433 28.8911 229.872C29.921 229.392 30.9878 228.967 32.1147 228.861C34.2576 228.662 36.3127 229.627 38.2478 230.565C40.1228 231.474 40.1182 232.68 40.2152 234.541C40.2614 235.432 40.2337 236.333 40.0074 237.197C39.5548 238.933 38.3494 240.365 37.1209 241.667C35.7031 243.177 34.1698 244.623 32.3317 245.574C30.8262 246.35 29.1498 246.775 27.7412 247.712C26.7944 248.34 26.0093 249.181 25.3258 250.082C24.7855 250.793 24.3745 251.707 23.6125 252.211C22.0284 253.25 20.1441 254.086 18.2413 254.219C14.4959 254.492 11.3879 252.215 11.2447 248.345C11.1893 246.96 11.434 245.579 11.6788 244.212C11.8312 243.353 11.8774 242.683 12.0344 242.18"
        fill="#575862"
      />
      <path
        d="M39.6396 146.802C38.8499 145.763 37.4182 145.518 36.1343 145.292C30.5 144.285 24.9904 142.049 19.4393 140.701C16.95 140.101 14.4469 139.685 11.9253 139.63L6.43421 134.674C5.75533 139.454 5.34885 144.308 5.89842 149.102C7.09456 159.544 9.82398 183.642 10.5352 195.132C11.0802 203.893 11.0247 212.681 11.0986 221.456C11.1263 225.234 11.3757 229.016 10.9001 232.743C10.4659 236.142 10.4105 240.798 12.0315 243.892C13.3154 246.344 15.8786 248.053 18.6311 248.381C21.3743 248.713 24.2376 247.674 26.2004 245.721C27.4427 244.488 27.3965 242.045 27.5859 240.396C27.7521 238.899 27.8353 237.394 27.8907 235.888C28.0524 231.547 28.057 227.201 28.2001 222.86C28.3525 218.279 28.5927 213.698 28.9114 209.125C29.1192 206.119 29.3547 203.117 29.6226 200.12C29.7658 198.462 29.9182 196.808 30.0752 195.151C30.2137 193.742 29.9504 190.994 30.6155 189.77C33.4881 184.496 35.9588 178.825 36.3791 175.717C36.9841 171.288 36.9656 166.813 37.4228 162.37C37.8385 158.31 39.3256 154.565 40.0461 150.552C40.277 149.277 40.4201 147.836 39.6396 146.802"
        fill="#333139"
      />
      <path
        d="M15.361 156.593C13.5367 152.584 10.1424 148.89 5.71805 146.862C5.48713 142.803 5.86124 138.711 6.4339 134.675L11.925 139.63C14.4466 139.686 16.9497 140.101 19.4389 140.702C24.9901 142.05 30.4997 144.285 36.134 145.292C37.4179 145.518 38.8496 145.763 39.6393 146.802C40.4198 147.837 40.2767 149.278 40.0458 150.552C39.3253 154.566 37.8382 158.311 37.4225 162.371C36.9653 166.813 36.9838 171.288 36.3788 175.717C35.9585 178.825 33.4877 184.497 30.6152 189.771C29.9501 190.995 30.2134 193.743 30.0749 195.151C29.9178 196.809 29.7654 198.462 29.6223 200.12C29.3544 203.118 29.1189 206.12 28.911 209.126C28.5924 213.698 28.3522 218.279 28.1998 222.861C28.0566 227.202 28.0521 231.548 27.8904 235.889C27.835 237.395 27.7518 238.9 27.5855 240.396C27.3962 242.045 27.4424 244.488 26.2001 245.721C25.2672 246.65 24.1311 247.37 22.898 247.836C27.4886 216.894 28.3014 185.711 15.361 156.593"
        fill="#232124"
      />
      <path
        d="M15.3662 156.593C28.3066 185.711 27.4938 216.893 22.9033 247.836C21.5455 248.349 20.0769 248.556 18.636 248.381C15.8835 248.053 13.3203 246.344 12.0364 243.892C10.4154 240.798 10.4708 236.142 10.905 232.743C11.3806 229.016 11.1312 225.234 11.1035 221.456C11.0296 212.682 11.0851 203.893 10.5401 195.132C9.82889 183.642 7.09947 159.544 5.90333 149.102C5.8202 148.358 5.76483 147.61 5.72327 146.862C10.1476 148.889 13.542 152.584 15.3662 156.593"
        fill="#333139"
      />
      <path
        d="M50.7552 202.364L50.7413 202.951C50.5797 212.026 51.217 221.087 51.4387 230.157C51.5819 236.193 52.7964 252.408 47.3422 254.472C44.6452 255.493 39.1956 256.255 37.6993 253.645C32.1112 243.91 30.4393 209.966 30.0468 200.378C29.9498 197.963 30.2131 198.85 30.0699 196.434C29.9544 194.504 29.174 191.747 29.7235 189.881C30.7719 186.297 35.5103 188.329 37.8333 189.128C41.2046 190.292 45.4257 190.865 48.3214 193.1C51.2124 195.335 50.8568 199.067 50.7552 202.364"
        fill="#454550"
      />
      <path
        d="M51.4366 139.528C48.3886 136.775 38.9764 140.151 35.4435 140.359C32.1783 140.553 28.9086 140.585 25.6389 140.558C24.1887 140.544 22.3276 140.816 20.9236 140.47C19.7044 140.174 18.7438 139.223 17.4645 138.923C16.4624 138.683 13.6545 136.609 12.8601 137.265C11.6501 138.267 13.3265 142.299 13.6637 143.837C16.6517 157.479 25.662 168.969 28.336 182.63C29.4582 188.375 29.2273 194.291 29.823 200.11C29.8969 200.831 29.9847 201.565 30.271 202.23C30.7652 203.376 31.7904 204.207 32.8342 204.9C35.4066 206.604 38.3345 207.749 41.3641 208.285C42.9898 208.571 44.6477 208.687 46.2918 208.622C47.5434 208.571 49.1413 207.975 50.2774 208.608C50.9933 203.74 50.4714 198.711 51.4874 193.922C52.5542 188.893 52.328 183.448 52.4989 178.284C52.8776 166.942 53.0807 155.586 52.7805 144.239C52.7389 142.83 52.6744 141.343 51.9401 140.137L51.4366 139.528"
        fill="#454550"
      />
      <path
        d="M56.7789 82.2293C56.8805 83.5271 56.6634 86.0486 55.398 86.8984C54.3543 87.6003 52.0682 87.3418 50.8906 87.4526C47.1636 87.799 43.4458 88.0391 39.7281 88.2792C38.0378 88.3854 36.3522 88.4963 34.6619 88.5517C29.7203 88.7041 24.8018 88.3808 19.985 87.5865C18.1145 87.2771 15.0157 86.8245 13.524 85.5822C12.0507 84.3538 11.9122 82.3633 12.1616 80.3774C12.6003 76.8306 14.3507 73.3529 17.7267 71.9998C21.1072 70.6374 24.4509 71.2193 27.8084 71.6488C35.5579 72.6417 43.2149 74.1242 50.7289 76.0962C52.0636 76.4426 53.426 76.8213 54.5436 77.6295C55.8182 78.5532 56.6311 80.3497 56.7789 82.2293"
        fill="white"
      />
      <path
        d="M108.37 90.3531L109.367 89.3787C111.27 87.8131 113.376 86.5016 115.616 85.4809C117.518 84.6081 119.961 83.4257 122.109 83.8968C123.504 84.2016 124.921 84.4187 126.335 84.645C127.84 84.8898 129.253 85.2593 130.62 85.9566C131.567 86.4462 132.537 87.1619 132.717 88.2195L132.699 88.4504L132.551 88.5751C132.154 88.7599 131.696 88.6121 131.276 88.4828C130.717 88.3073 130.219 87.9886 129.641 87.8362C129.036 87.6745 128.404 87.6053 127.78 87.6469C127.281 87.6746 126.529 87.887 126.376 88.4504C126.238 88.94 126.519 89.2679 126.759 89.6374C127.083 90.1361 127.258 90.7134 127.397 91.2906C127.503 91.7248 127.549 92.2606 127.198 92.5377L126.302 93.0918C126.117 93.5721 126.681 93.9092 126.335 94.3895L125.845 94.8052L125.757 95.6458L125.189 96.7033L124.894 96.9758L124.529 96.9665C123.407 96.6941 122.548 96.0382 121.476 95.678C120.659 95.4009 119.754 95.5256 118.936 95.8027C118.119 96.0844 117.361 96.5093 116.572 96.8557C115.606 97.2806 114.595 97.5807 113.56 97.7609C112.683 97.9133 110.905 98.1026 110.475 98.2319L108.37 90.3531"
        fill="#F9B398"
      />
      <path
        d="M55.4714 77.9797L55.4622 77.6241C55.416 75.5828 55.4161 73.2182 54.2338 71.4402C53.1993 69.8838 51.1856 69.0803 49.6662 68.1104C44.5584 64.8453 39.0904 62.1482 33.3868 60.0977C24.7044 56.9711 15.8465 50.6025 6.58226 55.8304C5.64013 56.3615 4.74422 56.9896 3.96835 57.747C-0.797716 62.393 -0.257391 69.8839 0.490771 75.8969C1.45599 83.6325 3.75127 91.1233 5.09981 98.7943C5.38152 100.392 5.6217 101.999 5.82491 103.611C6.52227 109.181 6.0189 114.898 6.7763 120.417C7.4321 125.215 5.93107 130.697 5.30298 135.519C4.91505 138.488 4.58263 141.749 6.20826 144.261C7.96321 146.977 11.4037 147.951 14.5534 148.672C26.0853 151.318 38.4207 153.345 49.5277 149.277C51.5089 148.552 53.5871 147.476 54.3722 145.518C54.8895 144.234 54.7509 142.793 54.6124 141.412C53.056 125.839 52.6404 110.123 54.2014 94.5547C54.7556 89.0405 55.5592 83.5263 55.4714 77.9797"
        fill="#F9F4F5"
      />
      <path
        d="M35.8984 86.5194C34.8085 83.1388 34.4484 79.6474 36.0879 76.1052C37.6673 72.7061 40.7893 70.0829 44.3777 69.0438C47.5412 68.1248 52.238 68.4388 54.2331 71.4407C55.4154 73.2187 55.4154 75.5833 55.4615 77.6246L55.4708 77.9802C55.5585 83.5268 54.7549 89.041 54.2008 94.5552C52.6398 110.123 53.0554 125.839 54.6117 141.412C54.7503 142.793 54.8888 144.234 54.3716 145.518C53.9559 146.557 53.1709 147.347 52.2334 147.97C52.395 141.717 51.8269 135.45 50.7186 129.294C49.2084 120.875 46.7146 112.65 43.6249 104.678C42.0686 100.656 40.3552 96.6934 38.5263 92.7864C37.5703 90.7497 36.5866 88.6577 35.8984 86.5194"
        fill="#EBE2E2"
      />
      <path
        d="M37.839 61.885C36.2687 61.2154 34.5876 60.5318 33.3869 60.0977C27.3554 57.9271 21.2408 54.1909 14.9646 53.863C11.9396 58.4213 14.3826 63.6493 18.8439 66.0831C24.4459 68.8864 31.8213 68.5724 37.1416 64.8085C38.2038 64.0557 39.0028 62.781 37.839 61.885"
        fill="#DCCFCF"
      />
      <path
        d="M53.0643 71.3721C49.3465 69.5017 44.682 68.3149 40.775 70.37C39.3017 71.1505 38.1517 72.4251 37.1634 73.769C36.3645 74.8497 35.6487 76.0089 35.1777 77.2697C34.0831 80.1931 34.5495 83.5736 36.1659 86.2153C38.1795 89.5081 41.0059 90.8382 44.5204 92.0297C47.5962 93.0781 50.6119 94.2881 53.6138 95.5304C59.6037 98.0058 65.5705 100.62 71.8606 102.278C75.5368 103.243 79.6979 103.811 82.9445 101.825C85.1382 100.49 86.6068 98.0427 86.7546 95.4749C87.1148 89.2633 82.4087 88.4736 81.7299 88.0903C60.2826 75.995 52.5747 71.1228 53.0643 71.3721"
        fill="#F9F4F5"
      />
      <path
        d="M114.888 93.0587C115.165 94.3241 115.29 96.8595 114.154 97.8755C113.216 98.7068 110.916 98.7668 109.762 99.0301C106.122 99.8752 102.465 100.619 98.8117 101.358C97.1537 101.695 95.4958 102.032 93.8332 102.314C88.9563 103.136 84.0378 103.482 79.1609 103.344C77.2674 103.288 74.1316 103.26 72.4875 102.235C70.8618 101.215 70.4553 99.2564 70.4323 97.2567C70.3861 93.6868 71.6561 90.0013 74.8104 88.2048C77.9785 86.4037 81.373 86.5238 84.7536 86.4961C92.5677 86.4268 100.359 86.861 108.067 87.7985C109.438 87.9647 110.838 88.1541 112.052 88.8052C113.442 89.5442 114.491 91.216 114.888 93.0587Z"
        fill="#F9F4F5"
      />
      <path
        d="M35.7047 52.5512C36.0373 50.2559 37.7738 50.7916 36.8963 48.7134C36.1528 46.9446 35.0212 45.319 33.5018 44.1459C31.987 42.9729 30.0705 42.2801 28.1585 42.3864C27.4981 42.4233 27.0548 42.7973 26.4036 42.802C24.5978 42.8066 22.7828 42.4649 20.9817 42.7235C19.1852 42.9821 17.5226 43.116 17.5273 45.1388C17.5273 47.8405 17.6519 50.436 16.7883 53.0915C16.3265 54.5093 15.6245 55.8717 15.5044 57.3588C15.2596 60.2822 17.3933 63.0069 20.0303 64.2816C23.277 65.8518 26.9485 65.7086 30.3983 65.2052C32.1302 64.9559 34.5686 64.6095 35.5847 62.9562C36.3513 61.7139 36.0188 60.0835 35.8572 58.7212C35.6078 56.6614 35.3999 54.6248 35.7047 52.5512"
        fill="#F9B398"
      />
      <path
        d="M43.4016 23.1982C44.4869 24.5236 45.3366 26.0154 45.5999 27.7611C46.1818 31.6589 44.6117 35.6629 45.4568 39.5285C46.2789 43.2739 44.6716 51.7761 37.287 53.9606C33.5508 55.0644 26.1754 53.7712 23.8062 52.6305C20.6196 51.1064 18.5183 48.0214 16.6386 45.038C1.20896 20.5427 32.9365 10.4656 43.4016 23.1982Z"
        fill="#F9B398"
      />
      <path
        d="M12.1266 18.3811C12.8748 17.6145 13.7939 16.9541 14.9115 16.4322L15.8536 15.841L16.2416 15.1067C16.7635 13.9752 17.8118 13.1809 18.9017 12.5713C23.0166 10.2852 28.0089 10.1744 32.6734 10.844C34.2482 11.0703 35.823 11.3798 37.2824 12.0078C38.9542 12.7237 40.4229 13.8321 41.7622 15.0606C43.6465 16.8017 45.4337 18.8429 46.4174 21.2444C46.87 22.362 46.9624 23.5443 47.2857 24.6758C47.572 25.6964 47.221 26.8971 46.3713 27.5437L45.1936 28.2595C43.628 29.3125 43.337 31.751 42.6582 33.3998C42.2333 34.4435 41.3881 35.0115 41.148 36.1337C40.894 37.2976 40.917 38.5122 40.5476 39.6576C40.3721 40.1979 40.0119 40.7843 39.4438 40.8213L38.7788 40.6227C38.1184 40.2117 37.7766 39.4497 37.5596 38.7062C37.3425 37.9626 37.1994 37.1775 36.7883 36.5217L36.5482 36.2862L36.271 36.2769C35.4767 36.434 34.9549 37.0251 34.5762 37.6948C34.1189 38.503 33.8742 39.4127 33.634 40.3133C33.2553 41.7265 33.2045 42.9226 33.029 44.3266C32.8489 45.749 32.4425 47.273 31.4496 48.3491C29.2097 50.7644 24.8962 50.6259 21.9821 49.984C20.9291 49.7484 19.8669 49.499 18.8925 49.0326C16.6434 47.9473 15.1101 45.8275 13.6784 43.7816C12.3668 41.9159 11.0414 40.0085 10.3487 37.8379C9.96073 36.6094 9.78057 35.3302 9.60507 34.0555C8.88924 28.8923 8.34888 22.2651 12.1266 18.3811"
        fill="#23262C"
      />
      <path
        d="M35.0473 33.9402C32.147 33.3075 30.8677 36.8358 32.461 39.944C32.9274 40.8491 33.8603 41.7312 34.53 42.4979C39.1159 47.7442 41.1526 43.1029 39.7071 41.3341C36.3588 37.2284 38.6957 34.7345 35.0473 33.9402Z"
        fill="#F9B398"
      />
      <path
        d="M35.1167 39.5981C34.8257 39.2378 31.7777 36.7717 34.2531 35.7695C34.932 35.4924 35.5554 36.1436 35.5507 36.8271L35.3938 37.8708L35.5324 38.4805C35.7587 39.007 36.7007 39.6212 36.3959 40.2954C36.1281 40.8958 35.2829 39.8013 35.1167 39.5981Z"
        fill="#F39C75"
      />
    </svg>
  );
}

export default BackgroundMinumumResolutionCromie;
