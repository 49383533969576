import { createSlice } from "@reduxjs/toolkit";

export const defaultState = {
  snackbar: {
    message: "",
    type: "error",
    open: false,
    timeoutId: null,
  },
  bottomToolbar: {
    open: false,
  },
  hasOnePickerOpened: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: defaultState,
  reducers: {
    openSnackbar(state, { payload }) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          type: payload.type,
          message: payload.message,
          open: true,
          timeoutId: payload.timeoutId,
        },
      };
    },
    closeSnackbar(state) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
          timeoutId: null,
        },
      };
    },
    closeAllPickers(state) {
      return {
        ...state,
        hasOnePickerOpened: false,
      };
    },
    setPickerOpen(state) {
      return {
        ...state,
        hasOnePickerOpened: true,
      };
    },
    openBottomToolbar(state) {
      return {
        ...state,
        bottomToolbar: {
          open: true,
        },
      };
    },
    closeBottomToolbar(state) {
      return {
        ...state,
        bottomToolbar: {
          open: false,
        },
      };
    },
  },
});

export default uiSlice;

export const getSnackbarState = (state) => state.snackbar;
export const getBottomToolbarState = (state) => state.bottomToolbar;
export const getSnackbarTimeoutId = (state) => state.snackbar.timeoutId;
