import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon } from "@dbox/components";
import { FEATURED_ICON_VARIANTS } from "../constants";

const proptypes = {
  isLeft: PropTypes.bool,
  image: PropTypes.object,
  children: PropTypes.PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  text: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  pretitle: PropTypes.string,
};

function Section404({ isLeft, image, children, text, title, icon, pretitle }) {
  const wrapperCn = () => cntl`
    lg:px-20
    pt-16
    pb-16
    lg:pb-24
    h-full
  `;

  const pretitleCn = () => cntl`
    font-semibold
    text-primary-700
    ${isLeft ? "text-start" : "text-center"}
  `;

  const titleCn = () => cntl`
    text-3xl
    lg:text-6xl
    font-semibold
    ${isLeft ? "text-start" : "text-center"}
  `;

  const textCn = () => cntl`
    text-grey-600
    text-lg
    lg:text-xl
    ${isLeft ? "text-start" : "text-center"}
  `;

  const contentCn = () => cntl`
    flex
    flex-col
    mb-12
    justify-center
  `;

  const containerCn = () => cntl`
    px-4
    lg:px-8
    flex
    flex-col
    lg:flex-row
    container
    mx-auto
    ${isLeft && !image ? "justify-start" : image ? "justify-between" : "justify-center"}
  `;

  const textWrapperCn = () => cntl`
    flex
    flex-col
    gap-y-3
    ${isLeft ? "items-start" : "items-center"}
  `;

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentCn()}>
          <div className={textWrapperCn()}>
            {icon && <FeaturedIcon variant={FEATURED_ICON_VARIANTS.light_circle_outline} children={icon} />}
            {pretitle && <div className={pretitleCn()}>{pretitle}</div>}
            <div className={titleCn()}>{title}</div>
            <div className={textCn()}>{text}</div>
          </div>
          {children}
        </div>
        {image}
      </div>
    </div>
  );
}

Section404.prototype = proptypes;
export default Section404;
