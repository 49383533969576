import cntl from "cntl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";

function SliderCard(props) {
  const {
    title,
    image,
    hasNewContent = false,
    isSelected,
    onCardClick,
    currentIndex,
    action,
    valuesToSet,
  } = props;

  const selectedCard = (isSelected) => cntl`
    flex
    flex-col
    w-52
    p-4
    min-h-full
    cursor-pointer
    ${isSelected ? cntl`border-2` : cntl`border`}
    ${isSelected ? cntl`border-primary-400` : cntl`border-grey-300`}
    ${isSelected ? cntl`bg-primary-50` : null}
    rounded-lg
    bg-white
  `;

  return (
    <div
      className="flex-none snap-center relative mr-4 last:mr-0"
      onClick={() => onCardClick({ index: currentIndex, values: valuesToSet })}
    >
      {hasNewContent && (
        <div className="bg-teal-50 h-2 w-2 rounded-full absolute ml-44 mt-4" />
      )}
      <div className={selectedCard(isSelected)}>
        <div className="first-letter:capitalize font-medium text-base text-grey-900">
          {title}
        </div>
        {action && <div className="mt-2">{action}</div>}
        {image && (
          <div className="mt-4">
            <LazyLoadImage
              alt="machine"
              className="rounded-lg"
              src={`data:image/png;base64,${arrayBufferToBase64(
                image?.buffer ?? null
              )}`}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SliderCard;
