import cntl from "cntl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SIZE } from "@dbox/components/constants";
import { ButtonPrimary, VerificationCode } from "@dbox/components";

const propTypes = {
  onSubmit: PropTypes.func,
  isButtonLoading: PropTypes.bool,
};

function VerificationCodeForm({ onSubmit, isButtonLoading }) {
  const { t } = useTranslation();
  const numberOfInput = 6;

  const formCn = () => cntl`
    flex
    gap-y-8
    flex-col
    items-center
  `;

  const alignSelf = ({ isFullWidth }) => cntl`
    self-center
    ${isFullWidth ? cntl`w-full` : cntl`w-auto`}
  `;

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    const codeArr = Object.values(formProps).join("");
    onSubmit(codeArr);
  };

  return (
    <form className={formCn()} onSubmit={onFormSubmit} autoComplete="off">
      <VerificationCode
        size={SIZE.md}
        numberOfInput={numberOfInput}
        className={alignSelf({ isFullWidth: false })}
        label={t("verification_code.label.secure_code")}
      />
      <ButtonPrimary
        type="submit"
        size={SIZE.lg}
        isLoading={isButtonLoading}
        label={t("button.label.verify_email")}
        className={alignSelf({ isFullWidth: true })}
      />
    </form>
  );
}

VerificationCodeForm.propTypes = propTypes;
export default VerificationCodeForm;
