import PropTypes from "prop-types";
import { useState, useEffect, useRef, useCallback } from "react";

const propTypes = {
  fabIcon: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function FloatingActionButton({ fabIcon, children }) {
  const fabRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback((e) => {
    if (!fabRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <ul className="flex flex-col-reverse items-end list-none fixed bottom-5 right-5 z-20">
      <li
        onClick={() => setIsOpen(!isOpen)}
        className="absolute flex flex-row justify-center items-center w-24 h-24 bg-primary-50 shadow-md rounded-2xl cursor-pointer"
      >
        <div className="w-11 h-11">{fabIcon}</div>
      </li>
      <li
        ref={fabRef}
        className={`relative bottom-0 right-0 w-72 ${
          isOpen
            ? `opacity-100 scale-100 duration-300 ease-in-out`
            : `opacity-0 scale-0 duration-300 ease-in-out`
        }`}
      >
        {children}
      </li>
    </ul>
  );
}

FloatingActionButton.propTypes = propTypes;
export default FloatingActionButton;
