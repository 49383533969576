import { formatCurrency } from "@dbox/core/utils/formatCurrency";
import { IconCheck, IconX } from "@tabler/icons-react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

const propTypes = {
  className: T.string,
  data: T.array.isRequired,
};

function QuoteCardTable({ data, className }) {
  const { t } = useTranslation();

  return (
    <table
      className={`flex flex-col overflow-auto ${
        className ? className : undefined
      }`}
    >
      <thead className="box-border mb-2 border-b border-b-grey-300">
        <tr className="flex flex-row justify-between items-end mb-1">
          <th>
            <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
              {t("table.header.details")}
            </div>
          </th>
          <th>
            <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
              {t("table.header.partial_amount_no_vat")}
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="card-table overflow-auto">
        {!!data?.length &&
          data?.map((item, index) => {
            return (
              <tr
                key={`row-${index}`}
                className={`flex flex-row justify-between py-1.5 px-3 odd:bg-grey-100 even:bg-grey-25 
                  ${
                    item?.status?.toLowerCase() === "accepted"
                      ? "text-success-700"
                      : item?.status?.toLowerCase() === "rejected"
                      ? "text-error-600"
                      : "text-grey-900"
                  }
                `}
              >
                <td className="text-xs first-letter:capitalize">
                  <div className="flex flex-row items-center gap-x-2">
                    {item?.status?.toLowerCase() === "accepted" && (
                      <IconCheck className="text-succes w-4 h-4" />
                    )}
                    {item?.status?.toLowerCase() === "rejected" && (
                      <IconX className="text-error-600 w-4 h-4" />
                    )}
                    {item?.product_data?.name || "n/a"}
                  </div>
                </td>
                <td className="text-xs font-medium">
                  {t("data.general.euro")}&nbsp;
                  {formatCurrency(item?.price) || "n/a"}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

QuoteCardTable.propTypes = propTypes;
export default QuoteCardTable;
