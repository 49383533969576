export const getAllDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const dates = [];

  while (date.getMonth() === month) {
    dates.push(new Date(date).getDate());
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export const getNextMonth = (currentMonth) => {
  if (currentMonth === 11) {
    return 0;
  }
  return currentMonth + 1;
};

export const getNextYear = (currentYear, currentMonth) => {
  if (currentMonth === 11) {
    return currentYear + 1;
  }
  return currentYear;
};

export const mapMonths = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const mapDays = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export const transformDaysInWeeks = (days) => {
  const weeks = [];
  const shallowDays = days.slice();

  while (shallowDays.length > 0) {
    const week = [];

    const weekLength = shallowDays.length >= 7 ? 7 : shallowDays.length;
    for (let j = 0; j < weekLength; j++) {
      week.push(shallowDays.shift());
    }
    weeks.push(week);
  }
  return weeks;
};

export const orderedDays = (currentYear, month) => {
  const date = new Date(currentYear, month, 1);
  const day = date.getDay();
  const keys = Object.keys(mapDays);
  const daysOfWeek = [];

  for (let i = day; i < keys.length; i++) {
    daysOfWeek.push(keys[i]);
  }

  for (let i = 0; i < day; i++) {
    daysOfWeek.push(keys[i]);
  }

  return daysOfWeek;
};

export const allYears = () => {
  const years = [];

  for (let i = 1900; i <= 2100; i++) {
    years.push(i);
  }

  return years;
};

export const getNextMonthName = (currentMonth) => {
  if (currentMonth === 11) {
    return mapMonths[1];
  }
  return mapMonths[currentMonth + 2];
};
