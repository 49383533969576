import PropTypes from "prop-types";
import NavButton from "../atoms/NavButton";
import { useState, cloneElement } from "react";

const propTypes = {
  tabs: PropTypes.array,
  active: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  on_index_change: PropTypes.func,
  start_index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isVertical: PropTypes.bool,
  navButtonType: PropTypes.string,
};

function NavTab({
  tabs,
  start_index,
  navButtonType,
  on_index_change,
  isVertical = false,
}) {
  const [activeIndex, setActiveIndex] = useState(start_index - 1);

  const onClick = (index, isDisabled) => {
    if (!isDisabled) {
      setActiveIndex(index);
    }
    if (on_index_change && !isDisabled) {
      on_index_change(index);
    }
  };

  return (
    <nav
      className={`flex ${
        isVertical
          ? `flex-col shrink-0 pr-4 border-r border-r-grey-200`
          : !!!navButtonType
          ? `hidden-scrollbar flex-row w-full border-b border-grey-200 h-14 overflow-x-auto`
          : undefined
      }`}
    >
      {tabs?.map(({ label, element, isDisabled }, index) => {
        return (
          <NavButton
            key={`nav-button-${index}`}
            active={index === activeIndex}
            onClick={() => onClick(index, isDisabled)}
            isVertical={isVertical}
            isDisabled={isDisabled}
            navButtonType={navButtonType}
          >
            <div
              className={`flex flex-row ${
                isVertical ? "items-baseline" : "items-center"
              }`}
            >
              {element && (
                <div className="flex items-center justify-center w-5 h-5 mr-2">
                  {cloneElement(element, {
                    className: "hover:text-primary-700",
                  })}
                </div>
              )}
              {label && <div className="first-letter:uppercase">{label}</div>}
              {isVertical && (index === activeIndex) && <div className="absolute triangle right-0 -mr-3 top-1/2 -translate-y-1/2" />}
            </div>
          </NavButton>
        );
      })}
    </nav>
  );
}

NavTab.propTypes = propTypes;
export default NavTab;
