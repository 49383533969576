import T from "prop-types";
import { BadgeText } from "../atoms";
import { useTranslation } from "react-i18next";

const propTypes = {
  order: T.object,
  quote: T.object,
  className: T.string,
};

function QuoteCardHeaderSmall({
  quote,
  order,
  className,
}) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col ${className ? className : undefined}`}>
      <div className="uppercase font-medium text-base mr-4">{quote?.id}</div>
      <div className="text-sm text-grey-900 first-letter:capitalize">
        {(quote?.customer_data?.firstname +
          " " +
          quote?.customer_data?.lastname) ||
          quote?.customer_data?.name ||
          "n/a"}
      </div>
      <div className="mt-2.5 mb-3">
        <BadgeText label={quote?.status} />
      </div>
      <>
        <div className="text-xs text-grey-500 first-letter:capitalize">
          {t("card.total")}&nbsp;{t("card.with_vat")}
        </div>
        <div className="text-base text-grey-900 font-medium">
          {t("data.general.euro")}&nbsp;
          {quote?.price_with_vat || "n/a"}
        </div>
      </>
      <div className="my-3">
        <div className="text-xs text-grey-500 first-letter:capitalize">
          {t("card.total")}&nbsp;{t("card.without_vat")}
        </div>
        <div className="text-sm text-grey-900 font-medium">
          {t("data.general.euro")}&nbsp;
          {quote?.price_without_vat || "n/a"}
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="text-xs text-grey-500 font-bold first-letter:capitalize">
          {t("card.order")}
          {t("data.general.colon")}&nbsp;
        </div>
        {!!order?.status ? (
          <BadgeText label={order?.status} />
        ) : (
          <div className="text-xs text-grey-500 font-regular first-letter:capitalize">
            {t("card.unavailable")}
          </div>
        )}
      </div>
    </div>
  );
}

QuoteCardHeaderSmall.propTypes = propTypes;
export default QuoteCardHeaderSmall;
