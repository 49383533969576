import PropTypes from "prop-types";
import { useState } from "react";
import { NavTab } from "../molecules";

const propTypes = {
  start_index: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  navTab: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

function TabsVertical({ navTab, start_index, children, isLoading }) {
  const [index, setIndex] = useState(start_index - 1);

  const on_index_change = (currentIndex) => {
    setIndex(currentIndex);
  };

  return (
    <div className="flex flex-row rounded-lg">
      <NavTab
        isLoading={isLoading}
        start_index={start_index}
        on_index_change={on_index_change}
        tabs={navTab}
        isVertical
      />
      <>{children && <div className="w-full">{children[index]}</div>}</>
    </div>
  );
}

TabsVertical.propTypes = propTypes;
export default TabsVertical;
