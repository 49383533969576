function TriangolTooltip({ color = "#001E31", direction = "vertical" }) {
  return direction === "vertical" ? (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-2.91409e-07 6.66659L8 -8.21273e-05L8 13.3333L-2.91409e-07 6.66659Z"
        fill="#E2E2E5"
      />
      <path
        d="M1.34644 6.64547L7.98665 1.33329L7.98665 11.9576L1.34644 6.64547Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width="25"
      height="8"
      viewBox="0 0 25 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 8L0.500001 -2.09815e-06L24.5 0L12.5 8Z" fill={color} />
    </svg>
  );
}

export default TriangolTooltip;
