export const DOTS = "...";

export const BREAKPOINT = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const ADDRESS_TYPES = {
  legal: "legal",
  billing: "billing",
  shipping: "shipping",
  residential: "residential",
};

export const PROFILE_ROLE = {
  administrator: 'administrator',
  technician: 'technician',
  commercial: 'commercial'
}

export const TENANT_TYPES = {
  typography: "typography",
  web_agency: "web_agency",
};

export const FORMAT_ACCEPTED = {
  json: "json",
  image: "image",
  document: "document",
};

export const KEYBOARDS = {
  enter: 13,
  backspace: 8,
  arrowLeft: 37,
  arrowRight: 39,
};

export const STATUS_VALUES = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const dimensions = {
  SMALL: "small",
  DEFAULT: "default",
  LARGE: "large",
};

export const formatValues = {
  GENERAL: "general",
  SINGLE: "single",
  PAGES: "pages",
  SUPPORT: "support",
  COVER: "cover",
  FRONT_COVER: "frontcover",
  BACK_COVER: "backcover",
};

export const PickerPositions = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const SIZE = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
  "w-full": "w-full",
  floating: "floating",
};

export const WIDTH = {
  xs: "w-20",
  sm: "w-40",
  md: "w-60",
  lg: "w-80",
  xl: "w-[35rem]",
};

export const COLOR = {
  light: "light",
  grey: "grey",
  dark: "dark",
  error: "error",
  primary: "primary",
  warning: "warning",
  success: "success",
  primary_light: "primaryLight",
};

export const PROGRESS_BAR_VARIANTS = {
  circular: "circular",
  linear: "linear",
};

export const STEP_ICON_BASE_VARIANTS = {
  mid: "mid",
  dark: "dark",
  light: "light",
};

export const CHECK_ITEM_ICON_VARIANTS = {
  dark: "dark",
  light: "light",
  border: "border",
};

export const STEP_ICON_BASE_STATE = {
  current: "current",
  complete: "complete",
  incomplete: "incomplete",
};

export const STEP_DIRECTION = {
  vertical: "vertical",
  horizontal: "horizontal",
};

export const BADGE_ICON_VARIANTS = {
  custom: "custom",
  only_icon: "onlyIcon",
};

export const BUTTON_VARIANTS = {
  light: "light",
  border: "border",
  default: "default",
  border_off: "borderOff",
};

export const FOOTER_VARIANTS = {
  only_link: "onlyLink",
  sidesLink: "sidesLink",
};

export const FOOTER_COLOR_VARIANTS = {
  dark: "dark",
  grey: "grey",
  primary: "primary",
  white: "white",
};

export const FEATURED_ICON_VARIANTS = {
  dark: "dark",
  square: "square",
  light_circle: "lightCircle",
  light_circle_outline: "lightCircleOutline",
};

export const LINK_VARIANTS = {
  grey: "grey",
  error: "error",
  primary: "primary",
};

export const HEADER_PRICING = {
  with_featured_icon: "withFeaturedIcon",
};

export const ONBOARDING_STATE = {
  init: "init",
  completed: "completed",
  processing: "processing",
};

export const SELECT_VARIANTS = {
  picker: "picker",
};

export const NAV_MENU_HEADER_VARIANTS = {
  only_cols: "onlyCols",
  only_images: "onlyImages",
  three_cols_one_card: "threeColsOneCard",
  two_cols_multiple_cards: "twoColsMulpileCards",
};

export const BREADCRUMB_VARIANTS = {
  text: "text",
  grey: "grey",
  primary: "primary",
};

export const TAB_BUTTON_VARIANTS = {
  grey: "grey",
  white: "white",
  primary: "primary",
  underline: "underline",
  underline_filled: "underlineFilled",
};

export const MODAL_HEADER_VARIANTS = {
  left: "left",
  center: "center",
  inline: "inline",
};

export const MODAL_ACTION_VARIANTS = {
  row: "row",
  column: "column",
  other_actions: "otherActions",
};

export const CARD_MENU_ITEM_CARD_VARIANTS = {
  feature: "feature",
  image_card: "imageCard",
  feature_card: "featureCard",
};

export const POSITIONS = {
  left: "left",
  right: "right",
  center: "center",
};

export const DIRECTION = {
  row: "row",
  column: "column",
};

export const SELF_ALIGNMENT = {
  start: "self-start",
  center: "self-center",
  end: "self-end",
};

export const TEXT_ALIGNMENT = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

export const PAGE_MODES = {
  create: "create",
  update: "update",
};

export const STATUS = {
  error: "error",
  completed: "completed",
  undefined: "undefined",
  success: "success",
  pending: "pending",
  confirmed: "confirmed",
};

export const PAGINATION_VARIANTS = {
  page_default: "pageDefault",
  page_button: "pageButton",
  card_default: "cardDefault",
  card_right: "cardRight",
  card_left: "cardLeft",
  card_center: "cardCenter",
  card_button: "cardButton",
};

export const PICKER_MONTH = {
  first_month: "firstMonth",
  second_month: "secondMonth",
};

export const PICKER_VARIANT = {
  data_range_base: "dataRangeBase",
  data_range_complex: "dataRangeComplex",
};
