import T from "prop-types";
import { Children, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonPrimary } from "../atoms";
import { SIZE } from "../constants";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";
import { IconAlertCircle, IconClock, IconInfoCircle, IconPlayerTrackNext, IconStar } from "@tabler/icons-react";

const propTypes = {
  hasErrors: T.bool,
  className: T.string,
  onClick: T.func.isRequired,
  workflow: T.object.isRequired,
  onInfoClick: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

function WorkflowCalc({ onInfoClick, onClick, children, workflow, className, hasErrors }) {
  const { t } = useTranslation();
  const [showErrorMessages, setShowErrorMessages] = useState(false);

  return (
    <div className={`flex flex-col p-4 bg-grey-50 shadow rounded-lg ${className ? className : undefined}`}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start gap-x-4">
          <div className="text-base text-grey-900 font-bold first-letter:capitalize">{workflow?.code || "n/a"}</div>
          <div className="flex flex-row gap-x-2">
            {!hasErrors ? (
              <>
                {workflow?.is_faster && <IconPlayerTrackNext className="w-5 h-5 text-primary-700" />}
                {workflow?.is_cheaper && <IconStar className="w-5 h-5 text-primary-700" />}
              </>
            ) : (
              <IconAlertCircle
                className="cursor-pointer w-5 h-5 text-error-600"
                onClick={() => setShowErrorMessages(!showErrorMessages)}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row" onClick={onInfoClick}>
          <IconInfoCircle className="w-5 h-5 text-grey-500 hover:cursor-pointer" />
        </div>
      </div>
      {workflow?.errors?.map(({ error, name }, index) => {
        return (
          <div key={`message-error-${index}`} className={`${showErrorMessages ? "flex list-disc mt-1" : "hidden"}`}>
            <ul className="list-disc list-outside text-error-600 text-xs pl-4">
              <li>{t("data.message." + error)}</li>
            </ul>
          </div>
        );
      })}
      {children &&
        Children.map(children, (child, index) => {
          return <Fragment key={`workflow-child-${index}`}>{child}</Fragment>;
        })}
      <div className="flex flex-row items-center justify-between mt-4 flex-wrap gap-y-3">
        <div className="text-2xl font-bold text-grey-900 w-48">
          {t("data.general.euro")}&nbsp;
          {formatCurrency(workflow?.total_cost) || "n/a"}
        </div>
        {workflow?.total_time && (
          <div className="flex flex-row items-center">
            <IconClock className="mr-2 w-5 h-5 text-grey-500" />
            <div className="text-sm text-grey-700">{workflow?.total_time || "n/a"}</div>
          </div>
        )}
        <ButtonPrimary
          className="w-full lg:w-fit"
          label={t("button.label.select")}
          onClick={onClick}
          size={SIZE.sm}
          disabled={hasErrors}
        />
      </div>
    </div>
  );
}

WorkflowCalc.propTypes = propTypes;
export default WorkflowCalc;
