import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { Avatar, ButtonDropdown } from "..";
import { IconChevronDown } from "@tabler/icons-react";

const propTypes = {
  options: PropTypes.array,
  onItemSelect: PropTypes.func,
  currentText: PropTypes.string,
  currentPicture: PropTypes.object,
  currentSupportingText: PropTypes.string,
};

function SwitcherDropdown({ currentPicture, currentText, currentSupportingText, options, onItemSelect }) {
  const container = () => cntl`
    !w-full
  `;

  return (
    <ButtonDropdown onItemSelect={onItemSelect} offset={[10, 5]} options={options} className={container()}>
      <Switcher
        currentText={currentText}
        currentPicture={currentPicture}
        currentSupportingText={currentSupportingText}
      />
    </ButtonDropdown>
  );
}

const Switcher = ({ currentPicture, currentText, currentSupportingText, buttonRef, onClick }) => {
  const switcherCn = () => cntl`
    h-14
    flex
    w-full
    border
    shadow-sm
    rounded-lg
    items-center
    cursor-pointer
    border-grey-200
    hover:bg-grey-100 
  `;

  const contentCn = () => cntl`
    flex
    px-3.5
    w-full
    items-center
    justify-between
  `;

  const avatarAndTextCn = () => cntl`
    flex
    gap-2.5
    flex-row
    items-center
  `;

  const iconCn = () => cntl`
    w-4
    h-4
    flex
    items-center
    text-grey-500
  `;

  const wrapperTextCn = () => cntl`
    flex
    flex-col
  `;

  const textCn = () => cntl`
    text-sm
    truncate
    font-medium
    text-grey-900
  `;

  const supportingTextCn = () => cntl`
    text-xs
    truncate
    text-grey-600
  `;

  return (
    <div ref={buttonRef} onClick={onClick} className={switcherCn()}>
      <div className={contentCn()}>
        <div className={avatarAndTextCn()}>
          <Avatar size={SIZE.sm} picture={currentPicture} />
          <div className={wrapperTextCn()}>
            <div className={textCn()}>{currentText}</div>
            <div className={supportingTextCn()}>{currentSupportingText}</div>
          </div>
        </div>
        <div className={iconCn()}>
          <IconChevronDown />
        </div>
      </div>
    </div>
  );
};

SwitcherDropdown.propTypes = propTypes;
export default SwitcherDropdown;
