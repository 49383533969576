import {
  getAllDaysInMonth,
  mapMonths,
  mapDays,
  transformDaysInWeeks,
  orderedDays,
  allYears,
} from "@dbox/core/utils/dates";
import { useState, useEffect, useRef } from "react";
import cntl from "cntl";
import { format, isValid } from "date-fns";
import { IconArrowLeft, IconArrowRight, IconChevronDown } from "@tabler/icons-react";

function DatePicker({ onOk, onCancel, initialDate = "" }) {
  const yearSelectedRef = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(2022);
  const [pickerSelection, setPickerSelection] = useState({
    day: -1,
    month: -1,
    year: -1,
  });

  const [yearVisible, setYearVisible] = useState({
    position: "left",
    open: false,
  });

  useEffect(() => {
    if (initialDate) {
      const [initialDay, initialMonth, initialYear] = initialDate.split("/");
      const newDate = `${initialYear}-${initialMonth}-${initialDay}`;
      const date = new Date(newDate);
      const selectedDay = date.getDate();
      const selectedMonth = date.getMonth();
      const selectedYear = date.getFullYear();
      setPickerSelection({
        day: selectedDay,
        month: selectedMonth,
        year: selectedYear,
      });
      setCurrentMonth(selectedMonth);
      setCurrentYear(selectedYear);
    }
  }, [initialDate]);

  useEffect(() => {
    if (yearVisible.open) {
      yearSelectedRef.current.scrollIntoView({
        behaviour: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [yearVisible]);

  const days = getAllDaysInMonth(currentYear, currentMonth);

  const daysLeft = transformDaysInWeeks(days);

  const dayCn = (isDaySelected) => cntl`
    mt-2 
    w-[2.35rem] 
    h-8 
    flex 
    items-center 
    justify-center
    cursor-pointer
    ${isDaySelected ? cntl`bg-primary-700 rounded-full text-white` : null}
  `;

  const yearSelectedCn = (isYearSelected) => cntl`
    mt-4
    ml-3
    mr-3
    pt-1
    pb-1
    scroll-mt-4
    ${isYearSelected ? cntl`bg-primary-700 rounded-full text-white` : null}
  `;

  const yearCn = cntl`
    absolute
    bg-white
    flex
    flex-col
    shadow
    rounded-lg
    top-5
    max-h-60
    left-0
    right-0
    overflow-y-scroll
    date-picker-year
    pl-2
    text-center
  `;

  const arrowCn = cntl`
    ${yearVisible.open ? cntl`rotate-180 ml-2 mb-1` : cntl`ml-2`}
  `;

  const daysOfWeek = orderedDays(currentYear, currentMonth);

  const handleNumberClick = () => {
    if (
      currentYear === -1 ||
      pickerSelection.month === -1 ||
      pickerSelection.day === -1
    ) {
      onOk("");
      return;
    }
    const date = new Date(
      currentYear,
      pickerSelection.month,
      pickerSelection.day
    );
    const dateFormatted = isValid(date) ? format(date, "yyyy-MM-dd") : "";
    onOk(dateFormatted);
  };

  return (
    <div
      className="bg-white shadow rounded-lg w-[18.75rem] h-[20.125rem] flex flex-row z-40 text-xs font-medium"
      data-cypress="date-picker"
    >
      <div className="w-full">
        <div className="flex flex-row items-center justify-around mt-5 mb-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              if (currentMonth === 0) {
                setCurrentMonth(11);
                setCurrentYear((previousYear) => {
                  return previousYear - 1;
                });
                return;
              }
              return setCurrentMonth((previousMonth) => {
                return previousMonth - 1;
              });
            }}
          >
            <IconArrowLeft className="h-5 w-5 text-grey-900" />
          </div>
          <div className="flex flex-row justify-center w-40">
            <div className="text-grey-900">{mapMonths[currentMonth + 1]}</div>
            <div
              className="cursor-pointer flex flex-row relative"
              onClick={() => {
                setYearVisible((previousYearVisible) => ({
                  position: "left",
                  open: !previousYearVisible.open,
                }));
              }}
            >
              {yearVisible.open && yearVisible.position === "left" && (
                <div className={yearCn}>
                  {allYears().map((year, idx) => {
                    let yearSelected = year === currentYear;
                    return (
                      <div
                        className={yearSelectedCn(yearSelected)}
                        key={`year-${idx}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          setCurrentYear(year);
                          setYearVisible(false);
                        }}
                        ref={(el) => {
                          if (yearSelected) {
                            yearSelectedRef.current = el;
                          }
                        }}
                      >
                        <div className="text-grey-900">{year}</div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="text-grey-900 ml-12">{currentYear}</div>
              <div className={arrowCn}>
                <IconChevronDown className="w-4 h-4 text-grey-500" />
              </div>
            </div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (currentMonth === 11) {
                setCurrentMonth(0);
                setCurrentYear((previousYear) => {
                  return previousYear + 1;
                });
                return;
              }
              return setCurrentMonth((previousMonth) => {
                return previousMonth + 1;
              });
            }}
          >
            <IconArrowRight className="w-5 h-5 text-grey-900" />
          </div>
        </div>
        <div className="flex flex-row justify-around ml-5 mr-5">
          {daysOfWeek.map((day, idx) => {
            const dayName = mapDays[day];
            return (
              <div key={`day-${idx}`} className="text-grey-500">
                {dayName}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col flex-wrap ml-5 h-52">
          {daysLeft.map((day, index) => {
            return (
              <div className="flex flex-row" key={`day-${index}`}>
                {day.map((d, i) => {
                  const isDaySelected =
                    d === pickerSelection.day &&
                    pickerSelection.month === currentMonth &&
                    pickerSelection.year === currentYear;
                  return (
                    <div
                      className={dayCn(isDaySelected)}
                      data-cypress="picker-days"
                      key={`d-${i}`}
                      onClick={() => {
                        setPickerSelection({
                          day: d,
                          month: currentMonth,
                          year: currentYear,
                        });
                      }}
                    >
                      <div className="text-grey-700">{d}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="flex flex-row">
          <div
            className="text-white bg-primary-700 rounded-lg h-9 text-center ml-4 flex items-center justify-center cursor-pointer w-6/12"
            data-cypress="picker-ok-btn"
            onClick={handleNumberClick}
          >
            OK
          </div>
          <div
            className="border border-grey-300 rounded-lg h-9 text-center ml-4 mr-4 flex items-center justify-center cursor-pointer w-6/12"
            onClick={() => {
              onCancel();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatePicker;
