import T from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { formatCurrency } from "@dbox/core/utils/formatCurrency";

const propTypes = {
  image: T.object,
  isFinalize: T.bool,
  isDisabled: T.bool,
  quoteItem: T.object,
};

const QuoteProductDetailsBox = ({ image, quoteItem, fetchImage, isDisabled, isFinalize }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchImage(quoteItem?.product_data?.id);
  }, [fetchImage, quoteItem?.product_data?.id]);

  return (
    <div className={`flex flex-row items-start justify-between pb-4 ${isDisabled ? `opacity-50` : `opacity-100`}`}>
      <div className="flex flex-col">
        <div className="text-xl text-grey-900 font-medium first-letter:capitalize">{quoteItem?.product_data?.name}</div>
        <div className="text-base text-grey-500 first-letter:capitalize">{quoteItem?.product_data?.sku || "n/a"}</div>
        {!isFinalize && (
          <div className="grid grid-cols-3 gap-x-10 mt-4">
            <div className="flex flex-col">
              <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
                {t("quote_product_details_box.label.total_cost")}
              </div>
              <div className="text-base text-grey-900">
                {t("data.general.euro")}&nbsp;
                {formatCurrency(quoteItem?.base_price) || "n/a"}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
                {t("quote_product_details_box.label.marginality")}
              </div>
              <div className="text-base text-grey-900">
                {quoteItem?.product_data?.marginality + " " + t("data.general.percentage") || "0"}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
                {t("quote_product_details_box.label.marginality")}
              </div>
              <div className="text-base text-grey-900">
                {t("data.general.euro")}&nbsp;
                {formatCurrency(quoteItem?.total_marginality) || "n/a"}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-3 gap-x-10 mt-4">
          <div className="flex flex-col">
            <div className="text-xs font-medium text-grey-500 first-letter:capitalize">
              {t("quote_product_details_box.label.price")}
            </div>
            <div className="text-base text-grey-900">
              {t("data.general.euro")}&nbsp;
              {formatCurrency(quoteItem?.price) || "n/a"}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-xs font-medium text-grey-500 uppercase">
              {t("quote_product_details_box.label.vat")}
            </div>
            <div className="text-base text-grey-900">{quoteItem?.vat + " " + t("data.general.percentage") || "0"}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-xs font-medium text-teal-500 first-letter:capitalize">
              {t("quote_product_details_box.label.final_price")}
            </div>
            <div className="text-base text-grey-900 font-bold">
              {t("data.general.euro")}&nbsp;{formatCurrency(quoteItem?.total_price) || "n/a"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-24 h-24 rounded-lg">
          <LazyLoadImage
            alt="quoteItem-image"
            className="rounded-lg"
            src={`data:image/png;base64,${arrayBufferToBase64(image?.buffer ?? null)}`}
          />
        </div>
      </div>
    </div>
  );
};

QuoteProductDetailsBox.propTypes = propTypes;
export default QuoteProductDetailsBox;
