import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { COLOR, SIZE } from "../constants";
import AvatarProfilePhoto from "./AvatarProfilePhoto";

const proptypes = {
  memberName: PropTypes.string,
  memberRole: PropTypes.string,
  socialIcons: PropTypes.array,
  textCentered: PropTypes.bool,
  profileImage: PropTypes.object,
  memberDescription: PropTypes.string,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.grey, COLOR.primary, COLOR.primary_light]),
};

function MemberCard({ memberDescription, memberName, memberRole, profileImage, textCentered, theme, socialIcons }) {
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.grey:
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        break;
      case COLOR.primary_light:
        setTextColor("text-primary-700");
        setTitleColor("text-primary-900");
        break;
      case COLOR.light:
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        break;
      default:
        setTextColor("text-grey-500");
        setTitleColor("text-grey-900");
        break;
    }
  }, [theme]);

  const contentCn = () => cntl`
    flex
    flex-col
    justify-center
    max-w-[14.75rem]
    ${textCentered ? "items-center" : "items-start"}
  `;

  const titleCn = () => cntl`
    mt-5
    text-lg
    font-medium
    ${titleColor}
  `;

  const roleTextCn = () => cntl`
    text-base
    text-primary-700
  `;

  const descriptionCn = () => cntl`
    mt-2
    mb-4
    text-base
    ${textColor}
    ${textCentered ? "text-center" : "text-left"}
  `;

  const imageCn = () => cntl`
    w-20
    h-20
  `;

  const iconsWrapperCn = () => cntl`
    flex
    gap-x-4
    flex-row
    justify-start
  `;

  const socialIconCn = () => cntl`
    w-h
    h-5
  `;

  return (
    <div className={contentCn()}>
      <AvatarProfilePhoto className={imageCn()} picture={profileImage} size={SIZE.sm} />
      {memberName?.length > 0 && <div className={titleCn()}>{memberName}</div>}
      {memberRole?.length > 0 && <div className={roleTextCn()}>{memberRole}</div>}
      {memberDescription?.length > 0 && <div className={descriptionCn()}>{memberDescription}</div>}
      <div className={iconsWrapperCn()}>
        {socialIcons?.map((socialIcon, index) => {
          return (
            <a href={socialIcon?.link}>
              <img src={socialIcon?.image} alt={`social-icon-${index}`} className={socialIconCn()} />
            </a>
          );
        })}
      </div>
    </div>
  );
}

MemberCard.prototype = proptypes;
export default MemberCard;
