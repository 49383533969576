import cntl from "cntl";
import { Badge } from ".";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";
import { getBadgeColors } from "@dbox/core/utils/getBadgeColors";

const propTypes = {
  isOutline: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function BadgeText({ label, size, className, isOutline }) {
  const [padding, setPadding] = useState("");

  const badgeColors = useMemo(() => getBadgeColors(label), [label]);

  const colorsFilled = () => cntl`
    ${badgeColors?.backgroundColor}
  `;

  const colorsOutline = () => cntl`
    outline
    bg-white
    outline-[1.5px]
    outline-offset-[-1.5px]
    ${badgeColors?.bordersColor}
  `;

  const colors = () => cntl`
    ${badgeColors?.textColor}
    ${isOutline ? colorsOutline() : colorsFilled()}
  `;

  const badgeTextCn = () => cntl`
    rounded-2xl
    ${padding}
    ${colors()}
    ${className ? className : undefined}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setPadding("px-2");
        break;
      case SIZE.md:
        setPadding("px-2.5");
        break;
      case SIZE.lg:
        setPadding("px-3");
        break;
      default:
        setPadding("px-2");
        break;
    }
  }, [size]);

  return <Badge className={badgeTextCn()} label={label} size={size} />;
}

BadgeText.propTypes = propTypes;
export default BadgeText;
