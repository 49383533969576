function MastercardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
    >
      <path
        d="M11.1831 1.06958H6.80399V8.93042H11.1831V1.06958Z"
        fill="#FF5A00"
      />
      <path
        d="M7.09549 5C7.09549 3.40291 7.84693 1.98544 9 1.06958C8.15139 0.402913 7.08092 0 5.91327 0C3.14721 0 0.909088 2.23625 0.909088 5C0.909088 7.76375 3.14721 10 5.91327 10C7.08092 10 8.15139 9.59709 9 8.93042C7.84531 8.02751 7.09549 6.59709 7.09549 5Z"
        fill="#EB001B"
      />
      <path
        d="M17.0909 5C17.0909 7.76375 14.8528 10 12.0867 10C10.9191 10 9.8486 9.59709 9 8.93042C10.1676 8.01295 10.9045 6.59709 10.9045 5C10.9045 3.40291 10.1531 1.98544 9 1.06958C9.84698 0.402913 10.9175 0 12.0851 0C14.8528 0 17.0909 2.25081 17.0909 5Z"
        fill="#F79E1B"
      />
    </svg>
  );
}

export default MastercardIcon;
