import T from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment, useMemo, useState } from "react";
import { ButtonPrimary, FormInputDate, QuoteTotalPriceView } from "../atoms";
import { STATUS_VALUES } from "../constants";
import { IconCalendar } from "@tabler/icons-react";

const propTypes = {
  isFinalize: T.bool,
  onFormSubmit: T.func,
  isButtonDisabled: T.bool,
  total: T.string.isRequired,
  costsRecap: T.array.isRequired,
  variant: T.oneOf([STATUS_VALUES.ENABLED, STATUS_VALUES.DISABLED]),
};

function QuoteSummary({
  total,
  children,
  costsRecap,
  onFormSubmit,
  isButtonDisabled,
  isFinalize = false,
  variant = STATUS_VALUES.ENABLED,
}) {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      expiring_date: "",
    },
  });

  const expiring_date = watch("expiring_date");

  useMemo(() => {
    switch (variant) {
      case STATUS_VALUES.DISABLED:
        setIsDisabled(true);
        break;
      default:
        setIsDisabled(false);
        break;
    }
  }, [variant]);

  useMemo(() => {
    if (!!total && !!total?.length) {
      setTotalPrice({
        key: t("quote_total_price_view.total_to_pay"),
        value: total,
      });
    }
  }, [total, t]);

  const onSubmit = ({ expiring_date }) => {
    onFormSubmit({
      expiring_date: expiring_date,
    });
  };

  return (
    <div className="flex flex-col shadow-lg xl:shadow rounded-2xl border border-grey-300 bg-white p-4">
      <div className="flex flex-col mb-4">
        {!!costsRecap?.length &&
          costsRecap?.map(({ key, value }, index) => {
            return (
              <Fragment key={`cost-${index}`}>
                <div className="flex flex-row justify-between mb-2 last:mb-0">
                  <div className="text-sm text-grey-500 first-letter:capitalize">
                    {key}
                  </div>
                  <div className="text-sm text-grey-500 first-letter:capitalize">
                    {value}
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
      <QuoteTotalPriceView totalPrice={totalPrice} />
      {!isFinalize && !isDisabled ? (
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <FormInputDate
            name="expiring_date"
            type="date"
            appendIcon={() => {
              return <IconCalendar className="w-5 h-5 text-primary-500" />;
            }}
            placeholder={t("input.label.quote_expire_date")}
            formRegistration={register("expiring_date", {
              required: {
                value: true,
                message: t("input.field.required_field"),
              },
            })}
            errors={errors}
            value={expiring_date}
          />
          <div className="mt-4">
            <ButtonPrimary
              disabled={isButtonDisabled}
              type="submit"
              label={t("button.label.continue")}
              className="w-full"
            />
          </div>
        </form>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}

QuoteSummary.propTypes = propTypes;
export default QuoteSummary;
