function PaypalIcon() {
    return (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.7103 17.3898L5.02186 15.4083L4.32785 15.3922H1.01383L3.3169 0.770645C3.32405 0.726506 3.34728 0.685349 3.38124 0.656121C3.4152 0.626893 3.45868 0.610789 3.50396 0.610789H9.09185C10.9469 0.610789 12.2271 0.997308 12.8956 1.76021C13.2089 2.1181 13.4085 2.49209 13.505 2.90366C13.6062 3.33551 13.608 3.85147 13.5091 4.48076L13.502 4.52669V4.92991L13.8153 5.10766C14.0793 5.24783 14.2889 5.40828 14.4498 5.592C14.7179 5.89799 14.8912 6.2869 14.9645 6.74798C15.0402 7.22218 15.0151 7.78645 14.8912 8.42528C14.7483 9.16015 14.5171 9.80017 14.205 10.3239C13.9178 10.8064 13.552 11.2067 13.1178 11.5168C12.7031 11.8115 12.2105 12.0352 11.6535 12.1783C11.1137 12.3191 10.4984 12.3901 9.8234 12.3901H9.38852C9.07755 12.3901 8.77552 12.5022 8.53842 12.7032C8.30073 12.9084 8.14346 13.1888 8.09521 13.4954L8.06244 13.6737L7.51199 17.1661L7.48697 17.2944C7.48042 17.3349 7.4691 17.3552 7.45242 17.3689C7.43753 17.3814 7.41608 17.3898 7.39523 17.3898H4.7103Z"
          fill="#253B80"
        />
        <path
          d="M14.112 4.57322C14.0953 4.67999 14.0763 4.78914 14.0548 4.90128C13.3179 8.68953 10.7968 9.99821 7.57692 9.99821H5.93749C5.54372 9.99821 5.2119 10.2845 5.15054 10.6734L4.31116 16.0036L4.07347 17.5145C4.03356 17.7698 4.23015 18 4.4875 18H7.39523C7.73956 18 8.03206 17.7495 8.08627 17.4095L8.11486 17.2616L8.66233 13.7829L8.69748 13.592C8.7511 13.2508 9.04419 13.0003 9.38852 13.0003H9.8234C12.6406 13.0003 14.846 11.8551 15.4905 8.54101C15.7598 7.15657 15.6204 6.00059 14.9079 5.18759C14.6923 4.94244 14.4248 4.73904 14.112 4.57322Z"
          fill="#179BD7"
        />
        <path
          d="M13.3411 4.26544C13.2285 4.23264 13.1124 4.20281 12.9932 4.17597C12.8735 4.14972 12.7508 4.12646 12.6245 4.10618C12.1825 4.0346 11.6981 4.0006 11.1793 4.0006H6.79949C6.69167 4.0006 6.5892 4.02506 6.49746 4.0692C6.29551 4.16643 6.14539 4.3579 6.10905 4.59231L5.17734 10.5011L5.15054 10.6734C5.2119 10.2845 5.54372 9.99821 5.93749 9.99821H7.57692C10.7968 9.99821 13.3179 8.68953 14.0548 4.90128C14.0763 4.78914 14.0953 4.67999 14.112 4.57322C13.9256 4.4742 13.7236 4.38951 13.5062 4.31734C13.4525 4.29944 13.3971 4.28214 13.3411 4.26544Z"
          fill="#222D65"
        />
        <path
          d="M6.10905 4.59231C6.14539 4.3579 6.29551 4.16643 6.49746 4.0692C6.5892 4.02506 6.69167 4.0006 6.79949 4.0006H11.1793C11.6981 4.0006 12.1825 4.0346 12.6245 4.10618C12.7508 4.12646 12.8735 4.14972 12.9932 4.17597C13.1124 4.20281 13.2285 4.23264 13.3411 4.26544C13.3971 4.28214 13.4525 4.29944 13.5062 4.31734C13.7236 4.38951 13.9256 4.4742 14.112 4.57322C14.3312 3.17327 14.1108 2.2201 13.3549 1.35699C12.5214 0.4068 11.0172 0 9.09245 0H3.50455C3.11138 0 2.77598 0.286311 2.71522 0.675813L0.387725 15.4477C0.341855 15.7399 0.567038 16.0036 0.861326 16.0036L4.31116 16.0036L5.17734 10.5011L6.10905 4.59231Z"
          fill="#253B80"
        />
      </svg>
    );
}

export default PaypalIcon;
