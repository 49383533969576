import cntl from "cntl";
import PropTypes from "prop-types";
import BreadcrumbGrey from "./BreadcrumbGrey";
import BreadcrumbText from "./BreadcrumbText";
import { BREADCRUMB_VARIANTS } from "../constants";
import BreadcrumbPrimary from "./BreadcrumbPrimary";
import { IconChevronRight } from "@tabler/icons-react";
import { useMemo, cloneElement, Fragment } from "react";

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  variant: PropTypes.oneOf([
    BREADCRUMB_VARIANTS.text,
    BREADCRUMB_VARIANTS.grey,
    BREADCRUMB_VARIANTS.primary,
  ]),
};

function Breadcrumbs({ data, className, variant = BREADCRUMB_VARIANTS.text }) {
  const navCn = () => cntl`
    z-40
    bg-white
    ${className ? className : undefined}
  `;

  const olCn = () => cntl`
    flex
    gap-x-3
    flex-row
    items-center
  `;

  const iconCn = () => cntl`
    w-5
    h-5
    last:hidden
    text-grey-300
  `;

  const breadcrumb = useMemo(() => {
    switch (variant) {
      case BREADCRUMB_VARIANTS.text:
        return <BreadcrumbText />;
      case BREADCRUMB_VARIANTS.grey:
        return <BreadcrumbGrey />;
      case BREADCRUMB_VARIANTS.primary:
        return <BreadcrumbPrimary />;
      default:
        return <BreadcrumbText />;
    }
  }, [variant]);

  return (
    <nav className={navCn()}>
      <ol className={olCn()}>
        {data?.map(({ isHomePage, to, label }, index) => {
          return (
            <Fragment key={`breadcumb-${variant}-${index}`}>
              {cloneElement(breadcrumb, {
                link: to,
                label: label,
                isHomePage: isHomePage,
                isActive: data?.length === index + 1,
              })}
              <IconChevronRight className={iconCn()} />
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = propTypes;
export default Breadcrumbs;
