import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState, cloneElement } from "react";
import { COLOR, SIZE } from "../constants";
import SectionContent from "./SectionContent";
import SectionItem from "./SectionItem";

const propTypes = {};

function FeatureSimpleIcons02({ items, content }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    container
    mx-auto
    px-4 md:px-8 py-16 md:py-24
    grid
    grid-cols-1
    lg:grid-cols-3
    gap-y-12
    lg:gap-x-8
  `;

  const columnsWrapperCn = () => cntl`
    grid
    grid-cols-1
    md:grid-cols-2
    gap-6
    md:gap-8
    col-span-2
  `;

  const itemCn = () => cntl`
    flex 
    flex-col 
    gap-y-12
    md:gap-y-16 
    p-5
    md:p-6 
    bg-grey-50
  `;

  const iconCn = () => cntl`
    rounded-lg
    bg-primary-500
    p-3
    w-fit
  `;

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <SectionContent {...content} size={SIZE.sm} />
        <div className={columnsWrapperCn()}>
          {items?.map(({ icon, text, title, link }, index) => {
            return (
              <div className={itemCn()} key={`section-item-${index}`}>
                <div className={iconCn()}>
                  {cloneElement(icon, {
                    className: `w-6 h-6 text-white`,
                  })}
                </div>
                <SectionItem title={title} text={text} link={link} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FeatureSimpleIcons02.prototype = propTypes;
export default FeatureSimpleIcons02;
