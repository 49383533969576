import { BadgeText } from "../atoms";
import PropTypes from "prop-types";
import { Fragment } from "react";

const propTypes = {
  data: PropTypes.array,
};

const LabelsWithValues = ({ data }) => {
  const setDetails = (type, label, value) => {
    switch (type) {
      case "text":
        return (
          <div className="flex flex-col">
            <div className="text-grey-500 text-xs font-medium first-letter:uppercase">
              {label}
            </div>
            <div className="text-grey-900 text-base font-medium">
              {value}
            </div>
          </div>
        );
      case "badge":
        return (
          <div className="mb-0.5">
            <BadgeText label={value} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {data?.map(({ type, label, value }, index) => {
        return (
          <Fragment key={`item-details-box-${index}`}>
            {setDetails(type, label, value)}
          </Fragment>
        );
      })}
    </>
  );
};

LabelsWithValues.propTypes = propTypes;
export default LabelsWithValues;
