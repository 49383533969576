import PropTypes from "prop-types";

const propTypes = {
  dropdownCardRef: PropTypes.object,
  classNameDropdownCard: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function DropdownCard({ children, dropdownCardRef, classNameDropdownCard }) {
  return (
    <div className={classNameDropdownCard} ref={dropdownCardRef}>
      {children}
    </div>
  );
}

DropdownCard.propTypes = propTypes;
export default DropdownCard;
