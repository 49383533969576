import cntl from "cntl";
import { useState, cloneElement, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { COLOR, FEATURED_ICON_VARIANTS, SIZE } from "../constants";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.oneOf([
    COLOR.primary,
    COLOR.grey,
    COLOR.error,
    COLOR.success,
    COLOR.warning,
    COLOR.dark,
    COLOR.primary_light,
  ]),
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl]),
  variant: PropTypes.oneOf([
    FEATURED_ICON_VARIANTS.dark,
    FEATURED_ICON_VARIANTS.square,
    FEATURED_ICON_VARIANTS.light_circle,
    FEATURED_ICON_VARIANTS.light_circle_outline,
  ]),
};

function FeaturedIcon({ size, color, variant, children, className }) {
  const [border, setBorder] = useState("");
  const [outline, setOutline] = useState("");
  const [rounded, setRounded] = useState("");
  const [isDark, setIsDark] = useState(false);
  const [iconSize, setIconSize] = useState("");
  const [iconColor, setIconColor] = useState("");
  const [isSquare, setIsSquare] = useState(false);
  const [hasOutline, setHasOutline] = useState("");
  const [outlineWidth, setOutlineWidth] = useState("");
  const [featuredIconSize, setFeaturedIconSize] = useState("");
  const [featuredIconColor, setFeaturedIconColor] = useState("");

  const featuredIconWrapperCn = () => cntl`
    flex
    shrink-0
    flex-row
    items-center
    justify-center
    ${border}
    ${rounded}
    ${featuredIconSize}
    ${featuredIconColor}
    ${className ? className : undefined}
    ${hasOutline ? outline : cntl`outline-none`}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.xs:
        setOutlineWidth("outline-2");
        setFeaturedIconSize("h-6 w-6");
        setIconSize("w-3 h-3 stroke-1");
        break;
      case SIZE.sm:
        setOutlineWidth("outline-4");
        setFeaturedIconSize("h-8 w-8");
        setIconSize("w-4 h-4 stroke-[1.33px]");
        break;
      case SIZE.md:
        setOutlineWidth("outline-[6px]");
        setFeaturedIconSize("h-10 w-10");
        setIconSize("w-5 h-5 stroke-[1.5px]");
        break;
      case SIZE.lg:
        setOutlineWidth("outline-8");
        setIconSize("w-6 h-6 stroke-2");
        setFeaturedIconSize("h-12 w-12");
        break;
      case SIZE.xl:
        setFeaturedIconSize("h-14 w-14");
        setOutlineWidth("outline-[10px]");
        setIconSize("w-7 h-7 stroke-[2.33px]");
        break;
      default:
        setOutlineWidth("outline-4");
        setFeaturedIconSize("h-8 w-8");
        setIconSize("w-4 h-4 stroke-[1.33px]");
        break;
    }
  }, [size]);

  const circleColor = useCallback(() => {
    switch (color) {
      case COLOR.primary:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-primary-600");
        setFeaturedIconColor(isDark ? "bg-primary-600" : "bg-primary-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-primary-700`
            : `outline ${outlineWidth} outline-primary-50`
        );
        break;
      case COLOR.grey:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-grey-600");
        setFeaturedIconColor(isDark ? "bg-grey-600" : "bg-grey-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-grey-700`
            : `outline ${outlineWidth} outline-grey-50`
        );
        break;
      case COLOR.error:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-error-600");
        setFeaturedIconColor(isDark ? "bg-error-600" : "bg-error-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-error-700`
            : `outline ${outlineWidth} outline-error-50`
        );
        break;
      case COLOR.warning:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-warning-600");
        setFeaturedIconColor(isDark ? "bg-warning-600" : "bg-warning-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-warning-700`
            : `outline ${outlineWidth} outline-warning-50`
        );
        break;
      case COLOR.success:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-success-600");
        setFeaturedIconColor(isDark ? "bg-success-600" : "bg-success-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-success-700`
            : `outline ${outlineWidth} outline-success-50`
        );
        break;
      default:
        setBorder("border-0");
        setIconColor(isDark ? "text-white" : "text-primary-600");
        setFeaturedIconColor(isDark ? "bg-primary-600" : "bg-primary-100");
        setOutline(
          isDark
            ? `outline ${outlineWidth} outline-primary-700`
            : `outline ${outlineWidth} outline-primary-50`
        );
        break;
    }
  }, [color, isDark, outlineWidth]);

  const squareColor = useCallback(() => {
    switch (color) {
      case COLOR.primary_light:
        setBorder("border-0");
        setIconColor("text-primary-600");
        setFeaturedIconColor("bg-primary-50");
        break;
      case COLOR.primary:
        setBorder("border-0");
        setIconColor("text-white");
        setFeaturedIconColor("bg-primary-500");
        break;
      case COLOR.dark:
        setBorder("border-0");
        setIconColor("text-white");
        setFeaturedIconColor("bg-primary-800");
        break;
      case COLOR.grey:
        setIconColor("text-grey-700");
        setFeaturedIconColor("bg-white");
        setBorder("border border-grey-200");
        break;
      default:
        setBorder("border-0");
        setIconColor("text-primary-600");
        setFeaturedIconColor("bg-primary-50");
        break;
    }
  }, [color]);

  useMemo(() => {
    if (!isSquare) {
      circleColor();
    } else {
      squareColor();
    }
  }, [isSquare, circleColor, squareColor]);

  useMemo(() => {
    switch (variant) {
      case FEATURED_ICON_VARIANTS.dark:
        setIsDark(true);
        setIsSquare(false);
        setHasOutline(true);
        setRounded("rounded-full");
        break;
      case FEATURED_ICON_VARIANTS.light_circle:
        setIsDark(false);
        setIsSquare(false);
        setHasOutline(false);
        setRounded("rounded-full");
        break;
      case FEATURED_ICON_VARIANTS.light_circle_outline:
        setIsDark(false);
        setIsSquare(false);
        setHasOutline(true);
        setRounded("rounded-full");
        break;
      case FEATURED_ICON_VARIANTS.square:
        setIsDark(false);
        setIsSquare(true);
        setHasOutline(false);
        setRounded("rounded-lg");
        break;
      default:
        setIsDark(false);
        setIsSquare(false);
        setHasOutline(false);
        setRounded("rounded-full");
        break;
    }
  }, [variant]);

  return (
    <div className={featuredIconWrapperCn()}>
      {cloneElement(children, {
        className: `${iconSize} ${iconColor}`,
      })}
    </div>
  );
}

FeaturedIcon.propTypes = propTypes;
export default FeaturedIcon;
