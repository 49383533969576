export function formatCurrency(value) {
  let nf = "n/a";

  if (value !== null && value !== undefined && !isNaN(value)) {
    nf = new Intl.NumberFormat("it-IT", {
      style: "decimal",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  return nf;
}
